import React, { useEffect, useState } from "react";
import Modal from "../base/modal/modal";
import useModalHook from "../../hooks/modal.hook";
import { PropTypes } from "data/modal/modal.proprieties";
import { OperationType } from "../../data/operationOrder/operation";
import { Field } from "../properties/property.data";
import Box, { Item } from 'devextreme-react/box';
import ButtonEditorRow from "./buttonEditorRow";
import { InteractionUtil } from "../../components/properties/util/interaction.util";
import { useTranslator } from "../../contexts/translator.context";
import { EditorsTranslates } from "../../translator/components/editors.translate";
import './editor.css'

const { PriorizationOrderTitle } = EditorsTranslates();

export default function PrioritizationOrder({ show, data, formState, field, onClose, onButtonOk }: PropTypes) {
    const [selectedOrder, setSelectedOrder] = useState<OperationType[]>([]);
    const [selectedItem, setSelectedItem] = useState<OperationType | null>(null);
    const { isOpen, toggle, open } = useModalHook();
    const translatorHelper = useTranslator();

    useEffect(() => {
        if (show) {
            const prioritizationOrder = InteractionUtil.getInteractionOrderValue(data, field, formState, true);
            convertDataToSelectionOrder(prioritizationOrder);
            open();
        }
        document.body.style.overflow = isOpen ? "hidden" : "unset";

    }, [data, show, isOpen])

    function convertDataToSelectionOrder(data: any[]) {
        let propertyClone = { ...data }
        const values: any[] = [];
        for (const operation of Object.values<Field>(propertyClone)) {
            values.push(operation)
        }
        setSelectedOrder(values)
        setSelectedItem(values[0]);
    }

    const convertSelectionToData = () => {
        let prioritizedConstants = InteractionUtil.getInteractionOrderValue(data, field, formState, false);
        const sortedData = selectedOrder.map(value => {
            return prioritizedConstants.find((constant: any) => constant.value === value);
        }).filter(item => item !== undefined);

        onButtonOk(sortedData);
    };

    const handleItemClick = (item: OperationType) => {
        setSelectedItem(item);
    };

    const onClickMoveUp = () => {
        if (!buttonMoveUpState()) {

            let index = selectedOrder.findIndex(item => item === selectedItem);

            let item = selectedOrder[index];
            selectedOrder.splice(index, 1);
            selectedOrder.splice(index - 1, 0, item);

            const cloneSelectedOrder = [...selectedOrder];

            setSelectedOrder(cloneSelectedOrder)
        }
    }

    const onClickMoveDown = () => {
        if (!buttonMoveDownState()) {

            let index = selectedOrder.findIndex(item => item === selectedItem);

            let item = selectedOrder[index];
            selectedOrder.splice(index, 1);
            selectedOrder.splice(index + 1, 0, item);

            const cloneSelectedOrder = [...selectedOrder];

            setSelectedOrder(cloneSelectedOrder)
        }
    }

    const buttonMoveUpState = () => {
        let index = selectedOrder.findIndex(item => item === selectedItem);

        if (index > 0) {
            return false;
        }

        return true;
    }

    const buttonMoveDownState = () => {
        let index = selectedOrder.findIndex(item => item === selectedItem);

        if (index < selectedOrder.length - 1 && index > -1) {
            return false;
        }

        return true;
    }

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} close={onClose} width="25%" height="35%" onButtonOk={convertSelectionToData} modalHeader={PriorizationOrderTitle}>
                <Box>
                    <Item ratio={0} >
                        <div className="div-columnEditor-column">
                            <ul>
                                {selectedOrder.map((item, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleItemClick(item)}
                                        className={selectedItem === item ? "selected" : ""}>
                                        {translatorHelper.getTranslatedValue(item)}
                                    </div>
                                ))}
                            </ul>
                        </div>
                    </Item>
                </Box>
                <div>
                    <ButtonEditorRow
                        onClickMoveUp={onClickMoveUp}
                        onClickMoveDown={onClickMoveDown}
                        buttonMoveUpState={buttonMoveUpState}
                        buttonMoveDownState={buttonMoveDownState}
                    />
                </div>
            </Modal>
        </React.Fragment>
    );
}