export interface Category {
    groupName: string
    id: string,
    isVisible: boolean
}
export interface Field {
    type: string;
    id: string;
    defaultName: string;
    value: any;
    visibleValue: string;
    categoryID: string;
    format: string;
    prefix: string;
    suffix: string;
    separator: string;
    groupedProperties: Field[];
    visibilityCondition: string,
    isVisible: boolean,
    editorType: string,
    requireAuthorization: boolean,
    localizedName: string,
    localizedDescription: string,
    bind: string[],
    constants: FieldEnum[],
    toolTips: ToolTips[],
    editorParameter?: EditorParameter,
    interaction?: Interaction,
    translateEnum?: string,
    required?: boolean
}

export interface Interaction {
    ids: string[];
    expressionValue: string;
}

export interface FieldEnum {
    value: string,
    localizedName: string
}

export interface ToolTips {
    id: string,
    content: string,
    localizedName: string
}

export interface EditorParameter {
    logicalOptions: LogicalOptions[],
    operatorOptions: OperatorOptions[],
    fieldOptions: FieldOptions[]
}

export interface LogicalOptions {
    id: string;
    definition: string,
    localizedName: string
}

export interface OperatorOptions {
    id: string;
    definition: string,
    localizedName: string
}

export interface FieldOptions {
    id: string;
    definition: string,
    localizedName: string
}

export function NewProperty(): Field {
    let property: Field = {
        type: 'Column',
        id: '',
        value: '',
        defaultName: '',
        visibleValue: '',
        categoryID: 'General',
        format: '',
        prefix: '',
        suffix: '',
        separator: '',
        groupedProperties: [],
        localizedName: '',
        bind: [],
        visibilityCondition: "",
        isVisible: false,
        editorType: "",
        requireAuthorization: false,
        localizedDescription: '',
        constants: [],
        toolTips: [],
        required: false
    };

    return property;
}