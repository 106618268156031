import React, { useEffect, useState } from "react";
import PropertyLabel from "./property.label";
import PropertyInput from "./property.input";
import { Field } from "./property.data";
import TreeView from 'devextreme-react/tree-view';
import minusIcon from '../../assets/images/icons/minus.svg'
import plusIcon from '../../assets/images/icons/plus.svg';

export default function PropertyFieldset({ fieldsetKey, category, formState, currentSettings, disableForm, handleChange }: any) {

    const groupTreeLayout = [{
        id: '1',
        name: category.groupName,
        isGroupHeader: true,
        expanded: true,
    }, {
        id: '1_1',
        categoryId: '1',
        isGroupHeader: false,
        expanded: false,
    }]

    const [groupTree, setGroupTree] = useState(groupTreeLayout);

    const getFieldsByCategory = (categoryID: string) => {
        return Object.values(formState as Field[]).filter(f => { return f.categoryID === categoryID });
    }

    const checkHiddenGroup = () => {
        return getFieldsByCategory(category.id).every(field => field.isVisible === undefined || (field.isVisible != undefined && !field.isVisible));
    }

    const onKeyClicked = (id: string) => {
        setGroupTree(prevState => {
            let newState = prevState?.map(obj => {
                if (obj.id === id) {
                    return {
                        ...obj,
                        expanded: !obj.expanded,
                    };
                }
                return { ...obj }
            });
            return newState;
        });
    }

    const renderCustomItem = (item: any) => {

        return (
            (item.isGroupHeader == false ?
                <div>
                    {getFieldsByCategory(category.id).map((field, index) => (
                        <div key={index} className={field.isVisible != undefined && field.isVisible ? 'properties-layout' : 'properties-layout-hidden'}>
                            <PropertyLabel field={field} />
                            <PropertyInput formState={formState} field={field} disableForm={disableForm} currentSettings={currentSettings} onChange={handleChange} />
                        </div>
                    ))
                    }</div>
                :
                <div onClick={() => onKeyClicked('1')} className={checkHiddenGroup() ? 'properties-layout-hidden' : 'group-text'}> {item.name}</ div >
            )
        );
    }

    const getStyle = (): React.CSSProperties => {
        return {
            display: `${checkHiddenGroup() ? 'none' : 'flex'}`
        };
    }

    return (
        <React.Fragment>
            <TreeView className="properties-tree-view" style={getStyle()}
                id="simple-treeview"
                items={groupTree}
                dataStructure="plain"
                displayExpr="name"
                expandedExpr="expanded"
                parentIdExpr="categoryId"
                keyExpr="ID"
                expandIcon={plusIcon}
                collapseIcon={minusIcon}
                itemRender={renderCustomItem}
            />
        </React.Fragment>
    )
}