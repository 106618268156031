import { AlignmentFaceConverter } from "./alignmentFaceConverter";
import { SawOrientationConverter } from "./sawOrientationConverter";
import { WorkingAreaConverter } from "./workingAreaConverter";

export interface IConverter {
    canConvert(bind: string): boolean;
    convertTo(value: any): any;
    convertBack(value: any): any;
}

class ConverterManager {
    private readonly converters: IConverter[] = [new AlignmentFaceConverter(), new SawOrientationConverter(), new WorkingAreaConverter()];

    convertValue(value: any, bind: string): any {
        const converter = this.converters.find(C => C.canConvert(bind));

        if (converter === undefined) {
            return value;
        }
        else {
            return converter.convertTo(value);
        }
    }

    unconvertValue(value: any, bind: string): any {
        const converter = this.converters.find(C => C.canConvert(bind));

        if (converter === undefined) {
            return value;
        }
        else {
            return converter.convertBack(value);
        }
    }
}


export default ConverterManager;