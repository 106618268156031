import { useState } from "react";

export default function useModalHook() {
  const [isOpen, setisOpen] = useState(false);

  const toggle = () => {
    setisOpen(!isOpen);
  };

  const open = () => {
    setisOpen(true);
  };

  const close = () => {
    setisOpen(false);
  }

  return {
    isOpen,
    toggle,
    open,
    close
  };
}