import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ManufactureUser } from "../data/user/userType";

interface UserDataState {
    user: ManufactureUser
}

const initialState: UserDataState = {
    user: {} as ManufactureUser
}

export const UserDataSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        addUser: (state, action: PayloadAction<ManufactureUser>) => {
            state.user = (JSON.parse(JSON.stringify(action.payload)));
        },
        editUser: (state, action: PayloadAction<ManufactureUser>) => {
            state.user = (JSON.parse(JSON.stringify(action.payload)));
        },
        removeUser: (state) => {
            state.user = {} as ManufactureUser;
        },
    }
})

export default UserDataSlice.reducer;
export const { addUser, editUser, removeUser } = UserDataSlice.actions;