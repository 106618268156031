import { HomePage, NestingPage, SetupPage, WorkingAreaPage, MachinePage, MillPage, DrillPage, SawPage } from './pages';
import { withNavigationWatcher } from './contexts/navigation.context';

const routes = [
    {
        path: '/:manufacturer/:model/:machineName',
        element: MachinePage
    },
    {
        path: '/:manufacturer/:model/:machineName/nesting',
        element: NestingPage
    },
    {
        path: '/:manufacturer/:model/:machineName/setups',
        element: SetupPage
    },
    {
        path: '/:manufacturer/:model/:machineName/tools/drills',
        element: DrillPage
    },
    {
        path: '/:manufacturer/:model/:machineName/tools/mills',
        element: MillPage
    },
    {
        path: '/:manufacturer/:model/:machineName/tools/saws',
        element: SawPage
    },
    {
        path: '/:manufacturer/:model/:machineName/working_area',
        element: WorkingAreaPage
    },
    {
        path: '/',
        element: HomePage
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
