import React, { ChangeEvent, useEffect } from 'react';
import { SearchTranslates } from 'translator/components/search.translate';
import { useSearch } from 'contexts/search.context';
import { useLocation } from 'react-router-dom';
import './page.search.component.scss';

const { Search, SearchPlaceholder } = SearchTranslates();

const PageSearchComponent: React.FC = () => {

    const { state } = useLocation();
    const { searchTerm, setSearchTerm } = useSearch();

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        setSearchTerm('');
    }, [state.pageNavigation]);

    return (
        <div className="input-field">
            <label className="label-container">{Search}</label>
            <input
                className="default-text"
                type="text"
                placeholder={SearchPlaceholder}
                value={searchTerm}
                onChange={handleInputChange}
            />
        </div>
    );
};

export default PageSearchComponent;