import React, { useEffect, useRef, useState } from "react";
import Authorization from "../security/authorization";
import TextBox from "devextreme-react/text-box";
import { NativeEventInfo } from "devextreme/events";
import {
    Validator,
    RequiredRule,
    ValidatorRef
} from 'devextreme-react/validator';
import { addValidation, removeValidation } from 'store/validation.store';
import { useAppDispatch } from "store/store";
import { TextBoxInstance } from "devextreme/ui/text_box";

interface PropertyTextState {
    textOption: string;
}

export default function PropertyText({ field, onChange, disableForm }: any) {

    const [isAuthorizationOpen, setToggleAuthorization] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const dispatch = useAppDispatch();
    const validatorRef = useRef<ValidatorRef>(null);

    useEffect(() => {
        setState({ textOption: field.value });
        if (field.required)
            checkValidation();
    }, [field]);

    const [state, setState] = useState<PropertyTextState>({
        textOption: field.value
    });

    const handleCheckChange = (value: string) => {
        setState({ textOption: value });
        onChange(field.id, value);
    };

    const handleCheckSecurity = (event: NativeEventInfo<TextBoxInstance, KeyboardEvent>) => {
        if (!isAuthorized) {
            if (field.RequireAuthorization)
                event.event?.preventDefault();

            setToggleAuthorization(field.RequireAuthorization);
        }
    };

    const checkValidation = () => {
        const validator = validatorRef.current && validatorRef.current.instance();
        if (validator) {
            const validation = validatorRef.current.instance().validate();
            if (!validation.isValid && field.isVisible)
                dispatch(addValidation({ field: field, isValid: false }))
            else
                dispatch(removeValidation(field))
        }
    }

    return (
        <React.Fragment >
            <TextBox className='dx-field-label' disabled={disableForm}
                visible={field.isVisible !== undefined ? !!field.isVisible : true} name={field.id} value={state.textOption} onValueChange={handleCheckChange} onKeyDown={handleCheckSecurity} >
                <Validator ref={validatorRef} >
                    {field.required && <RequiredRule type='required' message="" />}
                </Validator>
            </TextBox>
            <Authorization show={isAuthorizationOpen} onClose={() => setToggleAuthorization(false)} onButtonOk={() => setIsAuthorized(true)} />
        </React.Fragment>
    )
}