import React, { useEffect, useState } from "react";
import Modal from "../base/modal/modal";
import useModalHook from "../../hooks/modal.hook";
import { PropTypes } from 'data/modal/modal.proprieties';
import { store } from "../../store/store";
import { TreeList, Selection, Column, TreeListTypes } from 'devextreme-react/tree-list';
import { IMill, ISaw, ITool } from "../../data/tools/toolData";
import { ITreeListTools } from "./editor.interfaces";
import { Utils } from "../../utils/utils";
import { eToolsSelect } from "./editor.enums";
import { EditorsTranslates } from "../../translator/components/editors.translate";
import './editor.css';

const { ToolsTitle, Mills, Saws } = EditorsTranslates();

export default function SelectedTools({ show, data, currentSettings, onClose, onButtonOk, selectedTools }: PropTypes & { selectedTools: ITool[] }) {

    const [mills, setMills] = useState<IMill[]>([]);
    const [saws, setSaws] = useState<ISaw[]>([]);
    const [treeListData, setTreeListData] = useState<ITreeListTools[]>([]);
    const [selectedData, setSelectedData] = useState<ITool[]>(selectedTools || []);
    const { isOpen, toggle, open } = useModalHook();

    const state = store.getState();

    useEffect(() => {
        if (show) {
            let enumTools = data as eToolsSelect[];
            if (currentSettings) {
                let toolSettings = currentSettings.toolSettings;
                if (toolSettings) {
                    if (enumTools.includes(eToolsSelect.MILL))
                        setMills(toolSettings.mills || []);
                    if (enumTools.includes(eToolsSelect.SAW))
                        setSaws(toolSettings.saws || []);
                }
            }
            populateToolTreeList();
            open();
        }
    }, [data, show, mills, selectedTools]);

    function populateToolTreeList() {
        let toolsArray: ITreeListTools[] = [];
        let enumTools = data as eToolsSelect[];

        if (enumTools.includes(eToolsSelect.MILL)) {
            let millHeadTool: ITreeListTools = {
                ID: Utils.generateGUID(),
                headID: '0',
                toolName: Mills,
                isHead: true
            };

            toolsArray.push(millHeadTool);

            for (let m of mills) {
                let newTool: ITreeListTools = {
                    ID: Utils.generateGUID(),
                    headID: millHeadTool.ID,
                    toolID: m.id,
                    toolName: m.name ?? ""
                };
                toolsArray.push(newTool);
            }
        }

        if (enumTools.includes(eToolsSelect.SAW)) {
            let sawHeadTool: ITreeListTools = {
                ID: Utils.generateGUID(),
                headID: '0',
                toolName: Saws,
                isHead: true
            };

            toolsArray.push(sawHeadTool);

            for (let m of saws) {
                let newTool: ITreeListTools = {
                    ID: Utils.generateGUID(),
                    headID: sawHeadTool.ID,
                    toolID: m.id,
                    toolName: m.name ?? ""
                };
                toolsArray.push(newTool);
            }
        }

        setTreeListData(toolsArray);
    }

    const onSelectionChanged = (e: TreeListTypes.SelectionChangedEvent) => {
        let childrenToolsID: any[] = [];
        if (e.selectedRowsData.some(s => s.isHead)) {
            childrenToolsID = treeListData
                .filter(f => f.headID === e.currentSelectedRowKeys[0])
                .map((tool) => tool.toolID);
        }

        let toolsID = e.selectedRowsData.map((tool) => tool.toolID);
        let dataSelectedMills = mills.filter(m => toolsID.includes(m.id) || childrenToolsID.includes(m.id));
        let dataSelectedSaws = saws.filter(s => toolsID.includes(s.id) || childrenToolsID.includes(s.id));

        setSelectedData([...dataSelectedMills, ...dataSelectedSaws]);
    };

    const getSelected = () => {
        let dataSelected = selectedData.map(m => m.id);
        if (treeListData) {
            return treeListData
                .filter(m => dataSelected.includes(m.toolID))
                .map(m => m.ID);
        }
        return [];
    };

    const saveData = () => onButtonOk(selectedData);

    return (
        <React.Fragment>
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                close={onClose}
                width="30%"
                height="40%"
                onButtonOk={saveData}
                modalHeader={ToolsTitle}
            >
                <TreeList
                    id="tool-grid"
                    dataSource={treeListData}
                    showRowLines={true}
                    showBorders={true}
                    columnAutoWidth={true}
                    showColumnHeaders={false}
                    keyExpr="ID"
                    parentIdExpr="headID"
                    defaultSelectedRowKeys={getSelected()}
                    onSelectionChanged={onSelectionChanged}
                >
                    <Selection recursive={false} mode="single" />
                    <Column dataField="toolName" />
                </TreeList>
            </Modal>
        </React.Fragment>
    );
}
