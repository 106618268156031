import { getApiAddress } from './setupProxy';

export class AppService {

    public async getConditionResult(formFields: any[]): Promise<any[]> {
        const response = await fetch(`${getApiAddress()}/Property/GetConditionResult`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                formFields
            )
        })
        return await response.json();
    }

    public async getInteractionResult(formFields: any[]): Promise<any[]> {
        const response = await fetch(`${getApiAddress()}/Property/GetInteractionResult`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                formFields
            )
        })
        return await response.json();
    }
}