import { store, useAppDispatch } from "store/store";
import { useNotification } from "contexts/notification.context";
import { ImportTranslates } from "translator/components/import.translate";
import { clearSettings, replaceNewSettings } from "store/settings.store";
import { ISettings, ISettingsInput } from "data/settings/settingsData";
import { IMachineInfoModel } from "data/machine/machineData";
import { triggerForcedEventTrigger } from "store/event.store";

const { ImportSuccess, ImportError } = ImportTranslates();

export default function useImportSettingsAction() {

    const dispatch = useAppDispatch();
    const { setNotificationConfig } = useNotification();

    const importSettingsAction = async (fileContent: string) => {
        try {

            let content = JSON.parse(fileContent) as any;
            const storedSettings = store.getState().settingsDataState.settings ?? [];
            let tempSettings: [IMachineInfoModel, ISettings[], ISettings[]][] = [];

            for (let sett of content?.settings) {
                storedSettings.forEach(SS => {
                    if (SS.machineInfo.id === sett.machineInfoId) {
                        const existingMachine = tempSettings.find(s => s[0].id === SS.machineInfo.id);

                        if (!existingMachine) {
                            tempSettings.push([
                                SS.machineInfo,
                                [{ ...sett }],
                                []
                            ]);

                        } else {
                            existingMachine[1].push({ ...sett });
                        }
                    }
                });
            }

            let settingsToImport: ISettingsInput[] = [];
            tempSettings.forEach(([machineInfo, settingsToUpdate, settingsToDelete]) => {
                if (settingsToUpdate.length > 0) {
                    const settingsInput: ISettingsInput = {
                        machineInfo,
                        settingsToUpdate,
                        settingsToDelete
                    };
                    settingsToImport.push(settingsInput);
                }
            });

            dispatch(clearSettings());
            dispatch(replaceNewSettings(settingsToImport));
            dispatch(triggerForcedEventTrigger());

            setNotificationConfig({
                isVisible: true,
                message: ImportSuccess,
                type: 'success'
            });
        } catch {
            setNotificationConfig({
                isVisible: true,
                message: ImportError,
                type: 'error'
            });
        }
    };

    return importSettingsAction;
};