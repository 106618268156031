
import React from 'react';
import { SingleCard } from 'layouts';
import { useCallback, useEffect, useState } from 'react';
import authService from '../../cyncly-login/authService';
import { LoginTranslates } from '../../translator/pages/login.translate';
import { SelectBox } from 'devextreme-react';
import { getPromobAccountData } from 'api/promob.request';
import { useAppDispatch } from 'store/store';
import { addLogedAccountInfo, PromobAccount } from 'store/loged.user.store';
import { SelectBoxTypes } from 'devextreme-react/cjs/select-box';
import { useAuth } from 'contexts/auth.context';
import './login.css';

const { SelectAccount } = LoginTranslates();

export default function SelectUserLogin() {

    const { setSelectUser } = useAuth();
    const [promobAccount, setPromobAccount] = useState<PromobAccount[]>([]);
    const [selectedPromobAccount, setSelectedPromobAccount] = useState<PromobAccount>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        getlogin();
    }, []);

    const getlogin = async () => {
        const authenciatedUser = await authService.getUser();
        const profiles = authenciatedUser?.profile["http://promobid.promob.com/identity/security/claims/accountid"] as any[];
        let promobAccounts: PromobAccount[] = [];
        for (const profileID of profiles) {
            const data = await getPromobAccountData<PromobAccount>(authenciatedUser?.access_token as string, profileID as string);
            if (data && data.active) {
                promobAccounts.push({
                    name: data.name,
                    accountId: data.accountId,
                    active: data.active
                });
            }
        }
        setPromobAccount(promobAccounts);
        setSelectedPromobAccount(promobAccounts[0]);
    };

    const login = async () => {
        const authenciatedUser = await authService.getUser();
        if (authenciatedUser)
            authenciatedUser.profile["accountID"] = selectedPromobAccount?.accountId;

        if (selectedPromobAccount)
            dispatch(addLogedAccountInfo(selectedPromobAccount));

        if (setSelectUser)
            setSelectUser(false);
    };

    const onValueChanged = useCallback((e: SelectBoxTypes.ValueChangedEvent) => {
        setSelectedPromobAccount(e.value);
    }, []);

    return (
        <React.Fragment>
            <SingleCard title={SelectAccount}>
                {promobAccount.length > 0 &&
                    (<>
                        <SelectBox
                            className='select-margin'
                            dataSource={promobAccount}
                            displayExpr="name"
                            valueExpr="accountId"
                            defaultValue={promobAccount[0].accountId}
                            onValueChange={onValueChanged}
                        />
                        <button className="login-button" onClick={login}>
                            <div className="login-button-text">Ok</div>
                        </button>
                    </>
                    )
                }
                {(!promobAccount || promobAccount.length == 0) && (
                    <div className='center-screen-spinner'>
                        <div className="border-gray-300 h-10 w-10 animate-spin rounded-full border-8 border-t-purple-600" />
                    </div>
                )}
            </SingleCard>
        </React.Fragment>
    );
}