import { TotalWorkingAreaSize } from "../workingArea.areaEditor";

class WorkingAreaUtil {

  static getAlphabetLetter = (index: number): string => {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return alphabet[index];
  };

  static translateTotalAreaToWorkingAreaRect = (totalWorkingAreaSize: TotalWorkingAreaSize, workingAreaWidth: number, workingAreaHeight: number): [number, number] => {
    let w = totalWorkingAreaSize.width - workingAreaWidth;
    let wPercent = (w / totalWorkingAreaSize.width) * 100;
    let screenWidth = w == 0 ? totalWorkingAreaSize.screenWidth :
      totalWorkingAreaSize.screenWidth - ((wPercent / 100) * totalWorkingAreaSize.screenWidth);

    let h = totalWorkingAreaSize.height - workingAreaHeight;
    let hPercent = (h / totalWorkingAreaSize.height) * 100;
    let screenHeight = h == 0 ? totalWorkingAreaSize.screenHeight :
      totalWorkingAreaSize.screenHeight - ((hPercent / 100) * totalWorkingAreaSize.screenHeight);

    return [screenWidth, screenHeight];
  };

  static translateOffsetX = (totalWorkingAreaSize: TotalWorkingAreaSize, offset: number, location: number): number => {
    let w = totalWorkingAreaSize.width - offset - location;
    let wPercent = (w / totalWorkingAreaSize.width) * 100;
    let screenTotalOffset = w == 0 ? totalWorkingAreaSize.screenWidth :
      totalWorkingAreaSize.screenWidth - ((wPercent / 100) * totalWorkingAreaSize.screenWidth);

    return screenTotalOffset;
  };

  static translateOffsetY = (totalWorkingAreaSize: TotalWorkingAreaSize, offset: number, location: number): number => {
    let h = totalWorkingAreaSize.height - offset - location;
    let hPercent = (h / totalWorkingAreaSize.height) * 100;
    let screenTotalOffset = h == 0 ? totalWorkingAreaSize.screenHeight :
      totalWorkingAreaSize.screenHeight - ((hPercent / 100) * totalWorkingAreaSize.screenHeight);

    return screenTotalOffset;
  };
}

export default WorkingAreaUtil;