import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Field } from "components/properties/property.data";

interface IValidationData {
    field: Field,
    isValid: boolean
}

interface ValidationDataState {
    fields: IValidationData[]
}

const initialState: ValidationDataState = {
    fields: []
}

export const ValidationDataSlice = createSlice({
    name: 'validationData',
    initialState,
    reducers: {
        addValidation: (state, action: PayloadAction<IValidationData>) => {
            state.fields.push(JSON.parse(JSON.stringify(action.payload)))
        },
        removeValidation: (state, action: PayloadAction<Field>) => {
            state.fields = state.fields.filter(({ field }) => field.id !== action.payload.id)
        }
    }
})

export default ValidationDataSlice.reducer;
export const { addValidation, removeValidation } = ValidationDataSlice.actions;