import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISetup } from "data/setup/setupData";

export interface SelectedElement {
    selectedSetup?: ISetup;
}

interface UserDataState {
    selectedElement: SelectedElement
}

const initialState: UserDataState = {
    selectedElement: {} as SelectedElement
}

export const SelectedElementSlice = createSlice({
    name: 'SelectedElement',
    initialState,
    reducers: {
        addSelectedElement: (state, action: PayloadAction<SelectedElement>) => {
            state.selectedElement = (JSON.parse(JSON.stringify(action.payload)));
        },
        editSelectedElement: (state, action: PayloadAction<SelectedElement>) => {
            state.selectedElement = (JSON.parse(JSON.stringify(action.payload)));
        },
        removeSelectedElement: (state) => {
            state.selectedElement = {} as SelectedElement;
        },
    }
})

export default SelectedElementSlice.reducer;
export const { addSelectedElement, editSelectedElement, removeSelectedElement } = SelectedElementSlice.actions;