import { useState } from "react";
import './side-navigation-menu.css';

export default function NavigationItem({ navigationItem, onItemClicked }: any) {

    const [isHovered, setHover] = useState(false);

    const onClicked = () => {
        onItemClicked(navigationItem.id);
    }

    return (
        <div>
            <div className={(navigationItem.expanded !== undefined && navigationItem.expanded == false) || navigationItem.isHeadLine ? 'hilighting-line-none' : 'hilighting-line'} />
            {(navigationItem.icon !== undefined && navigationItem.icon !== "") ?
                <div className={'render-style'} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={onClicked}>
                    <img className={'render-style-img'}
                        src={isHovered ? navigationItem.hoverIcon : navigationItem.itemClicked ? navigationItem.selectedIcon : navigationItem.icon} />
                    <div className={navigationItem.itemClicked ? 'render-style-text-selected' : 'render-style-text'}>{navigationItem.text}</div>
                </div>
                :
                <div className={'render-style'} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
                    <div className={'render-style-text'}>{navigationItem.text}</div>
                </div>
            }
        </div>)
}