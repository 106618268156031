import { ManufactureUser } from "data/user/userType";

export class LocalStorageHelper {

    static setLocalStorage = (manufactureUser: ManufactureUser) => {
        localStorage.setItem('manufactureUser', JSON.stringify(manufactureUser));
    }

    static getLocalStorage = (): ManufactureUser | undefined => {
        try {
            const item = localStorage.getItem('manufactureUser');
            if (item) {
                return JSON.parse(item) as ManufactureUser;
            }
            return undefined;
        } catch (error) {
            console.error('Erro ao analisar o localStorage:', error);
            return undefined;
        }
    };

    static clearLocalStorage = () => {
        localStorage.removeItem('manufactureUser');
        localStorage.clear();
    }
}