import TranslatorHelper from '../../helpers/translatorHelper';
const translatorHelper = new TranslatorHelper();
export const NavigationTranslates = () => {
    return {
        Setups: translatorHelper.getTranslatedValue('Setups'),
        Nesting: translatorHelper.getTranslatedValue('Nesting'),
        Tools: translatorHelper.getTranslatedValue('Tools'),
        Drills: translatorHelper.getTranslatedValue('Drills'),
        Mills: translatorHelper.getTranslatedValue('Mills'),
        Saws: translatorHelper.getTranslatedValue('Saws'),
        WorkingAreas: translatorHelper.getTranslatedValue('WorkingAreas'),
    };
};
