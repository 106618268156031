import { jwtDecode } from 'jwt-decode';
import { IdTokenClaims, UserProfile } from 'oidc-client-ts';

export function extractTokenData(token: string): UserProfile {
    try {
        const decoded = jwtDecode<UserProfile>(token);
        decoded['accountID'] = extractMainAccountID(decoded);
        return decoded;
    } catch (error) {
        console.error('Erro ao decodificar o token:', error);
        throw new Error('Token inválido');
    }
}

function extractMainAccountID(decoded: IdTokenClaims) {
    return Number(decoded["http://promobid.promob.com/identity/security/claims/defaultaccountid"]) || 0;
}