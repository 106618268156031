import { IToolSettings } from "../../../data/settings/settingsData";

export const getSelectedToolIDByProcessID = (toolSettingsDTO: IToolSettings, operationType: string): { [key: string]: object } => {
    const selectedToolByProcessID: { [key: string]: object } = {};

    if (!toolSettingsDTO.toolIDByProcessID || !toolSettingsDTO.selectedToolOrProcessIDByOperationType) {
        return selectedToolByProcessID;
    }

    const listIDs = toolSettingsDTO.selectedToolOrProcessIDByOperationType[operationType];
    if (listIDs) {
        (listIDs as string[]).forEach((id: string) => checkAndAddToolID(toolSettingsDTO.toolIDByProcessID!, selectedToolByProcessID, id));
    }

    return selectedToolByProcessID;
}

const checkAndAddToolID = (toolIDByProcessID: { [key: string]: object }, selectedToolByProcessID: { [key: string]: object }, id: string): void => {
    if (selectedToolByProcessID.hasOwnProperty(id)) {
        return;
    }

    const toolID = toolIDByProcessID[id];
    if (!toolID) {
        return;
    }

    if (typeof toolID === 'string') {
        const guidConverted = tryParseGuid(toolID);
        if (guidConverted) {
            selectedToolByProcessID[id] = guidConverted;
            return;
        }
    }

    if ((typeof toolID === 'string' && toolID !== '') || (Array.isArray(toolID) && toolID.length > 0)) {
        selectedToolByProcessID[id] = toolID;
    }
}

const tryParseGuid = (value: string): object | null => {
    const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return guidPattern.test(value) ? { guid: value } : null;
}

export const removeItemFromDictionary = (dictionary: { [key: string]: object }, key: string): { [key: string]: object } => {
    const newDictionary = { ...dictionary };

    if (key in newDictionary) {
        delete newDictionary[key];
    }

    return newDictionary;
}

export const addItemToDictionary = (
    dictionary: { [key: string]: object },
    key: string
): { [key: string]: object } => {
    const newDictionary = { ...dictionary };

    if (key in newDictionary) {
        if (Array.isArray(newDictionary[key])) {
            if (!(newDictionary[key] as any[]).includes(key)) {
                (newDictionary[key] as any[]).push(key);
            }
        } else {
            newDictionary[key] = [key];
        }
    } else {
        newDictionary[key] = [];
    }

    return newDictionary;
};


export const removeValueFromDictionary = (dictionary: { [key: string]: object }, key: string, value: string): { [key: string]: object } => {
    const newDictionary = { ...dictionary };

    if (newDictionary[key]) {
        newDictionary[key] = (newDictionary[key] as string[]).filter(item => item !== value);
    }

    return newDictionary;
}

export const addValueToDictionary = (dictionary: { [key: string]: object | string[] }, key: string, value: string): { [key: string]: object | string[] } => {
    const newDictionary = { ...dictionary };

    if (Array.isArray(newDictionary[key])) {
        if (!(newDictionary[key] as string[]).includes(value)) {
            (newDictionary[key] as string[]).push(value);
        }
    } else {
        newDictionary[key] = [value];
    }

    return newDictionary;
}

const cleanToolIDs = (toolSettingsDTO: IToolSettings, operationType: string, ids: string[]): void => {
    if (!toolSettingsDTO.selectedToolOrProcessIDByOperationType) return;

    if (toolSettingsDTO.selectedToolOrProcessIDByOperationType.hasOwnProperty(operationType)) {
        const currentIds = toolSettingsDTO.selectedToolOrProcessIDByOperationType[operationType] as string[];
        toolSettingsDTO.selectedToolOrProcessIDByOperationType[operationType] = currentIds
            .filter(id => ids.includes(id) || toolSettingsDTO.toolIDByProcessID?.hasOwnProperty(id));
    }
};

export const addToolIDsByType = (toolSettingsDTO: IToolSettings, operationType: string, ids: string[]): void => {
    if (!toolSettingsDTO.selectedToolOrProcessIDByOperationType) {
        toolSettingsDTO.selectedToolOrProcessIDByOperationType = {};
    }

    if (toolSettingsDTO.selectedToolOrProcessIDByOperationType.hasOwnProperty(operationType)) {
        const currentIds = toolSettingsDTO.selectedToolOrProcessIDByOperationType[operationType] as string[];
        ids.filter(id => !currentIds.includes(id)).forEach(id => currentIds.push(id));
    } else {
        toolSettingsDTO.selectedToolOrProcessIDByOperationType[operationType] = ids.slice();
    }

    cleanToolIDs(toolSettingsDTO, operationType, ids);
};

export const isEmptyGuid = (guid: string): boolean => {
    const emptyGuid = "00000000-0000-0000-0000-000000000000";
    return guid === emptyGuid;
};