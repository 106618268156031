import { ISettings } from "../data/settings/settingsData";
import { IHandler } from "./handlerManager";

export class AdditionalSettingsHandler implements IHandler {
    canHandle(bind: string): boolean {
        const regex = new RegExp("AdditionalSettings\\[(.*)\\]");
        const match = regex.exec(bind.split('.').pop() || '');
        return !!match;
    }

    handleTo(ogObject: object, object: object, value: any, bind: string) {
        const settings = object as ISettings;
        if (settings !== undefined) {
            const key = bind.match(/\[(.*?)\]/g)?.toString().replace('[', '').replace(']', '') ?? "";

            if (settings.additionalSettings![key] !== undefined) {
                return settings.additionalSettings![key];
            }
        }

        return value;
    }

    handleBack(ogObject: object, object: object, value: any, bind: string) {
        const settings = object as ISettings;
        if (settings !== undefined) {
            const key = bind.match(/\[(.*?)\]/g)?.toString().replace('[', '').replace(']', '') ?? "";

            if (settings.additionalSettings === undefined) {
                settings.additionalSettings = {};
            }

            settings.additionalSettings[key] = value;
        }

        return value;
    }
}