import React from "react";
import { useLoader } from "contexts/loader.context";
import './loading.overlay.scss';

interface LoadingProps {
  isLoading: boolean;
}

const LoadingOverlay: React.FC<LoadingProps> = ({ isLoading }) => {

  const { isLoaderActive } = useLoader();

  if (isLoading || isLoaderActive) {
    return (
      <div>
        {isLoaderActive && (
          <div className="screen-config-disable">
          </div>
        )}
        {isLoading && (
          <div className="screen-config">
            <div className='center-screen-spinner'>
              <div className="border-gray-300 h-10 w-10 animate-spin rounded-full border-8 border-t-purple-600" />
            </div>
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default LoadingOverlay;