import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'devextreme-react/progress-bar';
import SingleCard from 'layouts/single-card/single-card';
import { ProgressBarTranslates } from 'translator/components/progress.bar.translate';
import authService from 'cyncly-login/authService';
import './progress.bar.component.css';

const { LoadingInformations, Welcome } = ProgressBarTranslates();

const maxValue = 4;

interface progressBarType {
  currentStep?: number,
  profileName?: string
}

function statusFormat(ratio: number) {
  return `${LoadingInformations}: ${ratio * 100}%`;
}

const elementAttr = { 'aria-label': 'Progress Bar' };

export default function ProgressBarComponent({ profileName }: progressBarType) {

  const [step, setStep] = useState(maxValue);
  const [userName, setUserName] = useState<string | undefined>(profileName);

  // useEffect(() => {
  //   setStep(currentStep ?? 0);
  // }, [currentStep]);

  return (
    <SingleCard >
      {profileName &&
        (<p className='p-bottom'>
          <span className='welcome-style'>{Welcome}, </span>
          <span className='welcome-user-style'>{profileName}</span>
        </p>)
      }
      <div className='center-screen-spinner'>
        <div className="border-gray-300 h-10 w-10 animate-spin rounded-full border-8 border-t-purple-600" />
      </div>
    </SingleCard>
  );
}