import { SignoutResponse, User, UserManager, UserManagerSettings } from 'oidc-client-ts';
import { extractTokenData } from './extractTokenData';

class AuthService {
  private userManager: UserManager;

  constructor() {
    const settings = {
      authority: 'https://flex-login.compusoftgroup.com',
      client_id: 'ManufactureEngineCF',
      redirect_uri: `${window.location.origin}/login`,
      post_logout_redirect_uri: `${window.location.origin}/logout`,
      response_type: 'code',
      scope: 'openid profile',
      loadUserInfo: false,
      extraQueryParams: {
        idp_list: '17'
      },
      automaticSilentRenew: false
    };
    this.userManager = new UserManager(settings);
  }

  async getUser(): Promise<User | null> {
    let flexUser = await this.userManager.getUser();
    if (flexUser)
      flexUser.profile = extractTokenData(flexUser?.access_token);

    return flexUser;
  }

  signinCallback(): Promise<void | User> {
    return this.userManager.signinCallback();
  }

  login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  renewToken(): Promise<User | null> {
    return this.userManager.signinSilent().catch(ex => {
      return null;
    }).then((user: User | null) => {
      if (user)
        return this.getUser();
      else
        return null;
    });
  }

  logout(): Promise<void> {
    return this.userManager.signoutPopup({
      extraQueryParams: {
        idp_list: '17'
      },
      popupWindowFeatures: {
        closePopupWindowAfterInSeconds: 0.000000001
      },
      post_logout_redirect_uri: `${window.location.origin}/login`
    }).catch((err: any) => { });
  }
}

const authService = new AuthService();
export default authService;