import { IConverter } from "./converterManager";
import { Vector3 } from "../data/utils/vectorsData";
import { eReferencePoint } from "../data/settings/settingsData"

export class AlignmentFaceConverter implements IConverter {

    private readonly _zeroAlignmentPlane: Vector3 = new Vector3();
    private readonly _leftAlignmentPlane: Vector3 = new Vector3(-1, 0);
    private readonly _topAlignmentPlane: Vector3 = new Vector3(0, 1);
    private readonly _rightAlignmentPlane: Vector3 = new Vector3(1, 0);
    private readonly _bottomAlignmentPlane: Vector3 = new Vector3(0, -1);
    private readonly _leftBottomAlignmentPlane: Vector3 = new Vector3(-1, -1);
    private readonly _leftTopAlignmentPlane: Vector3 = new Vector3(-1, 1);
    private readonly _rightBottomAlignmentPlane: Vector3 = new Vector3(1, -1);
    private readonly _rightTopAlignmentPlane: Vector3 = new Vector3(1, 1);

    canConvert(bind: string): boolean {
        if (bind === "SettingsDTO.MachineSettings.AlignmentFace")
            return true;

        return false;
    }

    convertTo(value: any) {
        if (value === undefined) return eReferencePoint.None;

        const alignments = JSON.parse(JSON.stringify(value)) as Vector3[];
        const alignment = Vector3.plus(alignments[0] ?? this._zeroAlignmentPlane, alignments[1] ?? this._zeroAlignmentPlane) ?? this._zeroAlignmentPlane;

        if (Vector3.sameValues(alignment, this._zeroAlignmentPlane))
            return eReferencePoint.None;

        if (Vector3.sameValues(alignment, this._leftAlignmentPlane))
            return eReferencePoint.Left;

        if (Vector3.sameValues(alignment, this._topAlignmentPlane))
            return eReferencePoint.Top;

        if (Vector3.sameValues(alignment, this._rightAlignmentPlane))
            return eReferencePoint.Right;

        if (Vector3.sameValues(alignment, this._bottomAlignmentPlane))
            return eReferencePoint.Bottom;

        if (Vector3.sameValues(alignment, this._leftBottomAlignmentPlane))
            return eReferencePoint.BottomLeft;

        if (Vector3.sameValues(alignment, this._leftTopAlignmentPlane))
            return eReferencePoint.TopLeft;

        if (Vector3.sameValues(alignment, this._rightBottomAlignmentPlane))
            return eReferencePoint.BottomRight;

        if (Vector3.sameValues(alignment, this._rightTopAlignmentPlane))
            return eReferencePoint.TopRight;

        return eReferencePoint.None;
    }

    convertBack(value: any) {
        if (value === eReferencePoint.None)
            return [this._zeroAlignmentPlane];

        if (value === eReferencePoint.Left)
            return [this._leftAlignmentPlane];

        if (value === eReferencePoint.Top)
            return [this._topAlignmentPlane];

        if (value === eReferencePoint.Right)
            return [this._rightAlignmentPlane];

        if (value === eReferencePoint.Bottom)
            return [this._bottomAlignmentPlane];

        if (value === eReferencePoint.BottomLeft)
            return [this._leftAlignmentPlane, this._bottomAlignmentPlane];

        if (value === eReferencePoint.TopLeft)
            return [this._leftAlignmentPlane, this._topAlignmentPlane];

        if (value === eReferencePoint.BottomRight)
            return [this._rightAlignmentPlane, this._bottomAlignmentPlane];

        if (value === eReferencePoint.TopRight)
            return [this._rightAlignmentPlane, this._topAlignmentPlane];

        return [this._zeroAlignmentPlane];
    }
}