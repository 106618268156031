import { addToolIDsByType, removeItemFromDictionary } from "../../components/properties/util/property.editor.util";
import { IMachineSettings, ISettings } from "../../data/settings/settingsData";
import { IHandler } from "../handlerManager";

export class SelectedInsertionProcessesHandler implements IHandler {
    canHandle(bind: string): boolean {
        return bind === "SettingsDTO.InsertionSettings.AvailableProcesses";
    }

    handleTo(ogObject: object, object: object, value: any, bind: string) {
        const insertionSettings = object as any;
        if (insertionSettings !== undefined) {
            if (insertionSettings!.availableProcesses?.length !== 0) {
                return insertionSettings!.availableProcesses;
            }
        }

        return value;
    }

    handleBack(ogObject: object, object: object, value: any, bind: string) {
        const insertionSettings = object as any;
        const settings = ogObject as ISettings;
        if (insertionSettings !== undefined) {
            insertionSettings.availableProcesses = value?.map((v: any) => {
                if (v.enablingProcessOptionsType === 1) {
                    return v.processOutput?.processId
                }
            })
                .filter((v: any) => v !== undefined);

            settings.machineSettings.machiningDisabledProcesses = value?.map((v: any) => {
                if (v.enablingProcessOptionsType === 0) {
                    return v.processOutput?.processId
                }
            })
                .filter((v: any) => v !== undefined);

            return insertionSettings.availableProcesses;
        }

        return value;
    }
}