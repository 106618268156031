export abstract class UtilsTranslate {

    private static translations: { [key: string]: any } = {};

    public static getTranslationFile = (): any => {
        const language = this.getWindowsLanguage();
        if (!this.translations[language]) {
            this.translations[language] = require(`../translator/Resources/Languages/${language}.Builder_Settings`);
        }
        return this.translations[language];
    }

    public static getWindowsLanguage(): string {
        const language = localStorage.getItem('language');

        if (language) return language;

        if (typeof window === "undefined" || typeof window.Intl === "undefined") {
            return "EN";
        }

        const locale = window.Intl.DateTimeFormat().resolvedOptions().locale;
        const languageMap: { [key: string]: string } = {
            'es': 'ES',
            'pt': 'PT',
            'en': 'EN'
        };

        for (const [prefix, lang] of Object.entries(languageMap)) {
            if (locale.startsWith(prefix)) {
                return lang;
            }
        }

        return "EN";
    }
}