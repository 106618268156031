export interface IFace {
    face?: string;
    axis?: string;
    value?: number;
}

export interface IFaceDataValue {
    [key: string]: {
        value: number;
    };
}

export enum FacesType {
    f1 = 'F1',
    f2 = 'F2',
    f3 = 'F3',
    f4 = 'F4',
    f5 = 'F5',
    f6 = 'F6'
}

export enum ZeroPointType {
    leftBottom = 'LeftBottom',
    rightBottom = 'RightBottom',
    leftTop = 'LeftTop',
    rightTop = 'RightTop'
}