import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IMachine } from "../data/machine/machineData";

interface MachineDataState {
    machines: IMachine[]
}

const initialState: MachineDataState = {
    machines: []
}

export const MachineDataSlice = createSlice({
    name: 'machineData',
    initialState,
    reducers: {
        addMachine: (state, action: PayloadAction<IMachine>) => {
            state.machines.push(JSON.parse(JSON.stringify(action.payload)))
        },
        editMachine: (state, action: PayloadAction<IMachine>) => {
            var index = state.machines.findIndex(s => s.id === action.payload.id)
            if (index !== -1) {
                state.machines[index] = action.payload
            } else {
                state.machines.push(JSON.parse(JSON.stringify(action.payload)))
            }
        },
        removeMachine: (state, action: PayloadAction<IMachine>) => {
            state.machines = state.machines.filter(({ id }) => id !== action.payload.id)
        }
    }
})

export default MachineDataSlice.reducer;
export const { addMachine, editMachine, removeMachine } = MachineDataSlice.actions;