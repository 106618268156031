import React, { useEffect, useState } from "react";
import TextBox from "devextreme-react/text-box";
import { Field, NewProperty } from '../properties/property.data';

export interface PropTypes {
  property: Field | null;
  type: string; 
  onPropertyChange: (property: Field) => void;
}

export default function ColumnEditorRow({ property,  type, onPropertyChange }: PropTypes) {
  const [state, setState] = useState<Field>(
    property || NewProperty()
  );

  useEffect(() => {
    setState(property || NewProperty());
  }, [property]);

  const handleCheckChange = (event: string, field: string) => {    
    const newState = {
      ...state,
      [field]: event
    };

    setState({...newState});
    onPropertyChange({...newState});
  };

  return (
    <React.Fragment>
      <div className="dx-field">
        <label className="div-label-column-editor-row">Titulo</label>
        <TextBox
          className="textbox-column-editor-row"
          value={state.value}
          onValueChange={(event) => handleCheckChange(event, "value")}
        />
      </div>
      <div className="dx-field">
        <label className="div-label-column-editor-row">Prefixo</label>
        <TextBox
          className="textbox-column-editor-row"
          value={state.prefix}
          onValueChange={(event) => handleCheckChange(event, "prefix")}
        />
      </div>

      {type === "selectedColumn" && (
      <div className="div-column-EditorRow">
        {(state.id === "systemAttribute" || state.id === "systemReference") && (
          <div className="dx-field">
            {state.id === "systemAttribute" &&(
            <label className="div-label-column-editor-row">AtributoSistema</label>
            )}
            {state.id === "systemReference" &&(
            <label className="div-label-column-editor-row">ReferênciaSistema</label>
            )}
            <TextBox
              className="textbox-column-editor-row"
              value={state.suffix}
              onValueChange={(event) => handleCheckChange(event, "suffix")}
            />
          </div>
        )}
      </div>
       )}
      <div className="dx-field label-column-editor-row">
        <label>{state.visibleValue}</label>
      </div>
    </React.Fragment>
  );
}