export enum editorTypeEnum {
    Undefined = "Undefined",
    FilePicker = "FilePicker",
    FolderPicker = "FolderPicker",
    OperationsOrder = "OperationsOrder",
    PrioritizationOrder = "PrioritizationOrder",
    SelectInsertionProcesses = "SelectInsertionProcesses",
    SelectSlotProcesses = "SelectSlotProcesses",
    SelectMachiningProcesses = "SelectMachiningProcesses",
    SelectSlotTools = "SelectSlotTools",
    SelectMachiningTools = "SelectMachiningTools",
    SelectOrtogonalMachiningTools = "SelectOrtogonalMachiningTools",    
    SelectSlotToolsByProcesses = "SelectSlotToolsByProcesses",
    SelectMachiningToolsByProcesses = "SelectMachiningToolsByProcesses",
    ContourToolsByConditions = "ContourToolsByConditions",
    ToolsByConditionRules = "ToolsByConditionRules",
    SelectSolidFaces = "SelectSolidFaces",
    ZeroPoint = "ZeroPoint",
    ZeroPointByFace = "ZeroPointByFace",
    ToolByConditionRule = "ToolByConditionRule",
    SelectTools = "SelectTools",
    ToolByConditionEditRules = "ToolByConditionEditRules",
    ContourToolByThickness = "ContourToolByThickness",
    LabelSettingsSelectedEditor = "LabelSettingsSelectedEditor",
    FullLabelSettingsSelectedEditor = "FullLabelSettingsSelectedEditor"
}