import { IPromobPlugin } from "data/user/promobType";

export class UtilsSystem {

    static getPromob5Systems(promobPlugins: IPromobPlugin[]): any[] {
        return promobPlugins
            .filter(plugin => plugin.productType === 'Promob5')
            .map(plugin => ({
                id: plugin.library.toString(),
                name: plugin.name,
            }));
    }

    static getStartLabsSystemID = "424";
}