import '../Header.scss';

import React, { useEffect, useState } from 'react';
import { HeaderButtonProps } from '../../../types';
import { ClickEvent } from 'devextreme/ui/button';
import warningIcon from '../../../assets/images/icons/warning.svg';
import { eHeaderButtonVisibility } from './header.button.visibility.types'
import { store, useAppSelector } from 'store/store';
import { Tooltip } from 'devextreme-react';

export const HeaderButton: React.FC<HeaderButtonProps> = (props) => {

    const [isHover, setIsHover] = useState<boolean>(false);
    const [hasInvalidFields, setHasInvalidFields] = useState<boolean>(false);
    const checkValidations = useAppSelector((state) => state.validationDataState.fields);

    useEffect(() => {
        setHasInvalidFields(store.getState().validationDataState.fields.some(s => !s.isValid));
    }, [checkValidations]);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        props.buttonHandler(e as unknown as ClickEvent);
        if (props.customButtonEvents) {
            props.customButtonEvents();
        }
    };

    const { icon, hoverIcon, text, disabled, disabledIcon, visibilityType } = props;

    const isInvalidButton = () => (visibilityType === eHeaderButtonVisibility.SAVE && hasInvalidFields);

    return (
        <button className='button-layout' disabled={isInvalidButton() || disabled} onClick={handleClick} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <img className='button-icon'
                src={isHover && !isInvalidButton() ? hoverIcon : isInvalidButton() || disabled ? disabledIcon : icon} />
            <div className={(visibilityType === eHeaderButtonVisibility.SAVE && hasInvalidFields) || disabled ? 'button-text-disabled' : 'button-text'}>
                {text}
            </div>
            {isInvalidButton() &&
                <div>
                    <img className='warning-icon' src={warningIcon} id='warning' />
                    <Tooltip
                        target="#warning"
                        showEvent="mouseenter"
                        hideEvent="mouseleave"
                        hideOnOutsideClick={false}>
                        <div className='warning-background'>Campos obrigatórios</div>
                    </Tooltip>
                </div>}
        </button>
    );
};
