import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface PromobAccount {
    name: string;
    accountId: number;
    active: boolean;
}

interface UserDataState {
    accountInfo: PromobAccount
}

const initialState: UserDataState = {
    accountInfo: {} as PromobAccount
}

export const LogedAccountInfoDataSlice = createSlice({
    name: 'AccountInfo',
    initialState,
    reducers: {
        addLogedAccountInfo: (state, action: PayloadAction<PromobAccount>) => {
            state.accountInfo = (JSON.parse(JSON.stringify(action.payload)));
        },
        editLogedAccountInfo: (state, action: PayloadAction<PromobAccount>) => {
            state.accountInfo = (JSON.parse(JSON.stringify(action.payload)));
        },
        removeLogedAccountInfo: (state) => {
            state.accountInfo = {} as PromobAccount;
        },
    }
})

export default LogedAccountInfoDataSlice.reducer;
export const { addLogedAccountInfo, editLogedAccountInfo, removeLogedAccountInfo } = LogedAccountInfoDataSlice.actions;