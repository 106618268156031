
import TranslatorHelper from '../../helpers/translatorHelper';
const translatorHelper = new TranslatorHelper();
export const SaveTranslates = () => {
    return {
        SaveSuccess: translatorHelper.getTranslatedValue('SaveSuccess'),
        SaveError: translatorHelper.getTranslatedValue('SaveError'),
        SaveExpiredToken: translatorHelper.getTranslatedValue('SaveExpiredToken'),
        SavingData: translatorHelper.getTranslatedValue('SavingData')
    };
};
