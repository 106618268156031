import { addToolIDsByType } from "../../components/properties/util/property.editor.util";
import { ISettings, IToolSettings } from "../../data/settings/settingsData";
import { IMill } from "../../data/tools/toolData";
import { IHandler } from "../handlerManager";

export class SelectedSlotToolsHandler implements IHandler {
    canHandle(bind: string, editorName: string): boolean {
        return bind === "SettingsDTO.ToolSettings.SelectedToolOrProcessIDByOperationType" && editorName === "SelectSlotTools";
    }

    handleTo(ogObject: object, object: object, value: any, bind: string) {
        const toolSettings = object as IToolSettings;
        if (toolSettings !== undefined) {
            if (Object.keys(toolSettings!.selectedToolOrProcessIDByOperationType!)?.length !== 0
                && (!toolSettings!.selectedToolOrProcessIDByOperationType!.hasOwnProperty("SlotDTO")
                || Object.keys(toolSettings!.selectedToolOrProcessIDByOperationType!["SlotDTO"]).length === 0)) {
                return toolSettings!.selectedToolOrProcessIDByOperationType;
            }
        }

        return value;
    }

    handleBack(ogObject: object, object: object, value: any, bind: string) {
        const toolSettings = object as IToolSettings;
        const settings = ogObject as ISettings;
        if (settings !== undefined && toolSettings !== undefined) {
            addToolIDsByType(toolSettings, "SlotDTO", (value as any[]).map(tool => tool.id).concat(settings.slotSettings.availableProcesses));

            return toolSettings.selectedToolOrProcessIDByOperationType;
        }

        return value;
    }
}