import './Header.scss';

import React, { useEffect, useState } from 'react';
import type { HeaderProps } from '../../types';
import { headerButtonConfig } from './header-button/header-button-config';
import { eHeaderButtonVisibility } from './header-button/header.button.visibility.types';
import { ClickEvent } from 'devextreme/ui/button';
import PageOptions from '../pages/options/page.options.component';
import useSaveAction from 'components/actions/save.action';
import { ButtonsTranslates } from 'translator/components/buttons.translate';
import { store } from 'store/store';
import { Utils } from 'utils/utils';
import { HeaderButton } from './header-button/header-button';

const { EnableSetups, DisableSetups } = ButtonsTranslates();

export default function Header({ headerButtonsVisibility, buttonHandlers, pageData }: HeaderProps) {
  const [visibleGroup, setVisibleGroup] = useState<any[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const [buttonConfigs, setButtonConfigs] = useState(headerButtonConfig);
  const saveAction = useSaveAction();

  useEffect(() => {
    checkEnabeDisableButton();
    checkDisableDeleteButton();
  }, [store.getState().selectedElementDataState.selectedElement]);

  useEffect(() => {
    checkDisableDeleteButton();
  }, [pageData]);

  const checkEnabeDisableButton = async () => {
    let currentSetup = store.getState().selectedElementDataState.selectedElement;
    if (currentSetup?.selectedSetup?.selected) {
      const enabled = currentSetup?.selectedSetup?.settings?.isEnabled ?? true;
      checkEnableEvents(null, true, enabled);
    }
  }

  const checkDisableDeleteButton = async () => {
    if (pageData)
      disableDeleteButton(pageData.length === 1);
  }

  const onSave = async () => {
    await saveAction();
  }

  const onOptionsClick = () => {
    setShowOptions(true);
  };

  const onOptionsClose = () => {
    setShowOptions(false);
  }

  headerButtonsVisibility?.push(eHeaderButtonVisibility.SAVE);
  buttonHandlers?.push([eHeaderButtonVisibility.SAVE, onSave]);
  buttonHandlers?.push([eHeaderButtonVisibility.OPTIONS, onOptionsClick]);

  const isVisibleGroup = (gr: any, e: eHeaderButtonVisibility) => {
    let includes = headerButtonsVisibility.includes(e);
    if (includes === true) {
      if (!visibleGroup.includes(gr))
        setVisibleGroup(prev => {
          return [...prev, gr];
        });
    }
    return includes;
  };

  const isNotLastItem = (group: any) => {
    let index = visibleGroup.indexOf(group);

    if (group.items?.length > 1)
      index = group.items.length;

    return index !== visibleGroup.length - 1;
  };

  function getCorrectHandler(
    onclick: () => void, visibilityType: eHeaderButtonVisibility,
    buttonHandlers: [eHeaderButtonVisibility, (e: ClickEvent) => void][] | undefined)
    : (e: ClickEvent) => void {

    if (!buttonHandlers) {
      return onclick;
    }

    const tuple = buttonHandlers.find(([type, handler]) => type === visibilityType);

    if (tuple) {
      return tuple[1];
    } else {
      return onclick;
    }
  }

  function disableDeleteButton(disable: boolean) {
    const updatedConfigs = buttonConfigs?.map(obj => {
      obj.items.forEach(btn => {
        if (btn.visibilityType === eHeaderButtonVisibility.DELETE)
          btn.disabled = disable;
      });
      return {
        ...obj
      };
    });

    setButtonConfigs(updatedConfigs);
  }

  function checkEnableEvents(button?: any, startCheck?: boolean, startEnable?: boolean) {
    if (button?.visibilityType === eHeaderButtonVisibility.ENABLE_SETUPS || startCheck) {
      const updatedConfigs = buttonConfigs?.map(obj => {
        if (obj.items[0].text === EnableSetups) {
          if ((startCheck && startEnable) || (button && !startCheck)) {
            obj.items[0].text = DisableSetups;
          }
        }
        else if (obj.items[0].text === DisableSetups) {
          if ((startCheck && !startEnable) || (button && !startCheck)) {
            obj.items[0].text = EnableSetups;
          }
        }
        return {
          ...obj
        };
      });

      setButtonConfigs(updatedConfigs);
    }
  }

  return (
    <>
      <div className={'toolbar-layout'} id={Utils.generateGUID()}>
        {
          buttonConfigs.map((btnsGroup, groupKey) =>
            [
              btnsGroup.items.map((btn, btnKey) =>
              (isVisibleGroup(btnsGroup, btn.visibilityType) &&
                <HeaderButton
                  icon={btn.icon} hoverIcon={btn.hoverIcon} text={btn.text} disabledIcon={btn.disabledIcon} disabled={btn.disabled}
                  buttonHandler={getCorrectHandler(btn.onclick, btn.visibilityType, buttonHandlers)} visibilityType={btn.visibilityType}
                  key={btnKey} />)
              ),

              (visibleGroup.includes(btnsGroup) &&
                <div className={(isNotLastItem(btnsGroup)) ? 'divider' : 'divider-none'} key={groupKey} />
              )
            ]
          )
        }
      </div>
      {showOptions && <PageOptions show={showOptions} onClose={onOptionsClose} onButtonOk={() => { }} />}
    </>
  );
}