import TranslatorHelper from '../../helpers/translatorHelper';
const translatorHelper = new TranslatorHelper();
export const ButtonsTranslates = () => {
    return {
        DisableSetups: translatorHelper.getTranslatedValue('DisableSetups'),
        EnableSetups: translatorHelper.getTranslatedValue('EnableSetups'),
        Save: translatorHelper.getTranslatedValue('Save'),
        Copy: translatorHelper.getTranslatedValue('Copy'),
        Add: translatorHelper.getTranslatedValue('Add'),
        Delete: translatorHelper.getTranslatedValue('Delete'),
        Options: translatorHelper.getTranslatedValue('Options'),
        MoveDown: translatorHelper.getTranslatedValue('MoveDown'),
        MoveUp: translatorHelper.getTranslatedValue('MoveUp'),
    };
};
