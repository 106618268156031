import NotificationComponent from 'components/notification/notification.component';
import React, { createContext, useContext, useState, FC } from 'react';

interface NotificationConfig {
    isVisible: boolean,
    type: 'info' | 'error' | 'success',
    message: string
}

interface NotificationContextProps {
    notificationConfig: NotificationConfig,
    setNotificationConfig: (notificationConfig: NotificationConfig) => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

const NotificationProvider: FC<{ children: React.ReactNode }> = ({ children }) => {

    const [notificationConfig, setNotificationConfig] = useState<NotificationConfig>({
        isVisible: false,
        type: 'info',
        message: '',
    });

    return (
        <NotificationContext.Provider value={{ notificationConfig, setNotificationConfig }}>
            <NotificationComponent />
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotification = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }
    return context;
};

export default NotificationProvider;