export enum eToolsSelect {
    MILL,
    SAW
}

export enum eToolByConditionRuleOpenEditorType {
    TOOLS_EDITOR,
    RULES_EDITOR
}

export enum enablingProcessOptionsType {
    Disabled,
    Enabled,
    EnabledWithMachiningTool,
    EnabledWithSlotTool,
    EnabledWithMachiningToolByProcess,
    EnabledWithSlotToolByProcess,
}