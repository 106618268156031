class FacesUtil {

    static getFaceByAxisAndValue = (axis: string, value: number) => {
        let sign = Math.sign(value);
        switch (axis) {
            case 'X':
                return sign > 0 ? 'F4' : 'F2';
            case 'Y':
                return sign > 0 ? 'F5' : 'F3';
            case 'Z':
                return sign > 0 ? 'F1' : 'F6';
        }
        return '';
    }

    static getAxisAndValueByFace(face: string): [string, number] {
        switch (face) {
            case 'F1':
                return ['Z', 1.0]
            case 'F2':
                return ['X', -1.0]
            case 'F3':
                return ['Y', -1.0]
            case 'F4':
                return ['X', 1.0]
            case 'F5':
                return ['Y', 1.0]
            case 'F6':
                return ['Z', -1.0]
        }
        return ['', 0];
    }
}

export default FacesUtil;