import { store } from "store/store";
import { getPromobAccountData } from "api/promob.request";
import { PromobAccount } from "store/loged.user.store";
import { useNotification } from "contexts/notification.context";
import { ExportTranslates } from "translator/components/export.translate";

const { ExportSuccess, ExportError } = ExportTranslates();

export default function useExportSettingsAction() {

    const state = store.getState();
    const getUser = state.userDataState.user;
    const { setNotificationConfig } = useNotification();

    const exportSettingsAction = async () => {
        try {
            let userAccountInfo = await getPromobAccountData<PromobAccount>(getUser.accessToken, String(getUser.accountID));

            let arr = [];
            const storedSettings = store.getState().settingsDataState.settings ?? [];
            for (const sett of storedSettings) {
                if (sett?.settingsToUpdate)
                    for (const up of sett?.settingsToUpdate)
                        arr.push(up);
            }

            const jsonString = JSON.stringify({ 'settings': arr }, null, 2);

            const blob = new Blob([jsonString], { type: 'application/json' });

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            const today = new Date();
            const date = `${String(today.getDate()).padStart(2, '0')}-${String(today.getMonth() + 1).padStart(2, '0')}-${today.getFullYear()}`;
            link.download = `ManufactureSettings-${userAccountInfo?.name}-${date}.json`;

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            setNotificationConfig({
                isVisible: true,
                message: ExportSuccess,
                type: 'success'
            });
        } catch {
            setNotificationConfig({
                isVisible: true,
                message: ExportError,
                type: 'error'
            });
        }
    };

    return exportSettingsAction;
};