import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum eCallEventType {
    MACHINE
}

export enum eTriggerCRUDType {
    ADD,
    EDIT,
    COPY,
    DELETE
}

export interface CallEvent {
    id: string
    isTriggered: boolean;
    text?: string;
    callEventType: eCallEventType,
    triggerCRUDType: eTriggerCRUDType,
    data?: any
}

interface UserDataState {
    triggerEvent: CallEvent[],
    forceTriggerEvent?: boolean
}

const initialState: UserDataState = {
    triggerEvent: [],
    forceTriggerEvent: false
}

export const CallEventDataSlice = createSlice({
    name: 'CallEvent',
    initialState,
    reducers: {
        addEventTrigger: (state, action: PayloadAction<CallEvent>) => {
            state.triggerEvent.push(JSON.parse(JSON.stringify(action.payload)));
        },
        editEventTrigger: (state, action: PayloadAction<CallEvent>) => {
            var index = state.triggerEvent.findIndex(s => s.id === action.payload.id)
            if (index !== -1) {
                state.triggerEvent[index] = action.payload
            } else {
                state.triggerEvent.push(JSON.parse(JSON.stringify(action.payload)))
            }
        },
        removeEventTrigger: (state, action: PayloadAction<CallEvent>) => {
            state.triggerEvent = state.triggerEvent.filter(({ id }) => id !== action.payload.id)
        },
        triggerForcedEventTrigger: (state) => {
            state.forceTriggerEvent = true;
        },
        removeForcedEventTrigger: (state) => {
            state.forceTriggerEvent = false;
        },
    }
})

export default CallEventDataSlice.reducer;
export const { addEventTrigger, removeEventTrigger, editEventTrigger, triggerForcedEventTrigger, removeForcedEventTrigger } = CallEventDataSlice.actions;