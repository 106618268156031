
import TranslatorHelper from '../../helpers/translatorHelper';
const translatorHelper = new TranslatorHelper();
export const ImportTranslates = () => {
    return {
        ImportSuccess: translatorHelper.getTranslatedValue('ImportSuccess'),
        ImportError: translatorHelper.getTranslatedValue('ImportError'),
        ImportModalTitle: translatorHelper.getTranslatedValue('ImportModalTitle'),
        ImportModalFile: translatorHelper.getTranslatedValue('ImportModalFile'),
    };
};
