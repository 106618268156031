import { Field } from "../property.data";

class FieldUtil {

    static getformFieldsBasedOnVisibilityCondition = (formState: any, visibilityCondition: string) => {
        const extractedContent = this.extractContentInBraces(visibilityCondition);
        const items: any = [];
        extractedContent.forEach(field => {
            let interessedField = formState.find((f: Field) => f.id === field);
            let interessedFieldValue = interessedField?.value;
            if (interessedFieldValue !== null) {
                items.push({
                    item1: field,
                    item2: interessedFieldValue
                });
            }
        });
        return items;
    };

    static getformFieldsBasedOnInterectionCondition = (formState: any, id: string) => {
        const items: any[] = [];
        const interessedField = formState.find((f: Field) => f.id === id);
        if (interessedField && interessedField.interaction.ids) {
            items.push({
                item1: interessedField.id,
                item2: interessedField.value
            });

            Object.keys(interessedField.interaction.ids).forEach((interactionId: string) => {
                const field = formState.find((f: Field) => f.id === interactionId);
                if (field && field.value !== null) {
                    items.push({
                        item1: field.id,
                        item2: field.value
                    });
                }
            });
        }
        return items;
    };

    static extractContentInBraces = (text: string): string[] => {
        const regex = /{([^}]*)}/g;
        const matches = text.match(regex) || [];
        return matches.map((match) => match.slice(1, -1));
    };
}

export default FieldUtil;