import React from 'react';

interface Props {
  data: {
    value: string;
    visibleValue: string;
    suffix?: string;
  };
}

function getValueText(rowInfo: Props) {
  if (rowInfo.data.suffix === undefined || rowInfo.data.suffix.length === 0) {
    return rowInfo.data.value;
  } else {
    return rowInfo.data.value + "-" + rowInfo.data.suffix;
  }
}

export default function NestingRow(rowInfo: Props) {
  return (
    <React.Fragment>
      <tr className="main-row">
        <td>{rowInfo.data.visibleValue}</td>
      </tr>
      <tr className="notes-row">
        <td>{getValueText(rowInfo)}</td>
      </tr>
    </React.Fragment>
  );
}