import React, { createContext, useContext } from 'react';
import TranslatorHelper from '../helpers/translatorHelper';

const TranslatorContext = createContext<TranslatorHelper | undefined>(undefined);

export const TranslatorProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const translatorHelper = new TranslatorHelper();

    return (
        <TranslatorContext.Provider value={translatorHelper}>
            {children}
        </TranslatorContext.Provider>
    );
};

export const useTranslator = (): TranslatorHelper => {
    const context = useContext(TranslatorContext);
    if (!context) {
        throw new Error('useTranslator must be used within a TranslatorProvider');
    }
    return context;
};