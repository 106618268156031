import React, { useEffect, useState } from "react";
import Modal from "../base/modal/modal";
import useModalHook from "../../hooks/modal.hook";
import { PropTypes } from "data/modal/modal.proprieties";
import { OperationType } from "../../data/operationOrder/operation";
import { EditorsTranslates } from "../../translator/components/editors.translate";
import './editor.css';
import { TreeList, Selection, Column, TreeListTypes } from 'devextreme-react/tree-list';
import { store } from "../../store/store";
import { ITreeListLabelSettings } from "./editor.interfaces";

const { LabelSettingsTitle } = EditorsTranslates();

export default function LabelSettingsSelected({ show, data, currentSettings, onClose, onButtonOk }: PropTypes) {
    const [treeListData, setTreeListData] = useState<ITreeListLabelSettings[]>([]);
    const [selectedData, setSelectedData] = useState<any>(currentSettings?.labelSettingsId);
    const { isOpen, toggle, open } = useModalHook();

    const state = store.getState();
    const getLabelSettings = state.userDataState.user.labelSettings;

    useEffect(() => {
        if (show) {
            populateLabelSettingsTreeList();
            open();
        }
    }, [data, show]);

    function populateLabelSettingsTreeList() {
        let settingsArray: ITreeListLabelSettings[] = [];

        for (let m of getLabelSettings) {
            let newTool: ITreeListLabelSettings = {
                id: m.id,
                name: m.name ?? ""
            };
            settingsArray.push(newTool);
        }

        settingsArray.sort((a, b) => a.name.localeCompare(b.name));

        setTreeListData(settingsArray);
    }

    const onSelectionChanged = (e: TreeListTypes.SelectionChangedEvent) => {
        let id = e.selectedRowsData.map((settings) => { return settings.id })[0];
        let dataSelectedSettings = getLabelSettings.filter(m => id === m.id);
        setSelectedData(dataSelectedSettings[0].id);
    };

    const getSelected = () => {
        let dataSelected = selectedData;
        if (treeListData)
            return treeListData.filter(m => m.id == dataSelected).map(m => m.id);
        else
            return [];
    };

    const saveData = () => onButtonOk(selectedData);

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} close={onClose} width="25%" height="40%" onButtonOk={saveData} modalHeader={LabelSettingsTitle}>
                <TreeList
                    id="tool-grid"
                    dataSource={treeListData}
                    showRowLines={true}
                    showBorders={true}
                    columnAutoWidth={true}
                    showColumnHeaders={false}
                    keyExpr="id"
                    defaultSelectedRowKeys={getSelected()}
                    onSelectionChanged={onSelectionChanged}>
                    <Selection recursive={true} mode="single" />
                    <Column dataField="name" />
                </TreeList>
            </Modal>
        </React.Fragment>
    );
}
