import React, { useEffect, useState } from 'react';
import TabPanel from 'devextreme-react/tab-panel';
import { useLocation } from 'react-router-dom';

const TabPanelComponent = ({ data, pageLoaded, currentData, onTabChanged }: any) => {

    const [tabLoaded, setTabLoaded] = useState<boolean>(false);
    const [order, setOrder] = useState<number>(0);
    const [tabData, setTabData] = useState<any[]>([]);
    const { state } = useLocation();

    useEffect(() => {
        setTabLoaded(pageLoaded);
    }, [pageLoaded]);

    useEffect(() => {
        clearData();
        setTabLoaded(false);
    }, [state.pageNavigation]);

    useEffect(() => {
        if (!tabLoaded) {
            setTabData([]);
            setOrder(0);
            setData();
        } else if (hasData() && data.length > tabData.length)
            addData();
        else if (hasData() && tabData.length > data.length)
            removeData();
        else
            editData();
    }, [data]);

    const hasData = () => Array.isArray(data) && data.length > 0;

    const clearData = () => {
        setTabData([]);
        setOrder(0);
    }

    const removeData = () => {
        if (Array.isArray(data) && data.length > 0) {
            const removeItem = tabData.find(d => !data.some(tab => tab.id === d.id));
            setTabData((prevTabData) =>
                prevTabData.filter((item) => item !== removeItem)
            );
        }
    }

    const addData = () => {
        if (Array.isArray(data) && data.length > 0) {
            const newItem = data.find(d => !tabData.some(tab => tab.id === d.id));
            setTabData((prevTabData) => [...prevTabData, {
                title: newItem.name,
                id: newItem.id,
                order: order + 1
            }]);
            setOrder(order + 1);
        }
    }

    const editData = () => {
        if (Array.isArray(data) && data.length > 0) {
            setTabData(prevState => {
                let newState = prevState?.map(obj => {
                    let getData = data.find((f: any) => f.id === obj.id);
                    if (getData.name !== obj.title)
                        return {
                            id: obj.id,
                            title: data.find((f: any) => f.id === obj.id).name
                        };
                    else
                        return obj;
                });
                return newState;
            });
        }
    }

    const setData = () => {
        if (Array.isArray(data) && data.length > 0 && currentData) {
            let dataList = [];
            let currentOrder = order;
            const sortedItems = data
                .filter((item) => item.id !== currentData.id)
                .sort((a, b) => a.id.localeCompare(b.id));

            const selectedItem = data.find((item) => item.id === currentData.id);
            if (selectedItem) {
                dataList.push({
                    title: selectedItem.name,
                    id: selectedItem.id,
                    order: currentOrder
                });
            }

            for (let info of sortedItems) {
                currentOrder += 1;
                dataList.push({
                    title: info.name,
                    id: info.id,
                    order: currentOrder
                });
            }
            setOrder(currentOrder);
            setTabData(dataList.sort((a, b) => a.order - b.order));
        }
    }

    const titleClick = (event: any) => {
        onTabChanged(event.itemData.id);
    }

    const getSelectedItem = () => {
        return currentData ? tabData.find((s: any) => s.id === currentData.id) : '';
    }

    return (
        <TabPanel
            width="100%"
            animationEnabled={true}
            swipeEnabled={true}
            showNavButtons={true}
            dataSource={tabData}
            tabsPosition='top'
            stylingMode='secondary'
            iconPosition='start'
            onTitleClick={titleClick}
            noDataText=''
            disabled={!tabLoaded}
            selectedItem={getSelectedItem()}
        />
    );
};

export default TabPanelComponent;
