import './editor.css';

import React, { useEffect, useMemo, useRef, useState } from "react";
import Modal from "../base/modal/modal";
import useModalHook from "../../hooks/modal.hook";
import { PropTypes } from 'data/modal/modal.proprieties';
import DataGrid, {
    Column,
    Editing,
    Paging,
    Lookup,
    Toolbar,
    Item,
    DataGridRef
} from 'devextreme-react/data-grid';
import { logicalConditions, operatorCondition, IConditionRules, fieldConditions } from "./editor.interfaces";
import { CellClickEvent, DataChange } from "devextreme/ui/data_grid";
import { Utils } from "../../utils/utils";
import { EditorsTranslates } from "../../translator/components/editors.translate";

const { EditConditions, AndOr, Field, Condition, Value, Add } = EditorsTranslates();

export default function ToolByConditionEditRules({ show, data, onClose, onButtonOk }: PropTypes) {

    const [dataSource, setDataSource] = useState<IConditionRules[]>(data || []);
    const [changes, setChanges] = useState<DataChange[]>([]);
    const { isOpen, toggle, open } = useModalHook();
    const dataGridRef = useRef<DataGridRef>(null);

    useEffect(() => {
        if (show) {
            setDataSource(data || []);
            open();
        }
    }, [data, show])

    const onCellClicked = (e: CellClickEvent<any, string>) => {
        if (e.rowType === "data")
            if (e.column.dataField === "logical" && e.rowIndex === 0)
                e.event?.stopImmediatePropagation();

    }

    const hideDeleteButtonOnFirstRow = (e: any) => {
        if (e.rowType === "data")
            if (e.column.type === "buttons" && e.rowIndex === 0) {
                e.cellElement.children[0].className = '';
                e.cellElement.childNodes[0].className = '';
            }
    }

    const saveData = () => {
        let newRow: any = null;
        let mergedData = dataSource;

        if (changes.some(t => t.type === "insert")) {
            newRow = changes[0].data;
            newRow.id = Utils.generateGUID();
        }
        else if (changes.some(t => t.type === "update")) {
            mergedData = mergedData.map(item =>
                item.id === changes[0].key ? {
                    ...item, ...changes[0].data
                } : item
            )
        }

        if (newRow)
            mergedData.push(newRow);

        let current = undefined;
        for (let i = mergedData.length - 1; i >= 0; i--) {
            mergedData[i].innerCondition = current;
            current = mergedData[i];
        }

        onButtonOk(mergedData[0]);
    };

    const getEnableButton = (e: any) => {
        e.data = {
            id: Utils.generateGUID(),
            logical: logicalConditions[0].Name,
            field: fieldConditions[0].Name,
            operator: operatorCondition[0].Name,
            value: 0
        }
    }

    const addRowButtonOptions = useMemo(() => {
        return {
            text: Add,
            onClick() {
                if (dataGridRef.current)
                    dataGridRef.current.instance().addRow();
            }
        };
    }, []);

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} close={onClose}
                width="45%" height="55%" onButtonOk={saveData}
                modalHeader={EditConditions}>

                <DataGrid
                    ref={dataGridRef}
                    onCellPrepared={hideDeleteButtonOnFirstRow}
                    onCellClick={onCellClicked}
                    dataSource={dataSource}
                    keyExpr="id"
                    showBorders={true}
                    columnAutoWidth={true}
                    onInitNewRow={getEnableButton}>
                    <Paging enabled={false} />
                    <Editing
                        mode="cell"
                        allowUpdating={true}
                        allowAdding={true}
                        allowDeleting={true}
                        confirmDelete={false}
                        selectTextOnEditStart={true}
                        changes={changes}
                        onChangesChange={setChanges}
                        startEditAction={'click'}
                        newRowPosition="last" />

                    <Column dataField="logical" caption={AndOr} alignment="center" >
                        <Lookup dataSource={logicalConditions} valueExpr="Value" displayExpr="Name" />
                    </Column>

                    <Column dataField="field" caption={Field} alignment="center">
                        <Lookup dataSource={fieldConditions} valueExpr="Value" displayExpr="Name" />
                    </Column>

                    <Column dataField="operator" caption={Condition} alignment="center">
                        <Lookup dataSource={operatorCondition} valueExpr="Value" displayExpr="Name" />
                    </Column>

                    <Column dataField="value" caption={Value} alignment="center" />

                    <Toolbar>
                        <Item name="addRowButton" showText="always" options={addRowButtonOptions} />
                    </Toolbar>
                </DataGrid>

            </Modal>
        </React.Fragment>
    );
}