import { addItemToDictionary, addToolIDsByType, addValueToDictionary } from "../../components/properties/util/property.editor.util";
import { ISettings, IToolSettings } from "../../data/settings/settingsData";
import { IMill } from "../../data/tools/toolData";
import { IHandler } from "../handlerManager";

export class SelectedMachiningToolsByProcessesHandler implements IHandler {
    canHandle(bind: string, editorName: string): boolean {
        return bind === "SettingsDTO.ToolSettings.ToolIDByProcessID" && editorName === "SelectMachiningToolsByProcesses";
    }

    handleTo(ogObject: object, object: object, value: any, bind: string) {
        const toolSettings = object as IToolSettings;
        if (toolSettings !== undefined) {
        }

        return value;
    }

    handleBack(ogObject: object, object: object, value: any, bind: string) {
        const toolSettings = object as IToolSettings;
        const settings = ogObject as ISettings;
    
        if (settings && toolSettings) {
            (value as any[]).forEach(element => {
                const processId = element.processOutput?.processId;
    
                if (processId) {
                    if (!settings.toolSettings?.toolIDByProcessID) {
                        settings.toolSettings!.toolIDByProcessID = {};
                    }
    
                    if (!Array.isArray(settings.toolSettings?.toolIDByProcessID[processId])) {
                        settings.toolSettings!.toolIDByProcessID[processId] = [];
                    }
    
                    const toolIds = settings.toolSettings?.toolIDByProcessID[processId] as string[];
    
                    element.tools.forEach((tool: { id: string }) => {
                        if (!toolIds.includes(tool.id)) {
                            toolIds.push(tool.id);
                        }
                    });
    
                    const selectedToolOrProcessIDByOperationType = addValueToDictionary(
                        settings.toolSettings?.selectedToolOrProcessIDByOperationType!,
                        "ParametricMachiningDTO",
                        processId
                    );
    
                    settings.toolSettings = {
                        ...settings.toolSettings,
                        selectedToolOrProcessIDByOperationType,
                        toolIDByProcessID: settings.toolSettings?.toolIDByProcessID
                    };
                }
            });
        }
    
        return value;
    }
}