import React, { useEffect, useState } from "react";
import Modal from "../base/modal/modal";
import useModalHook from "../../hooks/modal.hook";
import { PropTypes } from 'data/modal/modal.proprieties';
import { FacesType, ZeroPointType } from "../../data/solidFaces/faces";
import { EditorsTranslates } from "../../translator/components/editors.translate";
import './css/selectSolidFace.scss';

const { ZeroPointByFaceTitle } = EditorsTranslates();

interface ZeroPointByFaceValues {
    Face: FacesType,
    ZeroPoint: ZeroPointType
}

interface MouseOverFaceEvent {
    Face: FacesType,
    IsOverFace: boolean
}

export default function ZeroPointByFace({ show, data, onClose, onButtonOk }: PropTypes) {

    const [zeroPointByFaceValues, setZeroPointByFaceValues] = useState<ZeroPointByFaceValues[]>([]);
    const [mouseOverFaceEvent, setMouseOverFaceEvent] = useState<MouseOverFaceEvent[]>([]);
    const { isOpen, toggle, open } = useModalHook();

    useEffect(() => {
        if (show) {
            initializeZeroPoints();
            initializeMouseOverFaceEvent();
            open();
        }
    }, [show])

    const initializeZeroPoints = () => {
        const defaultF1: ZeroPointByFaceValues = { Face: FacesType.f1, ZeroPoint: ZeroPointType.leftBottom };
        const defaultF2: ZeroPointByFaceValues = { Face: FacesType.f2, ZeroPoint: ZeroPointType.leftBottom };
        const defaultF3: ZeroPointByFaceValues = { Face: FacesType.f3, ZeroPoint: ZeroPointType.leftBottom };
        const defaultF4: ZeroPointByFaceValues = { Face: FacesType.f4, ZeroPoint: ZeroPointType.rightBottom };
        const defaultF5: ZeroPointByFaceValues = { Face: FacesType.f5, ZeroPoint: ZeroPointType.leftTop };
        const defaultF6: ZeroPointByFaceValues = { Face: FacesType.f6, ZeroPoint: ZeroPointType.rightBottom };

        const values: ZeroPointByFaceValues[] = [defaultF1, defaultF2, defaultF3, defaultF4, defaultF5, defaultF6];
        setZeroPointByFaceValues(values);
    }

    const initializeMouseOverFaceEvent = () => {
        const defaultF1: MouseOverFaceEvent = { Face: FacesType.f1, IsOverFace: false };
        const defaultF2: MouseOverFaceEvent = { Face: FacesType.f2, IsOverFace: false };
        const defaultF3: MouseOverFaceEvent = { Face: FacesType.f3, IsOverFace: false };
        const defaultF4: MouseOverFaceEvent = { Face: FacesType.f4, IsOverFace: false };
        const defaultF5: MouseOverFaceEvent = { Face: FacesType.f5, IsOverFace: false };
        const defaultF6: MouseOverFaceEvent = { Face: FacesType.f6, IsOverFace: false };

        const values: MouseOverFaceEvent[] = [defaultF1, defaultF2, defaultF3, defaultF4, defaultF5, defaultF6];
        setMouseOverFaceEvent(values);
    }

    const handleCancelEdit = () => {
        onClose();
    }

    const setZeroPointSelected = async (face: FacesType, zeroPoint: ZeroPointType) => {
        setZeroPointByFaceValues(prevState => {
            let newState = prevState?.map(obj => {
                if (obj.Face === face) {
                    return {
                        ...obj,
                        ZeroPoint: zeroPoint
                    };
                }
                return obj;
            });
            return newState;
        });
    }

    const setMouseOverFace = async (face: FacesType) => {
        setMouseOverFaceEvent(prevState => {
            let newState = prevState?.map(obj => {
                if (obj.Face === face) {
                    return {
                        ...obj,
                        IsOverFace: !obj.IsOverFace
                    };
                }
                return obj;
            });
            return newState;
        });
    }

    const isZeroPointSelected = (face: FacesType, zeroPoint: ZeroPointType) => {
        return zeroPointByFaceValues.find(f => f.Face === face)?.ZeroPoint === zeroPoint;
    }

    const isMouseOverFace = (face: FacesType) => {
        return mouseOverFaceEvent.find(f => f.Face === face)?.IsOverFace;
    }

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} close={handleCancelEdit} width="25%" height="400px" onButtonOk={onButtonOk} modalDataState={zeroPointByFaceValues}>

                <div>
                    <span>{ZeroPointByFaceTitle}</span>
                </div>

                <div className="faces-area">

                    <table width={'100%'}>
                        <tbody>
                            <tr style={{ textAlign: 'center', height: '3em' }}>
                                <td></td>
                                <td className='zero-point-area' style={{ width: '15em' }}
                                    onMouseEnter={() => { setMouseOverFace(FacesType.f5) }}
                                    onMouseLeave={() => { setMouseOverFace(FacesType.f5) }}>
                                    <div style={{ verticalAlign: '-webkit-baseline-middle' }}>
                                        <span >F5</span>
                                    </div>

                                    <div style={{ position: 'relative', left: '-8px', top: '-35px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f5, ZeroPointType.leftTop) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f5) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f5, ZeroPointType.leftTop)}>
                                        </span>
                                    </div>
                                    <div style={{ position: 'relative', left: '97%', top: '-35px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f5, ZeroPointType.rightTop) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f5) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f5, ZeroPointType.rightTop)}>
                                        </span>
                                    </div>
                                    <div style={{ position: 'relative', left: '-8px', top: '4px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f5, ZeroPointType.leftBottom) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f5) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f5, ZeroPointType.leftBottom)}>
                                        </span>
                                    </div>
                                    <div style={{ position: 'relative', left: '97%', top: '4px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f5, ZeroPointType.rightBottom) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f5) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f5, ZeroPointType.rightBottom)}>
                                        </span>
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr style={{ textAlign: 'center', height: '6em' }}>
                                <td
                                    className='zero-point-area' style={{ width: '4em' }}
                                    onMouseEnter={() => { setMouseOverFace(FacesType.f2) }}
                                    onMouseLeave={() => { setMouseOverFace(FacesType.f2) }}>
                                    <span style={{ verticalAlign: '-webkit-baseline-middle' }}>F2</span>

                                    <div style={{ position: 'relative', left: '-8px', top: '-58px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f2, ZeroPointType.leftTop) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f2) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f2, ZeroPointType.leftTop)}>
                                        </span>
                                    </div>
                                    <div style={{ position: 'relative', left: '90%', top: '-58px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f2, ZeroPointType.rightTop) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f2) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f2, ZeroPointType.rightTop)}>
                                        </span>
                                    </div>
                                    <div style={{ position: 'relative', left: '-8px', top: '23px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f2, ZeroPointType.leftBottom) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f2) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f2, ZeroPointType.leftBottom)}>
                                        </span>
                                    </div>
                                    <div style={{ position: 'relative', left: '90%', top: '23px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f2, ZeroPointType.rightBottom) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f2) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f2, ZeroPointType.rightBottom)}>
                                        </span>
                                    </div>
                                </td>
                                <td
                                    className='zero-point-area' style={{ width: '15em' }}
                                    onMouseEnter={() => { setMouseOverFace(FacesType.f1) }}
                                    onMouseLeave={() => { setMouseOverFace(FacesType.f1) }}>
                                    <span style={{ verticalAlign: '-webkit-baseline-middle' }}>F1</span>

                                    <div style={{ position: 'relative', left: '-8px', top: '-58px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f1, ZeroPointType.leftTop) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f1) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f1, ZeroPointType.leftTop)}>
                                        </span>
                                    </div>
                                    <div style={{ position: 'relative', left: '97%', top: '-58px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f1, ZeroPointType.rightTop) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f1) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f1, ZeroPointType.rightTop)}>
                                        </span>
                                    </div>
                                    <div style={{ position: 'relative', left: '-8px', top: '23px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f1, ZeroPointType.leftBottom) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f1) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f1, ZeroPointType.leftBottom)}>
                                        </span>
                                    </div>
                                    <div style={{ position: 'relative', left: '97%', top: '23px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f1, ZeroPointType.rightBottom) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f1) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f1, ZeroPointType.rightBottom)}>
                                        </span>
                                    </div>
                                </td>
                                <td
                                    className='zero-point-area' style={{ width: '4em' }}
                                    onMouseEnter={() => { setMouseOverFace(FacesType.f3) }}
                                    onMouseLeave={() => { setMouseOverFace(FacesType.f3) }}>
                                    <span style={{ verticalAlign: '-webkit-baseline-middle' }}>F3</span>

                                    <div style={{ position: 'relative', left: '-8px', top: '-58px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f3, ZeroPointType.leftTop) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f3) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f3, ZeroPointType.leftTop)}>
                                        </span>
                                    </div>
                                    <div style={{ position: 'relative', left: '90%', top: '-58px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f3, ZeroPointType.rightTop) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f3) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f3, ZeroPointType.rightTop)}>
                                        </span>
                                    </div>
                                    <div style={{ position: 'relative', left: '-8px', top: '23px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f3, ZeroPointType.leftBottom) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f3) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f3, ZeroPointType.leftBottom)}>
                                        </span>
                                    </div>
                                    <div style={{ position: 'relative', left: '90%', top: '23px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f3, ZeroPointType.rightBottom) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f3) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f3, ZeroPointType.rightBottom)}>
                                        </span>
                                    </div>
                                </td>
                                <td
                                    className='zero-point-area' style={{ width: '15em' }}
                                    onMouseEnter={() => { setMouseOverFace(FacesType.f6) }}
                                    onMouseLeave={() => { setMouseOverFace(FacesType.f6) }}>
                                    <span style={{ verticalAlign: '-webkit-baseline-middle' }}>F6</span>

                                    <div style={{ position: 'relative', left: '-8px', top: '-58px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f6, ZeroPointType.leftTop) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f6) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f6, ZeroPointType.leftTop)}>
                                        </span>
                                    </div>
                                    <div style={{ position: 'relative', left: '97%', top: '-58px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f6, ZeroPointType.rightTop) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f6) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f6, ZeroPointType.rightTop)}>
                                        </span>
                                    </div>
                                    <div style={{ position: 'relative', left: '-8px', top: '23px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f6, ZeroPointType.leftBottom) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f6) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f6, ZeroPointType.leftBottom)}>
                                        </span>
                                    </div>
                                    <div style={{ position: 'relative', left: '97%', top: '23px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f6, ZeroPointType.rightBottom) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f6) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f6, ZeroPointType.rightBottom)}>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr style={{ textAlign: 'center', height: '3em' }}>
                                <td></td>
                                <td
                                    className='zero-point-area' style={{ width: '12em' }}
                                    onMouseEnter={() => { setMouseOverFace(FacesType.f4) }}
                                    onMouseLeave={() => { setMouseOverFace(FacesType.f4) }}>
                                    <span style={{ verticalAlign: '-webkit-baseline-middle' }}>F4</span>

                                    <div style={{ position: 'relative', left: '-8px', top: '-35px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f4, ZeroPointType.leftTop) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f4) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f4, ZeroPointType.leftTop)}>
                                        </span>
                                    </div>
                                    <div style={{ position: 'relative', left: '97%', top: '-35px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f4, ZeroPointType.rightTop) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f4) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f4, ZeroPointType.rightTop)}>
                                        </span>
                                    </div>
                                    <div style={{ position: 'relative', left: '-8px', top: '4px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f4, ZeroPointType.leftBottom) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f4) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f4, ZeroPointType.leftBottom)}>
                                        </span>
                                    </div>
                                    <div style={{ position: 'relative', left: '97%', top: '4px' }}>
                                        <span
                                            className={isZeroPointSelected(FacesType.f4, ZeroPointType.rightBottom) ? 'zero-point-circle-selected' : isMouseOverFace(FacesType.f4) ? 'zero-point-circle' : 'none'}
                                            onClick={() => setZeroPointSelected(FacesType.f4, ZeroPointType.rightBottom)}>
                                        </span>
                                    </div>
                                </td>

                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                </div>

            </Modal>
        </React.Fragment >
    );
}
