import './page.header.component.scss'

import React from 'react';
import PageSearchComponent from '../search/page.search.component';
import { eHeaderButtonVisibility } from '../../header/header-button/header.button.visibility.types';
import { ClickEvent } from 'devextreme/ui/button';
import Header from 'components/header/Header';

interface PageHeaderProps {
    title: string;
    headerButtonsVisibility: eHeaderButtonVisibility[]
    headerCustomComponents?: any;
    buttonHandlers?: [eHeaderButtonVisibility, (e: ClickEvent) => void][];
    pageData?: any[]
}

const PageHeaderComponent: React.FC<PageHeaderProps> = ({ title, headerButtonsVisibility, headerCustomComponents, buttonHandlers, pageData }) => {
    return (
        <React.Fragment>
            <div className='header-layout'>
                <div className={'header-text'}>
                    {title}
                </div>

                <PageSearchComponent />
            </div>
            <div className={headerCustomComponents !== undefined ? 'header-buttons-layout' : 'header-buttons-layout border-botom-layout'}>
                <Header buttonHandlers={buttonHandlers} headerButtonsVisibility={headerButtonsVisibility} pageData={pageData} />
            </div>
            <div className={headerCustomComponents !== undefined ? 'border-botom-layout-component' : ''}>
                {headerCustomComponents}
            </div>
        </React.Fragment>
    );
};

export default PageHeaderComponent;