import React, { useEffect, useRef, useState } from 'react';
import { IArea } from '../../data/workingArea/workingArea.data';
import { Field } from '../../components/properties/property.data';
import { useAppDispatch } from '../../store/store';
import SettingsHelper from '../../helpers/settingsHelper';
import { useLocation } from 'react-router-dom';
import { ISettings } from '../../data/settings/settingsData';
import { editSettings } from '../../store/settings.store';
import { INavigation } from '../../data/navigation/navigationType';

interface WorkingAreaEditorProps {
    areas: IArea[],
    fields: Field[],
    onSelectArea: (idArea: string) => void;
    onEditorAssign?: () => void;
    onUpdateSettings?: () => ISettings;
    onInitializeEditorSize: (totalWorkingAreaSize: TotalWorkingAreaSize) => void;
}

export interface TotalWorkingAreaSize {
    screenWidth: number;
    screenHeight: number;
    width: number;
    height: number;
}

export const WorkingAreaEditor: React.FC<WorkingAreaEditorProps> = ({
    areas,
    fields,
    onEditorAssign,
    onUpdateSettings,
    onSelectArea,
    onInitializeEditorSize
}) => {
    const [totalWorkingAreaSize, setTotalWorkingAreaSize] = useState<TotalWorkingAreaSize>({
        screenWidth: 0,
        screenHeight: 0,
        width: 0,
        height: 0
    });
    const elementRef = useRef<HTMLDivElement>(null);
    const settingsHelper = new SettingsHelper();
    const dispatch = useAppDispatch();
    const { state } = useLocation();
    const settings = useRef<ISettings>();

    useEffect(() => {
        assignUserSettings();

        if (onEditorAssign) {
            onEditorAssign();
        }

        const totalWidth = settings.current?.machineSettings.machineLength;
        const totalHeight = settings.current?.machineSettings.machineWidth;
        if (totalWidth !== undefined && totalHeight !== undefined) {
            setTotalWorkingAreaSize(prevSize => ({
                ...prevSize,
                width: totalWidth,
                height: totalHeight,
            }));
        }
    }, [fields, onEditorAssign, onUpdateSettings, dispatch, state.pageNavigation]);

    useEffect(() => {
        function updateSize() {
            if (elementRef.current) {
                const newResize = {
                    screenWidth: elementRef.current.offsetWidth - 3,
                    screenHeight: elementRef.current.offsetHeight - 3,
                    width: settings.current?.machineSettings.machineLength!,
                    height: settings.current?.machineSettings.machineWidth!,
                };
                setTotalWorkingAreaSize(newResize);
                onInitializeEditorSize(newResize);
            }
        }

        if (elementRef.current) {
            const resizeObserver = new ResizeObserver(updateSize);
            resizeObserver.observe(elementRef.current);
            window.addEventListener('resize', updateSize);

            updateSize();

            return () => {
                resizeObserver.disconnect();
                window.removeEventListener('resize', updateSize);
            };
        }
    }, [fields, onInitializeEditorSize]);

    const getFirstArea = () => {
        return areas.sort(s => s.order)[0];
    }

    const getOtherAreas = () => {
        return areas.filter(f => f.id !== getFirstArea()?.id);
    }

    const checkAreaOpacity = (id: string) => {
        if (!id) return '1';

        let selected = areas.find(f => f.id === id)?.selected;
        if (selected) return '1';

        let anyOtherSelected = areas.some(f => f.id !== id && f.selected === true);
        if (anyOtherSelected) return '0.2';

        return '1';
    }

    const getAreaStyle = (width: number, height: number, offsetX: number, offsetY: number, id: string): React.CSSProperties => {
        return {
            left: `${offsetX === undefined ? 0 : offsetX}px`,
            top: `${offsetY === undefined ? 0 : offsetY}px`,
            width: `${width === undefined ? 0 : width}px`,
            height: `${height === undefined ? 0 : height}px`,
            opacity: id === undefined ? '1' : checkAreaOpacity(id),
        };
    }

    function assignUserSettings() {
        if (onUpdateSettings) {
            settings.current = onUpdateSettings();
        }
        const storedSettings = settingsHelper.getStoredSettings(
            settings.current!,
            state.pageNavigation as INavigation
        );
        settings.current = storedSettings[1]!;
        dispatch(editSettings(storedSettings[0]!));
    }

    return (
        <React.Fragment>
            <div className='main-area-center' ref={elementRef}>
                <div className='main-area-config'>
                    <div key={getFirstArea()?.id}
                        className={getFirstArea()?.selected ? 'working-area-ui-selected selected-area-color' : 'working-area-ui default-area-color'}
                        style={getAreaStyle(getFirstArea()?.screenWidth, getFirstArea()?.screenHeight, getFirstArea()?.offsetX, getFirstArea()?.offsetY, getFirstArea()?.id)}
                        onClick={() => onSelectArea(getFirstArea()?.id)}>
                        <span className={getFirstArea()?.selected ? 'area-identifier-text-selected' : 'area-identifier-text'}>
                            {getFirstArea()?.identifier}
                            <br />
                            <span style={{ fontSize: '10px' }}>{getFirstArea()?.width} x {getFirstArea()?.height}</span>
                        </span>
                    </div>

                    {getOtherAreas().map(otherAreas => (
                        <div key={otherAreas.id}
                            className={otherAreas.selected ? 'working-area-ui-selected selected-area-color' : 'working-area-ui default-area-color'}
                            style={getAreaStyle(otherAreas.screenWidth, otherAreas.screenHeight, otherAreas.offsetX, otherAreas.offsetY, otherAreas.id)}
                            onClick={() => onSelectArea(otherAreas.id)}>
                            <span className={otherAreas.selected ? 'area-identifier-text-selected' : 'area-identifier-text'}>
                                {otherAreas.identifier}
                                <br />
                                <span style={{ fontSize: '10px' }}>{otherAreas.width} x {otherAreas.height}</span>
                            </span>
                        </div>
                    ))}
                </div>
                <div>
                    <span style={{ display: 'block', textAlign: 'center', width: '100%' }}>{totalWorkingAreaSize.width}</span>
                </div>
            </div>

            <span>{totalWorkingAreaSize.height}</span>
        </React.Fragment>
    );
};

export default WorkingAreaEditor;