export class Value {
    value: number;
    equation?: string

    constructor(value: number = 0) {
        this.value = value;
    }
}

export class Vector2 {
    x: Value;
    y: Value;
    radianAngle?: number

    constructor(x: number = 0, y: number = 0) {
        this.x = new Value(x);
        this.y = new Value(y);
    }

    static sameValues(firstVector: Vector2, secondVector: Vector2): boolean {
        return firstVector.x.value === secondVector.x.value && firstVector.y.value === secondVector.y.value;
    }
}

export class Vector3 {
    x: Value;
    y: Value;
    z: Value;
    radianAngle?: number

    constructor(x: number = 0, y: number = 0, z: number = 0) {
        this.x = new Value(x);
        this.y = new Value(y);
        this.z = new Value(z);
    }

    static sameValues(firstVector: Vector3, secondVector: Vector3): boolean {
        if (firstVector.x.value === secondVector.x.value && firstVector.y.value === secondVector.y.value) {
            return firstVector.z.value === secondVector.z.value;
        }

        return false;
    }

    static plus(firstVector: Vector3, secondVector: Vector3): Vector3 {
        return new Vector3(
            firstVector.x.value + secondVector.x.value,
            firstVector.y.value + secondVector.y.value,
            firstVector.z.value + secondVector.z.value
        );
    }
}