import { addToolIDsByType } from "../../components/properties/util/property.editor.util";
import { ISettings, IToolSettings } from "../../data/settings/settingsData";
import { IMill } from "../../data/tools/toolData";
import { IHandler } from "../handlerManager";

export class LabelSettingsSelectedHandler implements IHandler {
    canHandle(bind: string, editorName: string): boolean {
        return bind === "SettingsDTO.LabelSettingsId";
    }

    handleTo(ogObject: object, object: object, value: any, bind: string) {
        if (value !== undefined) {
            return value;
        }
        return '';
    }

    handleBack(ogObject: object, object: object, value: any, bind: string) {
        const settings = object as ISettings;
        if (settings !== undefined) {
            settings.labelSettingsId = value as string;
        }

        return value;
    }
}