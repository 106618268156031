import './nesting.scss';

import React, { useEffect, useState } from 'react';
import DataGrid, { Column, Selection, Scrolling, Pager, Sorting } from 'devextreme-react/data-grid';
import ButtonGroup from '../../components/nesting/nesting.buttonGroup';
import NestingRow from '../../components/nesting/nesting.nestingRow';
import ColumnEditor from '../../components/nesting/nesting.columnEditor';
import ToolBar from '../../components/nesting/nesting.toolbar';
import { Field, NewProperty } from '../../components/properties/property.data';
import { useLocation } from 'react-router-dom';
import { INavigation } from '../../data/navigation/navigationType';
import PageHeaderComponent from '../../components/pages/header/page.header.component';
import SettingsHelper from '../../helpers/settingsHelper';
import { editSettings } from '../../store/settings.store';
import { store, useAppDispatch } from '../../store/store';
import BindManager from '../../helpers/bindHelper';
import { IExporterColumn, ISettings, ISettingsInput } from '../../data/settings/settingsData';
import { eHeaderButtonVisibility } from '../../components/header/header-button/header.button.visibility.types';
import { NestingTranslates } from 'translator/pages/nesting.translate';
import { useTranslator } from 'contexts/translator.context';
import { Utils } from 'utils/utils';

const { Title } = NestingTranslates();

export const nestingSection = "NestingFile";

const headerButtonsVisibility: eHeaderButtonVisibility[] = []

function Nesting() {
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const [selectedFirstRowsData, setSelectedFirstRowsData] = useState<Field[]>([]);
  const [selectedSecondRowsData, setSelectedSecondRowsData] = useState<Field[]>([]);
  const [settingColumns, setSettingColumns] = useState<Field[]>([]);
  const [defaultColumns, setDefaultColumns] = useState<Field[]>([]);
  const [showColumnEditor, setShowColumnEditor] = useState(false);
  const [fields, setFields] = useState<Field[]>([]);
  const settingsHelper = new SettingsHelper();
  const translatorHelper = useTranslator();
  const bindManager = new BindManager();

  useEffect(() => {
    setFields([])
    setSelectedFirstRowsData([])
    setSelectedSecondRowsData([])
    setSettingColumns([])
    setDefaultColumns([])
    setShowColumnEditor(false)

    const navigation = state.pageNavigation as INavigation;

    if (navigation != null && navigation.sectionTemplate?.pageSection == nestingSection) {
      const formFields = JSON.parse(JSON.stringify(navigation.sectionTemplate));
      const nesting = translatorHelper.getTranslatedFields(formFields.properties);

      setFields(nesting.filter((item) => item.type !== "Column"));
      setDefaultColumns(nesting.filter((item) => item.type === "Column").map((item) => ({ ...item })));

      const storedSettings = settingsHelper.getStoredSettings(undefined!, navigation);
      assignDefaultSettingsColumns(nesting.filter((item) => item.type === "Column"), storedSettings[1]!);
    }
  }, [state.pageNavigation]);

  const assignDefaultSettingsColumns = (fields: Field[], settings: ISettings) => {
    let settingsColumns: Field[] = [];

    settings?.exporterSettings?.columnsSettings?.forEach(C => {
      let groupedColumnsFields: Field[] = [];

      C.groupedColumns?.forEach(G => {
        let groupedNestingField = fields.find(F => F.defaultName === G.header);

        if (groupedNestingField !== undefined) {
          let prop = NewProperty();
          prop.value = G.name;
          prop.visibleValue = G.description;
          prop.format = G.format;
          prop.id = G.id;
          prop.separator = G.separator;
          prop.prefix = G.prefix;
          prop.suffix = G.suffix;
          prop.defaultName = G.header;

          groupedColumnsFields.push(prop);
        }
      })

      let prop = NewProperty();
      prop.value = C.name;
      prop.visibleValue = C.description;
      prop.groupedProperties = groupedColumnsFields;
      prop.format = C.format;
      prop.id = C.id;
      prop.separator = C.separator;
      prop.prefix = C.prefix;
      prop.suffix = C.suffix;
      prop.defaultName = C.header;

      settingsColumns.push(prop);
    })

    onUpdateColumns(settingsColumns);
    setSettingColumns(settingsColumns.map((item) => ({ ...item })));
  };

  const handleValueChanged = (fieldName: string, fieldValue: any) => {
    const navigation = state.pageNavigation as INavigation;

    setFields((prevFields) =>
      prevFields.map((obj) => {
        if (obj.id === fieldName) {
          let storedSettings = settingsHelper.getStoredSettings(undefined!, navigation);
          const settings = JSON.parse(JSON.stringify(storedSettings[1]!)) as ISettings;
          bindManager.setValue(fieldValue, obj, settings);

          storedSettings = settingsHelper.getStoredSettings(settings!, navigation);

          dispatch(editSettings(storedSettings[0]));

          propagateBindChanges(fieldValue, obj, storedSettings[1]!);

          return { ...obj, value: fieldValue }
        }
        else
          return obj;
      }));
  };

  const onClickOk = (newProperty: Field) => {
    let index = settingColumns.findIndex((item) => item.id === newProperty.id);
    let updatedSettingColumns = [...settingColumns];
    updatedSettingColumns[index] = newProperty;

    onUpdateColumns(updatedSettingColumns);
    setSettingColumns(updatedSettingColumns);
    setSelectedSecondRowsData([newProperty]);
    setShowColumnEditor(false);
  };

  const onClickClose = () => {
    setShowColumnEditor(false);
  };

  const onClickMoveRight = () => {
    if (!buttonMoveRightState()) {
      let selectedclone = selectedFirstRowsData.map((item) => ({ ...item }));
      let itemsWithId = settingColumns.filter((item) => item.id.includes(selectedclone[0].id));

      if (itemsWithId.length !== 0) {
        selectedclone[0].id = Utils.generateGUID();
      }

      if (selectedFirstRowsData.length > 0) {
        let updatedSettingColumns = [...settingColumns];
        updatedSettingColumns.push(...selectedclone)

        onUpdateColumns(updatedSettingColumns);

        setSettingColumns((prevSettingColumns) => [...prevSettingColumns, ...selectedclone]);
      }
    }
  };

  const onClickRemove = () => {
    if (!buttonRemoveState()) {
      let updatedSettingsColumns = settingColumns.filter((columnsItem) => !selectedSecondRowsData.some((selectedItem) => selectedItem.id === columnsItem.id));

      onUpdateColumns(updatedSettingsColumns);
      setSettingColumns(updatedSettingsColumns);
    }
  };

  const onClickMoveUp = () => {
    if (!buttonMoveUpState()) {
      let index = settingColumns.findIndex((item) => item === selectedSecondRowsData[0]);
      let updatedSettingColumns = [...settingColumns];
      let item = updatedSettingColumns[index];
      updatedSettingColumns.splice(index, 1);
      updatedSettingColumns.splice(index - 1, 0, item);

      onUpdateColumns(updatedSettingColumns);
      setSettingColumns(updatedSettingColumns);
    }
  };

  const onClickMoveDown = () => {
    if (!buttonMoveDownState()) {
      let index = settingColumns.findIndex((item) => item === selectedSecondRowsData[0]);
      let updatedSettingColumns = [...settingColumns];
      let item = updatedSettingColumns[index];
      updatedSettingColumns.splice(index, 1);
      updatedSettingColumns.splice(index + 1, 0, item);

      onUpdateColumns(updatedSettingColumns);
      setSettingColumns(updatedSettingColumns);
    }
  };

  const onClickPreferences = () => {
    if (!buttonRemoveState()) {
      setShowColumnEditor(true);
    }
  };

  const onClickGroup = () => {
    if (!buttonGroupState()) {
      let property = NewProperty();
      let visibleValue = '';
      let value = '';
      let format = '';
      let header = ''
      let groupedProperties: Field[] = [];

      selectedSecondRowsData.forEach((selectedItem, index) => {
        visibleValue += selectedItem.visibleValue;
        value += selectedItem.value;
        format += selectedItem.format;
        header += selectedItem.defaultName;

        if (selectedItem.groupedProperties === undefined || selectedItem.groupedProperties.length === 0) {
          groupedProperties.push(selectedItem);
        } else {
          groupedProperties.push(...selectedItem.groupedProperties);
        }

        if (index !== selectedSecondRowsData.length - 1) {
          visibleValue += '-';
          value += '-';
          format += '-';
          header += '-'
        }
      });

      property.value = value;
      property.visibleValue = visibleValue;
      property.groupedProperties = groupedProperties;
      property.format = format;
      property.defaultName = header;
      property.id = Utils.generateGUID();
      property.separator = '-';

      let updatedSettingsColumns = settingColumns.filter((columnsItem) => !selectedSecondRowsData.some((selectedItem) => selectedItem.id === columnsItem.id))
      updatedSettingsColumns.push(property);

      onUpdateColumns(updatedSettingsColumns)
      setSettingColumns(updatedSettingsColumns);
    }
  };

  const onClickUngroup = () => {
    if (!buttonUngroupState()) {
      const groupedProperties = selectedSecondRowsData[0].groupedProperties;
      let updatedSettingsColumns = [...settingColumns, ...groupedProperties];

      updatedSettingsColumns = updatedSettingsColumns.filter((item) => item !== selectedSecondRowsData[0]);

      onUpdateColumns(updatedSettingsColumns)
      setSettingColumns(updatedSettingsColumns);
      setSelectedSecondRowsData([]);
    }
  };

  const onClickRefresh = () => {
    setSelectedSecondRowsData([]);
    assignDefaultSettingsColumns(defaultColumns, (state.pageNavigation as INavigation)?.defaultSettings!);
  };

  const onUpdateColumns = (fields: Field[]) => {
    let storedSettings = settingsHelper.getStoredSettings(undefined!, state.pageNavigation as INavigation);

    const sett = JSON.parse(JSON.stringify(storedSettings[1]));
    const columns: IExporterColumn[] = [];

    fields.forEach(C => {
      const groupedColumns: IExporterColumn[] = [];

      C.groupedProperties?.forEach(G => {
        groupedColumns.push({
          id: G.id,
          header: G.defaultName,
          name: G.value,
          description: G.visibleValue,
          format: G.format,
          prefix: G.prefix,
          suffix: G.suffix,
          separator: G.separator
        });
      });

      columns.push({
        id: C.id,
        header: C.defaultName,
        name: C.value,
        description: C.visibleValue,
        format: C.format,
        prefix: C.prefix,
        suffix: C.suffix,
        separator: C.separator,
        groupedColumns: groupedColumns!
      });
    });

    sett.exporterSettings.columnsSettings?.splice(0, sett.exporterSettings.columnsSettings.length);
    sett.exporterSettings.columnsSettings?.push(...columns);

    storedSettings = settingsHelper.getStoredSettings(sett, state.pageNavigation as INavigation);

    dispatch(editSettings(storedSettings[0]!));

    propagateChanges(storedSettings[1]!)
  }

  const buttonMoveRightState = () => {
    return selectedFirstRowsData.length > 0 ? false : true;
  };

  const buttonRemoveState = () => {
    return selectedSecondRowsData.length === 1 ? false : true;
  };

  const buttonMoveUpState = () => {
    let index = settingColumns.findIndex((item) => item === selectedSecondRowsData[0]);
    return selectedSecondRowsData.length === 1 && index > 0 ? false : true;
  };

  const buttonMoveDownState = () => {
    let index = settingColumns.findIndex((item) => item === selectedSecondRowsData[0]);
    return selectedSecondRowsData.length === 1 && index < settingColumns.length - 1 && index > -1 ? false : true;
  };

  const buttonGroupState = () => {
    return selectedSecondRowsData.length > 1 ? false : true;
  };

  const buttonUngroupState = () => {
    return selectedSecondRowsData.length === 1 && selectedSecondRowsData[0].groupedProperties !== undefined && selectedSecondRowsData[0].groupedProperties.length > 1 ? false : true;
  };

  const onFirstColumnSelectionChanged = ({ selectedRowsData }: { selectedRowsData: Field[] }) => {
    setSelectedFirstRowsData(selectedRowsData);
  };

  const onSecondColumnSelectionChanged = ({ selectedRowsData }: { selectedRowsData: Field[] }) => {
    setSelectedSecondRowsData(selectedRowsData);
  };

  function propagateBindChanges(fieldValue: string, obj: Field, sett: ISettings) {
    const storedSettings = store.getState().settingsDataState.settings ?? [];

    storedSettings.forEach(SS => {
      const settingsInput: ISettingsInput = {
        machineInfo: SS.machineInfo,
        settingsToUpdate: [],
        settingsToDelete: SS.settingsToDelete
      }

      if (SS.machineInfo.id === sett.machineInfoId) {
        SS.settingsToUpdate?.forEach(SU => {
          const settings = JSON.parse(JSON.stringify(SU));
          bindManager.setValue(fieldValue, obj, settings);

          settingsInput.settingsToUpdate?.push(settings);
        })
      }
      else {
        settingsInput.settingsToUpdate = SS.settingsToUpdate;
      }

      dispatch(editSettings(settingsInput));
    });
  }


  function propagateChanges(sett: ISettings) {
    const storedSettings = store.getState().settingsDataState.settings ?? [];

    storedSettings.forEach(SS => {
      const settingsInput: ISettingsInput = {
        machineInfo: SS.machineInfo,
        settingsToUpdate: [],
        settingsToDelete: SS.settingsToDelete
      }

      if (SS.machineInfo.id === sett.machineInfoId) {
        SS.settingsToUpdate?.forEach(SU => {
          const settings: ISettings = {
            ...SU,
            exporterSettings: {
              ...SU.exporterSettings,
              columnsSettings: [...sett.exporterSettings?.columnsSettings!]
            }
          };
          settingsInput.settingsToUpdate?.push(settings);
        });
      }

      dispatch(editSettings(settingsInput));
    })
  }

  return (
    <React.Fragment>
      <PageHeaderComponent
        title={Title} headerButtonsVisibility={headerButtonsVisibility} headerCustomComponents={<ToolBar fields={fields} onChange={handleValueChanged} />} />
      <div className='page-body-layout'>

        <div className="tables">
          <div className="left-column">
            <DataGrid
              dataSource={defaultColumns}
              height={800}
              showBorders={false}
              hoverStateEnabled={true}
              showColumnHeaders={false}
              keyExpr="id"
              onSelectionChanged={onFirstColumnSelectionChanged}
              dataRowRender={NestingRow}
            >
              <Scrolling mode="virtual" />
              <Pager visible={false} />
              <Sorting mode="none" />
              <Selection mode="single" />
              <Column dataField="Value" />
            </DataGrid>
          </div>

          <div>
            <ButtonGroup
              onClickMoveRight={onClickMoveRight}
              onClickRemove={onClickRemove}
              onClickMoveUp={onClickMoveUp}
              onClickMoveDown={onClickMoveDown}
              onClickPreferences={onClickPreferences}
              onClickGroup={onClickGroup}
              onClickUngroup={onClickUngroup}
              onClickRefresh={onClickRefresh}
              buttonMoveRightState={buttonMoveRightState}
              buttonRemoveState={buttonRemoveState}
              buttonMoveUpState={buttonMoveUpState}
              buttonMoveDownState={buttonMoveDownState}
              buttonGroupState={buttonGroupState}
              buttonUngroupState={buttonUngroupState}
            />
          </div>

          <div className="right-column">
            <DataGrid
              dataSource={settingColumns}
              showBorders={false}
              height={800}
              showColumnHeaders={false}
              hoverStateEnabled={true}
              keyExpr="id"
              onRowDblClick={onClickPreferences}
              onSelectionChanged={onSecondColumnSelectionChanged}
              dataRowRender={NestingRow}
            >
              <Scrolling mode="virtual" />
              <Pager visible={false} />
              <Sorting mode="none" />
              <Selection mode="multiple" showCheckBoxesMode="none" />
              <Column dataField="Value" />
            </DataGrid>
          </div>
        </div>

        <ColumnEditor show={showColumnEditor} onPropertyNotChange={onClickClose} property={selectedSecondRowsData[0]} onPropertyChange={onClickOk} />
      </div>
    </React.Fragment>
  );
}

export default Nesting;