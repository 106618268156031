import React, { useCallback } from "react";
import { Toast } from 'devextreme-react/toast';
import { useNotification } from "contexts/notification.context";

const NotificationComponent: React.FC = () => {

    const { notificationConfig, setNotificationConfig } = useNotification();

    const onHiding = useCallback(() => {
        setNotificationConfig({
            ...notificationConfig,
            isVisible: false,
        });
    }, [notificationConfig, setNotificationConfig]);

    return (
        <React.Fragment>
            <Toast
                visible={notificationConfig.isVisible}
                message={notificationConfig.message}
                type={notificationConfig.type ? notificationConfig.type : 'info'}
                position={'top center'}
                onHiding={onHiding}
            />
        </React.Fragment>
    );
};

export default NotificationComponent;