import NumberBox from "devextreme-react/number-box";
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import {
    Validator,
    RequiredRule,
    ValidatorRef
} from 'devextreme-react/validator';
import { addValidation, removeValidation } from 'store/validation.store';
import { useAppDispatch } from "store/store";

interface PropertyNumberState {
    numberOption: number;
}

export default function PropertyNumber({ field, onChange, disableForm }: any) {

    const [state, setState] = useState<PropertyNumberState>({
        numberOption: +field.value
    });
    const dispatch = useAppDispatch();
    const validatorRef = useRef<ValidatorRef>(null);

    useEffect(() => {
        setState({ numberOption: +field.value });
        if (field.required)
            checkValidation();
    }, [field]);

    const handleCheckChange = (value: number) => {
        setState({ numberOption: value });
        onChange(field.id, value);
    };

    const checkValidation = () => {
        const validator = validatorRef.current && validatorRef.current.instance();
        if (validator) {
            const validation = validatorRef.current.instance().validate();
            if (!validation.isValid && field.isVisible)
                dispatch(addValidation({ field: field, isValid: false }))
            else
                dispatch(removeValidation(field))
        }
    }

    return (
        <NumberBox className="dx-field-label" disabled={disableForm} step={0}
            visible={field.isVisible !== undefined ? !!field.isVisible : true}
            name={field.id} value={state.numberOption} onValueChange={handleCheckChange} >
            <Validator ref={validatorRef} >
                {field.required && <RequiredRule type='required' message="" />}
            </Validator>
        </NumberBox>
    )
}