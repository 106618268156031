import React, { useEffect, useState } from "react";
import Modal from "../base/modal/modal";
import useModalHook from "../../hooks/modal.hook";
import DataGrid, { Column, ColumnFixing } from 'devextreme-react/data-grid';
import Button from "devextreme-react/button";
import editIcon from '../../assets/images/icons/edit.svg';
import SelectedTools from "./selectedTools";
import { eToolsSelect } from "./editor.enums";
import { IDataByProcess } from "./editor.interfaces";
import { EditorsTranslates } from "../../translator/components/editors.translate";
import { ITool } from "../../data/tools/toolData";
import { store } from "../../store/store";
import './editor.css';
import { PropTypes } from 'data/modal/modal.proprieties';

const { SlotToolsByProcessTitle, Process, Tool } = EditorsTranslates();

export default function SelectedSlotToolsByProcess({ show, data, currentSettings, onClose, onButtonOk }: PropTypes) {
    const [dataSource, setDataSource] = useState<IDataByProcess[]>([]);
    const [selectedProcess, setSelectedProcess] = useState<string | null>(null);
    const [selectedTools, setSelectedTools] = useState<ITool[]>([]);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const { isOpen, toggle, open } = useModalHook();
    const { isOpen: isToolsModalOpen, toggle: toggleToolsModal, open: openToolsModal, close: closeToolsModal } = useModalHook();

    const state = store.getState();
    const getProcesses = state.userDataState.user.processes;

    useEffect(() => {
        if (show) {
            const handleResize = () => {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            };

            window.addEventListener('resize', handleResize);
            open();

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [show, open]);

    useEffect(() => {
        if (getProcesses.length > 0 && currentSettings?.slotSettings?.availableProcesses) {
            populateDataSource();
        }
    }, [currentSettings, getProcesses]);

    const populateDataSource = () => {
        const slotProcesses = getProcesses.filter(process =>
            currentSettings?.slotSettings.availableProcesses.includes(process.processId)
        );

        const newDatas: IDataByProcess[] = slotProcesses.map((process) => {
            const toolIDByProcessID = currentSettings?.toolSettings?.toolIDByProcessID as { [key: string]: string[] };

            let tools: ITool[] = [];
            if (toolIDByProcessID && toolIDByProcessID.hasOwnProperty(process.processId)) {
                const toolIDs = Array.isArray(toolIDByProcessID[process.processId])
                    ? toolIDByProcessID[process.processId]
                    : [];

                const mills = Array.isArray(currentSettings?.toolSettings?.mills)
                    ? currentSettings?.toolSettings?.mills!
                    : [];
                const saws = Array.isArray(currentSettings?.toolSettings?.saws)
                    ? currentSettings?.toolSettings?.saws!
                    : [];
                const allTools = [...mills, ...saws];

                tools = toolIDs
                    .map((toolID: string) => allTools.find((tool: ITool) => tool.id === toolID))
                    .filter((tool: ITool | undefined): tool is ITool => tool !== undefined);
            }

            return {
                processOutput: process,
                tools,
            };
        });

        setDataSource(newDatas);
    };

    const confirmSelectedTools = (updatedTools: ITool[]) => {
        if (selectedProcess) {
            setDataSource(prevState =>
                prevState.map(obj => {
                    if (obj.processOutput.processId === selectedProcess) {
                        return {
                            ...obj,
                            tools: updatedTools,
                        };
                    }
                    return obj;
                })
            );
        }
        closeToolsModal();
    };

    const openToolsEditor = (processId: string) => {
        setSelectedProcess(processId);

        const processData = dataSource.find(obj => obj.processOutput.processId === processId);
        if (processData) {
            setSelectedTools(processData.tools ?? []);
        }

        openToolsModal();
    };

    const getSelectToolOptions = (row: { data: IDataByProcess }) => (
        <Button
            width={40}
            height={40}
            className="button-alignment-right"
            icon={editIcon}
            onClick={() => openToolsEditor(row.data.processOutput.processId)}
        />
    );

    const saveData = () => {
        onButtonOk(dataSource);
        toggle();
    };

    return (
        <React.Fragment>
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                close={onClose}
                width="45%"
                height="55%"
                onButtonOk={saveData}
                modalHeader={SlotToolsByProcessTitle}
            >
                <DataGrid
                    dataSource={dataSource}
                    keyExpr="processOutput.processId"
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    showBorders={true}
                    height={windowSize.width > 1600 ? 400 : windowSize.width > 1180 ? 300 : 200}
                >
                    <ColumnFixing enabled={true} />
                    <Column
                        caption={Process}
                        alignment="center"
                        dataField="processOutput.processName"
                    />
                    <Column
                        caption={Tool}
                        alignment="center"
                        cellRender={({ data }: { data: IDataByProcess }) => (
                            data.tools && data.tools.length > 0 ? (
                                data.tools.map((tool: ITool) => (
                                    <div key={tool.id}>{tool.name}</div>
                                ))
                            ) : (
                                'No tools available'
                            )
                        )}
                    />
                    <Column
                        caption=""
                        width={60}
                        alignment="center"
                        cellRender={getSelectToolOptions}
                    />
                </DataGrid>
            </Modal>

            {isToolsModalOpen && (
                <SelectedTools
                    show={isToolsModalOpen}
                    data={[(eToolsSelect.MILL as eToolsSelect), (eToolsSelect.SAW as eToolsSelect)]}
                    currentSettings={currentSettings}
                    onClose={closeToolsModal}
                    onButtonOk={confirmSelectedTools}
                    selectedTools={selectedTools}
                />
            )}
        </React.Fragment>
    );
}
