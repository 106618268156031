class StringHelper {

    toCamelCase(str: string): string {
        return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match: string, index: number) {
            if (/\s+/.test(match)) return "";
            return index === 0 ? match.toLowerCase() : match.toUpperCase();
        });
    }
}

export default StringHelper;