import './editor.css';

import React, { useEffect, useState } from "react";
import Modal from "../base/modal/modal";
import useModalHook from "../../hooks/modal.hook";
import { store } from "../../store/store"
import { TreeList, Selection, Column, TreeListTypes } from 'devextreme-react/tree-list';
import { ISettings } from "../../data/settings/settingsData";
import { IMill, ITool } from "../../data/tools/toolData";
import { ITreeListTools } from "./editor.interfaces";
import { Utils } from "../../utils/utils";
import { EditorsTranslates } from "../../translator/components/editors.translate";
import { PropTypes } from 'data/modal/modal.proprieties';

const { OrtogonalMachiningToolsTitle, Mills } = EditorsTranslates();

export default function SelectedOrtogonalMachiningTools({ show, data, onClose, onButtonOk }: PropTypes) {

    const [mills, setMills] = useState<IMill[]>([]);
    const [treeListData, setTreeListData] = useState<ITreeListTools[]>([]);
    const [selectedData, setSelectedData] = useState<ITool[]>(data || []);
    const { isOpen, toggle, open } = useModalHook();

    const state = store.getState();
    const getSettings = state.settingsDataState.settings;

    useEffect(() => {
        if (show) {
            let settings = getSettings[0]?.settingsToUpdate as ISettings[];
            if (settings) {
                let toolSettings = settings[0].toolSettings;
                if (toolSettings) {
                    setMills(toolSettings.mills || []);
                }
            }
            populateToolTreeList();
            open();
        }
    }, [data, show, mills])

    function populateToolTreeList() {
        let toolsArray: ITreeListTools[] = [];

        let millHeadTool: ITreeListTools = {
            ID: Utils.generateGUID(),
            headID: '0',
            toolName: Mills,
            isHead: true
        };

        toolsArray.push(millHeadTool);

        for (let m of mills) {
            let newTool: ITreeListTools = {
                ID: Utils.generateGUID(),
                headID: millHeadTool.ID,
                toolName: m.name ?? "",
                toolID: m.id
            }
            toolsArray.push(newTool);
        }

        setTreeListData(toolsArray);
    }

    const onSelectionChanged = (e: TreeListTypes.SelectionChangedEvent) => {
        let childrenToolsID: any[] = [];
        if (e.selectedRowsData.some(s => s.isHead))
            childrenToolsID = treeListData.filter(f => f.headID === e.currentSelectedRowKeys[0]).map((tool) => { return tool.toolID });

        let toolsID = e.selectedRowsData.map((tool) => { return tool.toolID });
        let dataSelectedMills = mills.filter(m => toolsID.includes(m.id) || childrenToolsID.includes(m.id));
        setSelectedData([...dataSelectedMills]);
    }

    const getSelected = () => {
        if (Array.isArray(selectedData)) {
            let dataSelected = selectedData.map(m => { return m.id });
            if (treeListData)
                return treeListData.filter(m => dataSelected.includes(m.toolID)).map(m => { return m.ID });
        }
        return [];
    }

    const saveData = () => onButtonOk(selectedData);

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} close={onClose} width="45%" height="55%" onButtonOk={saveData} modalHeader={OrtogonalMachiningToolsTitle}>

                <TreeList
                    id="tool-grid"
                    dataSource={treeListData}
                    showRowLines={true}
                    showBorders={true}
                    columnAutoWidth={true}
                    showColumnHeaders={false}
                    keyExpr="ID"
                    parentIdExpr="headID"
                    defaultSelectedRowKeys={getSelected()}
                    onSelectionChanged={onSelectionChanged}>
                    <Selection recursive={true} mode="multiple" />
                    <Column dataField="toolName" />
                </TreeList>

            </Modal>
        </React.Fragment>
    );
}