import authService from "cyncly-login/authService";
import { useEffect } from "react";
import { useCacheAction } from "./cache.action";

export const useLogoutAction = () => {

    const cacheAction = useCacheAction();

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.key === 'F5') {
                event.preventDefault();
                logoutAction();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const logoutAction = async () => {
        await authService.logout();
        cacheAction();
        window.location.reload();
    };

    return logoutAction;
};