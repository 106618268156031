import React, { useEffect, useState } from "react";
import Modal from "../base/modal/modal";
import useModalHook from "../../hooks/modal.hook";
import { PropTypes } from 'data/modal/modal.proprieties';
import { FacesType, IFace, IFaceDataValue } from "../../data/solidFaces/faces";
import FacesUtil from "../../data/solidFaces/util/faces.util";
import { EditorsTranslates } from "../../translator/components/editors.translate";
import './css/selectSolidFace.scss';

const { All, None, SelectDeselectAll, SelectedFaces } = EditorsTranslates();

export default function SelectSolidFace({ show, data, onClose, onButtonOk }: PropTypes) {

    const [selectedFaces, setSelectedFaces] = useState<IFace[]>([]);
    const { isOpen, toggle, open } = useModalHook();

    const __FACES_LENGTH = Object.keys(FacesType).length;
    const allSelected = selectedFaces.length === __FACES_LENGTH;

    const selectedFacesText =
        allSelected ? All :
            selectedFaces.length > 0 ? selectedFaces.map(m => m.face).join(', ') :
                None;

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        if (show) {
            convertDataToFaces();
            open();
        }

        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [show])

    const convertSelectedFacesToDataValue = () => {
        const values: any[] = [];
        selectedFaces.map(face => {
            const convertedObj: IFaceDataValue = {
                [face.axis as string]: {
                    value: face.value as number,
                },
            };
            values.push(convertedObj);
        })
        return values;
    }

    const convertDataToFaces = async () => {
        for (const face of Object.values<IFace>(data ?? [])) {
            let faceAxis = Object.keys(face)?.shift();
            if (faceAxis !== undefined) {
                let faceValue = Object.values(face)?.shift()?.value;
                if (faceValue !== undefined) {
                    const newObj: IFace = {
                        face: FacesUtil.getFaceByAxisAndValue(faceAxis, faceValue),
                        axis: faceAxis,
                        value: faceValue
                    };
                    if (!selectedFaces.some(s => s.face === newObj.face)) {
                        setSelectedFaces(prevState => [...prevState, newObj]);
                    }
                }
            }
        }
    }

    const addDeleteFace = async (face: string) => {
        if (selectedFaces.some(s => s.face === face))
            removeFace(face)
        else
            addFace(face)
    }

    const removeFace = async (face: string) => {
        setSelectedFaces((current) =>
            current.filter((f) => f.face !== face)
        )
    }

    const addFace = async (face: string) => {
        if (!selectedFaces.some(s => s.face === face)) {
            const [axis, value] = FacesUtil.getAxisAndValueByFace(face);
            const newObj: IFace = {
                face: face,
                axis: axis,
                value: value
            };
            setSelectedFaces(prevState => [...prevState, newObj]);
        }
    }

    const checkFaceIsSelected = (face: string) => {
        return selectedFaces.some(s => s.face === face);
    }

    const handleToggleSelectAll = () => {
        if (allSelected)
            setSelectedFaces([]);
        else
            for (let i = 1; i <= __FACES_LENGTH; i++)
                addFace('F' + i);
    }

    const handleCancelEdit = () => {
        setSelectedFaces([]);
        onClose();
    }

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} close={handleCancelEdit} width="30%" height="40%" onButtonOk={onButtonOk}
                customButtonText={SelectDeselectAll} customButtonEvent={handleToggleSelectAll} modalDataState={convertSelectedFacesToDataValue()}>

                <div>
                    <span>{SelectedFaces} {selectedFacesText}
                    </span>
                </div>

                <div className="faces-area">

                    <table width={'100%'}>
                        <tbody>
                            <tr style={{ textAlign: 'center', height: '3em' }}>
                                <td></td>
                                <td
                                    className={checkFaceIsSelected('F5') ? 'selected-area' : 'default-area'}
                                    style={{ width: '15em' }} onClick={() => { addDeleteFace('F5') }}>
                                    <span style={{ verticalAlign: '-webkit-baseline-middle' }}>F5</span>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr style={{ textAlign: 'center', height: '6em' }}>
                                <td
                                    className={checkFaceIsSelected('F2') ? 'selected-area' : 'default-area'}
                                    style={{ width: '4em' }} onClick={() => { addDeleteFace('F2') }}>
                                    <span style={{ verticalAlign: '-webkit-baseline-middle' }}>F2</span>
                                </td>
                                <td
                                    className={checkFaceIsSelected('F1') ? 'selected-area' : 'default-area'}
                                    style={{ width: '15em' }} onClick={() => { addDeleteFace('F1') }}>
                                    <span style={{ verticalAlign: '-webkit-baseline-middle' }}>F1</span>
                                </td>
                                <td
                                    className={checkFaceIsSelected('F3') ? 'selected-area' : 'default-area'}
                                    style={{ width: '4em' }} onClick={() => { addDeleteFace('F3') }}>
                                    <span style={{ verticalAlign: '-webkit-baseline-middle' }}>F3</span>
                                </td>
                                <td
                                    className={checkFaceIsSelected('F6') ? 'selected-area' : 'default-area'}
                                    style={{ width: '15em' }} onClick={() => { addDeleteFace('F6') }}>
                                    <span style={{ verticalAlign: '-webkit-baseline-middle' }}>F6</span>
                                </td>
                            </tr>
                            <tr style={{ textAlign: 'center', height: '3em' }}>
                                <td></td>
                                <td
                                    className={checkFaceIsSelected('F4') ? 'selected-area' : 'default-area'}
                                    style={{ width: '12em' }} onClick={() => { addDeleteFace('F4') }}>
                                    <span style={{ verticalAlign: '-webkit-baseline-middle' }}>F4</span>
                                </td>

                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                </div>

            </Modal>
        </React.Fragment>
    );
}
