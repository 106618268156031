import React, { useEffect, useState } from "react";
import Modal from "../base/modal/modal";
import useModalHook from "../../hooks/modal.hook";
import { PropTypes } from 'data/modal/modal.proprieties';
import TranslatorHelper from '../../helpers/translatorHelper';
import { store } from "../../store/store";
import { TreeList, Selection, Column, TreeListTypes } from 'devextreme-react/tree-list';
import { IMill, ISaw, ITool } from "../../data/tools/toolData";
import { ITreeListTools } from "./editor.interfaces";
import { Utils } from "../../utils/utils";
import { EditorsTranslates } from "../../translator/components/editors.translate";
import './editor.css';

const { SlotToolsTitle, Mills, Saws } = EditorsTranslates();

export default function SelectedSlotTools({ show, data, currentSettings, onClose, onButtonOk }: PropTypes) {

    const [mills, setMills] = useState<IMill[]>([]);
    const [saws, setSaws] = useState<ISaw[]>([]);
    const [treeListData, setTreeListData] = useState<ITreeListTools[]>([]);
    const [selectedData, setSelectedData] = useState<ITool[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const { isOpen, toggle, open } = useModalHook();

    const state = store.getState();

    useEffect(() => {
        if (show) {
            if (currentSettings) {
                let toolSettings = currentSettings.toolSettings;
                if (toolSettings) {
                    setMills(toolSettings.mills || []);
                    setSaws(toolSettings.saws || []);
                }
            }
            open();
        }
    }, [show]);

    useEffect(() => {
        if (mills.length > 0 && saws.length > 0) {
            populateToolTreeList();
        }
    }, [mills, saws]);

    useEffect(() => {
        if (treeListData.length > 0) {
            const selectedTools = getSelectedTools(currentSettings, mills, saws);
            setSelectedData(selectedTools);
        }
    }, [treeListData]);

    useEffect(() => {
        updateSelectedKeys();
    }, [selectedData]);

    function populateToolTreeList() {
        let toolsArray: ITreeListTools[] = [];

        let millHeadTool: ITreeListTools = {
            ID: Utils.generateGUID(),
            headID: '0',
            toolName: Mills,
            isHead: true
        };

        toolsArray.push(millHeadTool);

        for (let m of mills) {
            let newTool: ITreeListTools = {
                ID: Utils.generateGUID(),
                headID: millHeadTool.ID,
                toolID: m.id,
                toolName: m.name ?? ""
            }
            toolsArray.push(newTool);
        }

        let sawHeadTool: ITreeListTools = {
            ID: Utils.generateGUID(),
            headID: '0',
            toolName: Saws,
            isHead: true
        };

        toolsArray.push(sawHeadTool);

        for (let s of saws) {
            let newTool: ITreeListTools = {
                ID: Utils.generateGUID(),
                headID: sawHeadTool.ID,
                toolID: s.id,
                toolName: s.name ?? ""
            }
            toolsArray.push(newTool);
        }

        setTreeListData(toolsArray);
    }

    function getSelectedTools(currentSettings: any, mills: IMill[], saws: ISaw[]): ITool[] {
        const selectedToolOrProcessIDByOperationType = currentSettings.toolSettings.selectedToolOrProcessIDByOperationType;

        if (!selectedToolOrProcessIDByOperationType.hasOwnProperty("SlotDTO")
            || Object.keys(selectedToolOrProcessIDByOperationType["SlotDTO"]).length === 0) {
            return [];
        }
        const selectedToolIDs = Object.values(selectedToolOrProcessIDByOperationType["SlotDTO"]);

        const selectedMills = mills.filter(mill => selectedToolIDs.includes(mill.id));
        const selectedSaws = saws.filter(saw => selectedToolIDs.includes(saw.id));

        return [...selectedMills, ...selectedSaws];
    }

    const onSelectionChanged = (e: TreeListTypes.SelectionChangedEvent) => {
        let childrenToolsID: any[] = [];
        if (e.selectedRowsData.some(s => s.isHead))
            childrenToolsID = treeListData.filter(f => f.headID === e.currentSelectedRowKeys[0]).map((tool) => { return tool.toolID });

        let toolsID = e.selectedRowsData.map((tool) => { return tool.toolID });
        let dataSelectedMills = mills.filter(m => toolsID.includes(m.id) || childrenToolsID.includes(m.id));
        let dataSelectedSaws = saws.filter(m => toolsID.includes(m.id) || childrenToolsID.includes(m.id));
        setSelectedData([...dataSelectedMills, ...dataSelectedSaws]);
        setSelectedKeys(e.selectedRowKeys);
    };

    const updateSelectedKeys = () => {
        let dataSelected = selectedData.map(m => { return m.id });
        if (treeListData)
            setSelectedKeys(treeListData.filter(m => dataSelected.includes(m.toolID)).map(m => { return m.ID }));
    }

    const saveData = () => onButtonOk(selectedData);

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} close={onClose} width="45%" height="55%" onButtonOk={saveData} modalHeader={SlotToolsTitle}>

                <TreeList
                    id="tool-grid"
                    dataSource={treeListData}
                    showRowLines={true}
                    showBorders={true}
                    columnAutoWidth={true}
                    showColumnHeaders={false}
                    keyExpr="ID"
                    parentIdExpr="headID"
                    selectedRowKeys={selectedKeys}
                    onSelectionChanged={onSelectionChanged}>
                    <Selection recursive={true} mode="multiple" />
                    <Column dataField="toolName" />
                </TreeList>

            </Modal>
        </React.Fragment>
    );
}
