import { IConditionRules } from "components/editor/editor.interfaces";
import { addItemToDictionary } from "../../components/properties/util/property.editor.util";
import { ISettings, IToolSettings } from "../../data/settings/settingsData";
import { IHandler } from "../handlerManager";

export class SelectedToolsByConditionsHandler implements IHandler {
    canHandle(bind: string, editorName: string): boolean {
        return bind === "SettingsDTO.ToolSettings.ToolIDByConditionID" && editorName === "ToolsByConditionRules";
    }

    handleTo(ogObject: object, object: object, value: any, bind: string) {
        const toolSettings = object as any;
        if (toolSettings !== undefined) {
            if (toolSettings!.toolIDByConditionID?.length !== 0) {
                return toolSettings!.toolIDByConditionID;
            }
        }
    }

    handleBack(ogObject: object, object: object, value: any, bind: string) {
        const toolSettings = object as IToolSettings;
        const settings = ogObject as ISettings;
        if (settings !== undefined && toolSettings !== undefined) {
            const rules = value as IConditionRules;
            if (rules) {
                if (!toolSettings.toolIDByConditionID) {
                    toolSettings.toolIDByConditionID = {};
                }

                let current: IConditionRules | undefined = rules;
                let toolIDByConditionID: { [key: string]: any } = {};

                const formatField = (field: string) => {
                    if (field && !field.startsWith("{") && !field.endsWith("}")) {
                        return `{${field}}`;
                    }
                    return field;
                };

                rules.field = formatField(rules.field);

                while (current) {
                    if (current.tool && current.tool.id) {
                        toolIDByConditionID = addItemToDictionary(toolIDByConditionID, current.id);
                        toolIDByConditionID[current.id] = current.tool.id;
                    }

                    current.field = formatField(current.field);
                    current = current.innerCondition;
                }

                settings.toolSettings = {
                    ...settings.toolSettings,
                    toolIDByConditionID,
                    conditions: [{ ...rules }]
                };
            }
        }
        return value;
    }
}