import { CheckBox } from "devextreme-react";
import React from "react";
import { useEffect, useState } from "react";

interface PropertyCheckboxState {
    checkedOption: boolean;
}

export default function PropertyCheckbox({ field, onChange, disableForm }: any) {

    const [state, setState] = useState<PropertyCheckboxState>({
        checkedOption: field.value ?? false
    });

    useEffect(() => {
        setState({ checkedOption: field.value ?? false });
    }, [field]);

    const handleCheckChange = () => {
        onChange(field.id, !state.checkedOption);
    };

    return (
        <React.Fragment >
            <CheckBox disabled={disableForm}
                name={field.id} visible={field.isVisible !== undefined ? !!field.isVisible : false} onValueChange={handleCheckChange}
                value={state?.checkedOption} />
        </React.Fragment >
    )
}