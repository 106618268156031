import React, { useCallback, useEffect, useRef, useState } from 'react';
import ToolBarLabel from './nesting.toolBarLabel';
import PropertyInput from '../properties/property.input';
import { Field } from '../properties/property.data';
import NestingToolbarInput from './nesting.toolbar.input';
import NestingToolBarLabel from './nesting.toolBarLabel';
import SelectBox, { SelectBoxTypes } from 'devextreme-react/cjs/select-box';

import headersIcon from '../../assets/images/icons/table.svg';
import generateByMaterialToolsIcon from '../../assets/images/icons/file_edit.svg';
import encodingIcon from '../../assets/images/icons/text_style_plain.svg';
import specialCharacteresIcon from '../../assets/images/icons/rename.svg';
import BindManager from '../../helpers/bindHelper';
import { ISettings } from '../../data/settings/settingsData';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../store/store';
import { editSettings } from '../../store/settings.store';
import { INavigation } from '../../data/navigation/navigationType';
import SettingsHelper from '../../helpers/settingsHelper';

interface ToolBarProps {
    fields: Field[];
    onChange: (field: string, value: any) => void;
}

const ToolBarNesting: React.FC<ToolBarProps> = ({ fields, onChange }) => {

    const { state } = useLocation();
    const [bindedFields, setFields] = useState<Field[]>();
    const settings = useRef<ISettings>();
    const settingsHelper = new SettingsHelper();
    const bindManager = new BindManager();
    const dispatch = useAppDispatch();

    useEffect(() => {
        assignUserSettings();

        const bindedFields = bindManager.assignBinds(fields, settings.current!);
        setFields(bindedFields);

    }, [fields])

    function assignUserSettings() {
        const storedSettings = settingsHelper.getStoredSettings(undefined!, state.pageNavigation as INavigation);

        settings.current = storedSettings[1]!;
        dispatch(editSettings(storedSettings[0]!));
    }

    return (
        <React.Fragment>
            <div className="toolbar">
                {
                    bindedFields?.filter(f => f.type === 'Text').map((field, index) => (
                        <div key={index} className="toolbar-div">
                            <NestingToolBarLabel field={field} />
                            <NestingToolbarInput field={field} onChange={onChange} />
                        </div>
                    ))
                }
                <div className={'divider'} />
                {
                    bindedFields?.filter(f => f.type === 'Bool').map((field, index) => (
                        <div key={index} className="toolbar-div">
                            <NestingToolBarLabel field={field} />
                            <NestingToolbarInput field={field} onChange={onChange} />
                        </div>
                    ))
                }
                <div className={'divider'} />
                {
                    bindedFields?.filter(f => f.type === 'Enum').map((field, index) => (
                        <div key={index} className="toolbar-div">
                            <NestingToolBarLabel field={field} />
                            <NestingToolbarInput field={field} onChange={onChange} />
                        </div>
                    ))
                }

            </div>
        </React.Fragment>
    );
};

export default ToolBarNesting;
