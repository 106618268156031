import { IMachineSettings } from "../data/settings/settingsData";
import { IHandler } from "./handlerManager";

export class AdditionalInfoHandler implements IHandler {
    canHandle(bind: string): boolean {
        const regex = new RegExp("AdditionalInfo\\[(.*)\\]");
        const match = regex.exec(bind.split('.').pop() || '');
        return !!match;
    }

    handleTo(ogObject: object, object: object, value: any, bind: string) {
        const settings = object as IMachineSettings;
        if (settings !== undefined) {
            const key = bind.match(/\[(.*?)\]/g)?.toString().replace('[', '').replace(']', '') ?? "";

            if (settings.additionalInfo![key] !== undefined) {
                return settings.additionalInfo![key];
            }
        }

        return value;
    }

    handleBack(ogObject: object, object: object, value: any, bind: string) {
        const settings = object as IMachineSettings;
        if (settings !== undefined) {
            const key = bind.match(/\[(.*?)\]/g)?.toString().replace('[', '').replace(']', '') ?? "";

            if (settings.additionalInfo === undefined) {
                settings.additionalInfo = {};
            }

            settings.additionalInfo[key] = value;
        }

        return value;
    }
}