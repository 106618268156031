import { UtilsTranslate } from 'utils/utils.translate';
import { Category, Field } from '../components/properties/property.data';
import { editorTypeEnum } from '../data/editor/editorType';

class TranslatorFields {

    getTranslatedFieldsAndCategories = (fields: Field[], categories: Category[]) => {
        const translations = UtilsTranslate.getTranslationFile();
        const translationFields = this.setFieldTranslations(fields, translations);

        const translationCategories = this.setTranslationCategory(categories, translations);
        return {
            translationFields,
            translationCategories
        };
    }

    getTranslatedCategories = (categories: Category[]) => {
        const categoriesTranslations = UtilsTranslate.getTranslationFile();
        const translationCategories = this.setTranslationCategory(categories, categoriesTranslations);

        return translationCategories;
    }

    getTranslatedFields = (fields: Field[]) => {
        const translations = UtilsTranslate.getTranslationFile();
        const translationFields = this.setFieldTranslations(fields, translations);

        return translationFields;
    }

    getTranslatedValue = (value: string) => {
        const translations = UtilsTranslate.getTranslationFile();
        return translations?.[value] || value;
    }

    private setFieldTranslations = (fields: Field[], translations?: { [key: string]: string }) => {
        if (translations) {
            fields?.forEach((field) => {
                if (field.type !== "Column") {
                    const Key = field.editorType !== editorTypeEnum.Undefined ? `${field?.bind?.[0] || ""}_${field.editorType}` : field?.bind?.[0] || "";

                    field.localizedName = field.localizedName ? field.localizedName : translations?.[Key] || Key;
                    field.localizedDescription = field.localizedDescription ? field.localizedDescription : translations?.[Key.concat("_Description")] || Key.concat("_Description");

                    field?.constants?.forEach((constant) => {
                        let constantValue = constant.value;
                        if (field.translateEnum) {
                            constantValue = `${field.translateEnum}.${constantValue}`;
                        }
                        constant.localizedName = constant.localizedName ? constant.localizedName : translations?.[constantValue] || constantValue;
                    });

                    field?.toolTips?.forEach((toolTips) => {
                        toolTips.localizedName = toolTips.localizedName ? toolTips.localizedName : translations?.[toolTips.id] || toolTips.id;
                    });

                    field?.editorParameter?.logicalOptions?.forEach((logicalOptions) => {
                        logicalOptions.localizedName = logicalOptions.localizedName ? logicalOptions.localizedName : translations?.[logicalOptions.id] || logicalOptions.id;
                    });

                    field?.editorParameter?.operatorOptions?.forEach((operatorOptions) => {
                        operatorOptions.localizedName = operatorOptions.localizedName ? operatorOptions.localizedName : translations?.[operatorOptions.id] || operatorOptions.id;
                    });

                    field?.editorParameter?.fieldOptions?.forEach((fieldOptions) => {
                        fieldOptions.localizedName = fieldOptions.localizedName ? fieldOptions.localizedName : translations?.[fieldOptions.id] || fieldOptions.id;
                    });
                }
                else {
                    const Key = field?.defaultName || "";

                    field.value = translations?.[Key] || Key;
                    field.visibleValue = field.visibleValue ? field.visibleValue : translations?.[Key.concat("_Description")] || Key.concat("_Description");
                }
            });
        }

        return fields;
    }

    private setTranslationCategory = (categories: Category[], translations?: { [key: string]: string }) => {
        if (translations) {
            categories?.forEach((category) => {
                const Key = category?.id || "";

                category.groupName = category.groupName ? category.groupName : translations?.[Key] || Key;
            });
        }

        return categories;
    }
}

export default TranslatorFields;
