export async function getPromobAccountData<T>(token: string, accountId: string): Promise<T | null> {
    const address = `https://api.promob.com/v1/Accounts/${accountId}`;
    const response = await requestResponseMessageAsync(token, address);

    if (response.status === 404) {
        return null;
    }

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseBody = await response.json();
    return responseBody as T;
}

async function requestResponseMessageAsync(token: string, address: string): Promise<Response> {
    const response = await fetch(address, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    return response;
}