import { addItemToDictionary, addToolIDsByType, addValueToDictionary, removeItemFromDictionary, removeValueFromDictionary } from "../../components/properties/util/property.editor.util";
import { IMachineSettings, ISettings } from "../../data/settings/settingsData";
import { IHandler } from "../handlerManager";

export class SelectedSlotProcessesHandler implements IHandler {
    canHandle(bind: string): boolean {
        return bind === "SettingsDTO.SlotSettings.AvailableProcesses";
    }

    handleTo(ogObject: object, object: object, value: any, bind: string) {
        const slotSettings = object as any;
        if (slotSettings !== undefined) {
            if (slotSettings!.availableProcesses?.length !== 0) {
                return slotSettings!.availableProcesses;
            }
        }

        return value;
    }

    handleBack(ogObject: object, object: object, value: any, bind: string) {
        const slotSettings = object as any;
        const settings = ogObject as ISettings;
        if (slotSettings !== undefined) {
            slotSettings.availableProcesses = value?.map((v: any) => {
                if (v.enablingProcessOptionsType !== 0) {
                    if (v.enablingProcessOptionsType === 3) {
                        const toolIDByProcessID = removeItemFromDictionary(settings.toolSettings?.toolIDByProcessID!, v.processOutput?.processId);
                        settings.toolSettings = {
                            ...settings.toolSettings,
                            toolIDByProcessID
                        }

                        const selectedToolOrProcessIDByOperationType = addValueToDictionary(settings.toolSettings?.selectedToolOrProcessIDByOperationType!, "SlotDTO", v.processOutput?.processId);
                        settings.toolSettings = {
                            ...settings.toolSettings,
                            selectedToolOrProcessIDByOperationType
                        }
                    }
                    else if (v.enablingProcessOptionsType === 5) {
                        const toolIDByProcessID = addItemToDictionary(settings.toolSettings?.toolIDByProcessID!, v.processOutput?.processId);
                        settings.toolSettings = {
                            ...settings.toolSettings,
                            toolIDByProcessID
                        }

                        const selectedToolOrProcessIDByOperationType = addValueToDictionary(settings.toolSettings?.selectedToolOrProcessIDByOperationType!, "SlotDTO", v.processOutput?.processId);
                        settings.toolSettings = {
                            ...settings.toolSettings,
                            selectedToolOrProcessIDByOperationType
                        }
                    }

                    return v.processOutput?.processId
                }
                else {
                    const toolIDByProcessID = removeItemFromDictionary(settings.toolSettings?.toolIDByProcessID!, v.processOutput?.processId);
                    settings.toolSettings = {
                        ...settings.toolSettings,
                        toolIDByProcessID
                    }

                    const selectedToolOrProcessIDByOperationType = removeValueFromDictionary(settings.toolSettings?.selectedToolOrProcessIDByOperationType!, "SlotDTO", v.processOutput?.processId);
                    settings.toolSettings = {
                        ...settings.toolSettings,
                        selectedToolOrProcessIDByOperationType
                    }
                }
            })
                .filter((v: any) => v !== undefined);

            settings.machineSettings.machiningDisabledProcesses = value?.map((v: any) => {
                if (v.enablingProcessOptionsType === 0) {
                    return v.processOutput?.processId
                }
            })
                .filter((v: any) => v !== undefined);

            return slotSettings.availableProcesses;
        }

        return value;
    }
}