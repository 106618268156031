import './setup.scss';

import React, { useState, useEffect, useRef } from 'react';
import PropertyForm, { ChangesPropagationLevel } from '../../components/properties/property.form';
import { Category, Field } from '../../components/properties/property.data';
import BindManager from '../../helpers/bindHelper'
import { ISetup } from '../../data/setup/setupData';
import { useLocation } from 'react-router-dom';
import { INavigation } from '../../data/navigation/navigationType';
import PageHeaderComponent from '../../components/pages/header/page.header.component';
import { ISettings, ISettingsInput } from '../../data/settings/settingsData';
import { eHeaderButtonVisibility } from '../../components/header/header-button/header.button.visibility.types';
import { editSettings } from '../../store/settings.store';
import { store, useAppDispatch } from '../../store/store';
import TabPanelComponent from '../../components/tab-panel/tab.panel.component';
import { SetupTranslates } from 'translator/pages/setup.translate';
import { useTranslator } from 'contexts/translator.context';
import { addSelectedElement, editSelectedElement } from 'store/selected.store';
import { Utils } from 'utils/utils';

const { Title } = SetupTranslates();

export const setupSection = 'Setup';

const headerButtonsVisibility: eHeaderButtonVisibility[] = [eHeaderButtonVisibility.ENABLE_SETUPS, eHeaderButtonVisibility.COPY, eHeaderButtonVisibility.ADD, eHeaderButtonVisibility.DELETE, eHeaderButtonVisibility.OPTIONS]

const Setup: React.FC = () => {
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const [allowBindAssign, setAllowBindAssign] = useState(true);
  const [fields, setFields] = useState<Field[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [setups, setSetups] = useState<ISetup[]>([]);
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [copyIndex, setCopyIndex] = useState<number>(1);
  const currentSettings = useRef<ISettings>();
  const currentSetup = useRef<ISetup>();
  const translatorHelper = useTranslator();
  const bindManager = new BindManager();

  useEffect(() => {
    currentSettings.current = undefined;
    currentSetup.current = undefined;
    setFields([])
    setCategories([])
    setSetups([])
    setAllowBindAssign(true)

    const navigation = state.pageNavigation as INavigation;

    if (navigation != null && navigation.sectionTemplate?.pageSection == setupSection) {
      const formFields = JSON.parse(JSON.stringify(navigation.sectionTemplate));
      const translationPromise = translatorHelper.getTranslatedFieldsAndCategories(formFields.properties, formFields.categories);

      setFields(translationPromise.translationFields);
      setCategories(translationPromise.translationCategories);
    }
  }, [state.pageNavigation]);

  const getSelectedSetup = () => {
    return setups?.find(f => f.selected == true);
  }

  const onEnableOrDisableSetup = async () => {
    let settings = JSON.parse(JSON.stringify(currentSettings.current!)) as ISettings;
    let currentSetup = JSON.parse(JSON.stringify(getSelectedSetup())) as ISetup;

    currentSetup!.settings!.isEnabled = settings.isEnabled = !settings.isEnabled;

    updateSettings(settings);
    currentSettings.current! = settings;
    setupSelected(settings.id!, true);
  }

  const onAddNewSetup = async () => {
    const uuid = Utils.generateGUID();
    let newSetup: ISetup = {
      id: uuid,
      name: uuid,
      settings: {
        ...currentSettings.current!,
        id: uuid,
        name: uuid
      }
    };

    updateSettings(newSetup.settings!);
    currentSettings.current! = newSetup.settings!;
    setSetups(previousState => [...previousState, newSetup]);
  }

  const onDeleteSetup = async () => {
    const selectedSetup = getSelectedSetup();
    let indexOf = setups.findIndex(x => x == selectedSetup);
    if (selectedSetup != undefined) {
      deleteSettings(selectedSetup.settings!);

      let newsetups = setups.filter(a => a.id !== selectedSetup?.id);
      setSetups(newsetups);

      if (indexOf > 0) {
        updateSettings(newsetups[indexOf - 1].settings!);
        currentSettings.current! = newsetups[indexOf - 1].settings!;
        setupSelected(newsetups[indexOf - 1].id!);
      }
      else {
        updateSettings(newsetups[0].settings!);
        currentSettings.current! = newsetups[0].settings!;
        setupSelected(newsetups[0].id!);
      }
    }
  }

  const onDuplicateSetup = async () => {
    let selectedSetup = getSelectedSetup();
    if (selectedSetup != undefined) {
      const uuid = Utils.generateGUID();
      let duplicateSetup: ISetup = {
        ...selectedSetup,
        id: uuid,
        name: `${currentSettings.current?.name}(${copyIndex})`,
        settings: {
          ...currentSettings.current!,
          id: uuid
        }
      };

      setCopyIndex(copyIndex + 1);
      updateSettings(duplicateSetup.settings!);
      currentSettings.current! = duplicateSetup.settings!;
      setSetups(previousState => [...previousState, duplicateSetup]);
    }
  }

  const onSetupClicked = (setupID: string) => {
    setupSelected(setupID);
  }

  const setupSelected = (setupID: string, isEnableOrDisable?: boolean) => {

    let getSetup = setups.find(f => f.id === setupID);

    if (getSetup)
      currentSetup.current = getSetup;

    let updatedFields: Field[] = [];
    for (var field of fields) {
      updatedFields.push({
        ...field,
      })
    }

    bindManager.assignBinds(updatedFields, getSetup?.settings);

    currentSettings.current = getSetup?.settings;

    setSetups(prevState => {
      let newState = prevState?.map(obj => {
        if (obj.id === getSetup?.id) {
          if (isEnableOrDisable === true) {
            if (obj.settings?.machineSettings) {
              dispatch(editSelectedElement({
                selectedSetup: {
                  ...obj,
                  settings: {
                    ...obj.settings,
                    machineSettings: obj.settings?.machineSettings,
                    isEnabled: !obj.settings?.isEnabled,
                  }
                }
              }));
              return {
                ...obj,
                settings: {
                  ...obj.settings,
                  isEnabled: !obj.settings?.isEnabled,
                }
              }
            }
          } else {
            const selected = obj.selected === undefined ? true : !obj.selected;
            dispatch(addSelectedElement({
              selectedSetup: {
                ...obj,
                selected: selected
              }
            }));
            return {
              ...obj,
              selected: selected
            };
          }
        }
        return {
          ...obj,
          selected: false
        };
      });
      return newState
    });

    setFields(updatedFields)
  }

  function updateSettings(sett: ISettings) {
    const storedSettings = store.getState().settingsDataState.settings ?? [];

    storedSettings.forEach(SS => {
      const settingsInput: ISettingsInput = {
        machineInfo: SS.machineInfo,
        settingsToUpdate: [],
        settingsToDelete: SS.settingsToDelete
      }

      if (SS.machineInfo.id === currentSettings.current!.machineInfoId) {
        const matchingSettings = SS.settingsToUpdate?.find(S => S.machineSettings?.id === currentSettings.current!.machineSettings?.id);

        if (matchingSettings !== undefined) {
          const validSettings = SS.settingsToUpdate?.filter(F => F.id !== sett?.id)
          validSettings?.forEach(S => settingsInput.settingsToUpdate?.push(S));
          settingsInput.settingsToUpdate?.push(sett!);
        }
        else {
          SS.settingsToUpdate?.forEach(SU => {
            settingsInput.settingsToUpdate?.push(SU);
          });
        }

        dispatch(editSettings(settingsInput));
      }
    })
  }

  function deleteSettings(sett: ISettings) {
    const storedSettings = store.getState().settingsDataState.settings ?? [];

    storedSettings.forEach(SS => {
      const settingsInput: ISettingsInput = {
        machineInfo: SS.machineInfo,
        settingsToUpdate: [],
        settingsToDelete: JSON.parse(JSON.stringify(SS.settingsToDelete))
      }

      if (SS.machineInfo.id === currentSettings.current!.machineInfoId) {
        const matchingSettings = SS.settingsToUpdate?.find(S => S.machineSettings?.id === currentSettings.current!.machineSettings?.id);

        if (matchingSettings !== undefined && !settingsInput.settingsToDelete?.find(S => S.id === sett.id)) {
          const validSettings = SS.settingsToUpdate?.filter(F => F.id !== sett?.id)
          validSettings?.forEach(S => settingsInput.settingsToUpdate?.push(S));

          settingsInput.settingsToDelete?.push(sett);
        }

        dispatch(editSettings(settingsInput));
      }
    })
  }

  const onUpdateSettings = () => {
    return currentSettings.current!;
  }

  const onBindAssign = (settings?: ISettings) => {

    if (allowBindAssign) {
      currentSettings.current = settings;

      setSetups(assignSetups(settings));

      setAllowBindAssign(false);
    }

    if (getSelectedSetup() === undefined) {
      setupSelected(setups![0]?.id!);
    }

    setPageLoaded(Array.isArray(setups) && setups.length > 0);
    return fields;
  }

  const onBindUpdate = (fieldValue?: any, obj?: Field, settings?: ISettings) => {
    if (!obj!.bind || fieldValue === undefined) return;
    currentSettings.current = settings!;

    let selectedSetup = JSON.parse(JSON.stringify(getSelectedSetup()));

    bindManager.setValue(fieldValue, obj!, selectedSetup.settings);

    updateSettings(selectedSetup.settings!);

    currentSettings.current = selectedSetup.settings!;

    const updatedSetups = assignSetups(selectedSetup.settings!);
    setSetups(updatedSetups);

    setSetups(prevSetups => {
      return prevSetups.map(setup => {
        if (setup.id === selectedSetup.id) {
          return selectedSetup;
        } else {
          return setup;
        }
      });
    });
  }

  function assignSetups(settings?: ISettings) {
    const setups: ISetup[] = [];
    const storedSettings = store.getState().settingsDataState.settings ?? [];
    storedSettings.forEach(SS => {
      if (SS.machineInfo.id === currentSettings.current!.machineInfoId) {
        SS.settingsToUpdate?.forEach(SU => {
          if (SU.machineSettings?.id === settings!.machineSettings?.id) {
            const sett = JSON.parse(JSON.stringify(SU)) as ISettings;
            if (!setups.some(s => s.id == sett.id))
              setups.push({ id: sett.id, name: sett.name, settings: sett });
          }
        })
      }
    })
    return setups;
  }

  const onFieldChanged = (fieldName?: string, fieldValue?: any) => {
    if (fieldName === "SettingsName") {
      setSetups(prevState => {
        let newState = prevState?.map(obj => {
          if (obj.selected) {
            return {
              ...obj,
              name: fieldValue
            };
          }
          return {
            ...obj
          };
        });
        return newState;
      });
    }
  }

  return (
    <div>
      <React.Fragment>
        <PageHeaderComponent pageData={setups} buttonHandlers={[
          [eHeaderButtonVisibility.ENABLE_SETUPS, onEnableOrDisableSetup],
          [eHeaderButtonVisibility.ADD, onAddNewSetup],
          [eHeaderButtonVisibility.DELETE, onDeleteSetup],
          [eHeaderButtonVisibility.COPY, onDuplicateSetup]]}
          headerButtonsVisibility={headerButtonsVisibility} title={Title} />
        <div className='page-body-layout'>
          <TabPanelComponent currentData={currentSetup.current} data={setups} pageLoaded={pageLoaded} onTabChanged={(id: string) => onSetupClicked(id)} />
          <PropertyForm changesPropagationLevel={ChangesPropagationLevel.None}
            categories={categories} fields={fields} onUpdateSettings={onUpdateSettings} onBindAssign={onBindAssign} onBindUpdate={onBindUpdate}
            onFieldChanged={onFieldChanged} />
        </div>
      </React.Fragment>
    </div>
  );

}

export default Setup;