import { Field } from '../components/properties/property.data';
import StringHelper from './stringHelper';
import ConvertManager from './../converters/converterManager';
import HandlerManager from '../handlers/handlerManager';

const stringHelper = new StringHelper();
const convertManager = new ConvertManager();
const handlerManager = new HandlerManager();

class BindManager {
    assignBinds = (fields: Field[], object: any) => {

        if (object === undefined || object === null)
            return fields;

        fields?.forEach((field) => {
            field.bind?.forEach((bind) => {
                let bindSteps = bind.split('.');
                let resolvedObject = object;

                for (let i = 0; i < bindSteps.length - 1; i++) {
                    let reflectObject = Reflect.get(object, stringHelper.toCamelCase(bindSteps[i]));

                    resolvedObject = reflectObject == undefined ? resolvedObject : reflectObject;
                }

                let property = Reflect.get(resolvedObject, stringHelper.toCamelCase(bindSteps[bindSteps.length - 1])) ?? "";

                if (handlerManager.canHandle(bind, field.editorType)) {
                    field.value = handlerManager.handleTo(object, resolvedObject, property, bind, field.editorType);
                } 
                else {
                    field.value = convertManager.convertValue(property, bind);
                }
            })
        });

        return fields;
    }

    setValue = (newValue: string, obj: Field, object: any) => {
        obj.bind?.forEach((bind) => {
            let bindSteps = bind.split('.');
            let resolvedObject = object;

            for (let i = 0; i < bindSteps.length - 1; i++) {
                let reflectObject = Reflect.get(object, stringHelper.toCamelCase(bindSteps[i]));

                resolvedObject = reflectObject == undefined ? resolvedObject : reflectObject;
            }

            if (handlerManager.canHandle(bind, obj.editorType)) {
                handlerManager.handleBack(object, resolvedObject, newValue, bind, obj.editorType);
            }
            else {
                Reflect.set(resolvedObject, stringHelper.toCamelCase(bindSteps[bindSteps.length - 1]), convertManager.unconvertValue(newValue, bind));
            }
        })
    };
}

export default BindManager;