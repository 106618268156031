import { AdditionalInfoHandler } from "./additionalInfoHandler";
import { AdditionalSettingsHandler } from "./additionalSettingsHandler";
import { OperationsOrderHandler } from "./editors/operationsOrderHandler";
import { PrioritizationOrderHandler } from "./editors/prioritizationOrderHandler";
import { SelectedInsertionProcessesHandler } from "./editors/selectedInsertionProcessesHandler";
import { SelectedMachiningProcessesHandler } from "./editors/selectedMachiningProcessesHandler";
import { SelectedSlotProcessesHandler } from "./editors/selectedSlotProcessesHandler";
import { SelectedMachiningToolsHandler } from "./editors/selectedMachiningToolsHandler";
import { SelectedSlotToolsHandler } from "./editors/selectedSlotToolsHandler";
import { LabelSettingsSelectedHandler } from "./editors/labelSettingsSelectedHandler";
import { FullLabelSettingsSelectedHandler } from "./editors/fullLabelSettingsSelectedHandler";
import { SelectedSlotToolsByProcessesHandler } from "./editors/selectedSlotToolsByProcessesHandler";
import { SelectedMachiningToolsByProcessesHandler } from "./editors/selectedMachiningToolsByProcessesHandler";
import { SelectedToolsByConditionsHandler } from "./editors/selectedToolsByConditionsHandler";

export interface IHandler {
    canHandle(bind: string, editorName?: string): boolean;
    handleTo(ogObject: object, object: object, value: any, bind: string, editorName?: string): any;
    handleBack(ogObject: object, object: object, value: any, bind: string, editorName?: string): any;
}

class HandlerManager implements IHandler {
    private readonly handlers: IHandler[] = [
        new PrioritizationOrderHandler(),
        new OperationsOrderHandler(),
        new SelectedMachiningProcessesHandler(),
        new SelectedSlotProcessesHandler(),
        new SelectedInsertionProcessesHandler(),
        new SelectedMachiningToolsHandler(),
        new SelectedMachiningToolsByProcessesHandler(),
        new SelectedSlotToolsHandler(),
        new SelectedSlotToolsByProcessesHandler(),
        new SelectedToolsByConditionsHandler(),
        new AdditionalSettingsHandler(),
        new LabelSettingsSelectedHandler(),
        new FullLabelSettingsSelectedHandler(),
        new AdditionalInfoHandler()];

    canHandle(bind: string, editorName: string) {
        return this.handlers.some(H => H.canHandle(bind, editorName));
    }

    handleTo(ogObject: object, object: object, value: any, bind: string, editorName: string): any {
        const handler = this.handlers.find(C => C.canHandle(bind, editorName));

        if (handler === undefined) {
            return value;
        }
        else {
            return handler.handleTo(ogObject, object, value, bind);
        }
    }

    handleBack(ogObject: object, object: object, value: any, bind: string, editorName: string): any {
        const handler = this.handlers.find(C => C.canHandle(bind, editorName));

        if (handler === undefined) {
            return value;
        }
        else {
            return handler.handleBack(ogObject, object, value, bind);
        }
    }
}


export default HandlerManager;