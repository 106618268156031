import { Value } from "../data/utils/vectorsData";
import { IConverter } from "./converterManager";

export class WorkingAreaConverter implements IConverter {
    canConvert(bind: string): boolean {
        if (bind === "SettingsDTO.MachineSettings.WorkingAreas.Width"
            || bind === "SettingsDTO.MachineSettings.WorkingAreas.Height"
            || bind === "SettingsDTO.MachineSettings.WorkingAreas.Offset.X"
            || bind === "SettingsDTO.MachineSettings.WorkingAreas.Location.X"
            || bind === "SettingsDTO.MachineSettings.WorkingAreas.Offset.Y"
            || bind === "SettingsDTO.MachineSettings.WorkingAreas.Location.Y")
            return true;

        return false;
    }

    convertTo(value: any) {
        if (value === undefined) return value;

        const sizeValue = JSON.parse(JSON.stringify(value)) as Value;

        return sizeValue.value;
    }

    convertBack(value: any) {
        return new Value(value ?? 0);
    }
}