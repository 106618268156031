import React, { createContext, useContext, useState, FC } from 'react';

interface LoaderContextProps {
    isLoaderActive: boolean;
    loaderText: string;
    setLoaderActive: (load: boolean) => void;
    setLoaderText: (load: string) => void;
}

const LoaderContext = createContext<LoaderContextProps | undefined>(undefined);

const LoaderProvider: FC<{ children: React.ReactNode }> = ({ children }) => {

    const [isLoaderActive, setLoaderActive] = useState<boolean>(false);
    const [loaderText, setLoaderText] = useState<string>('');

    return (
        <LoaderContext.Provider value={{ isLoaderActive, loaderText, setLoaderText, setLoaderActive }}>
            {children}
        </LoaderContext.Provider>
    );
};

export const useLoader = () => {
    const context = useContext(LoaderContext);
    if (!context) {
        throw new Error('useLoader must be used within a LoaderProvider');
    }
    return context;
};

export default LoaderProvider;