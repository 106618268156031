import './side-navigation-menu.css';

import React, { useEffect, useRef, useCallback, useState } from 'react';
import TreeView, { TreeViewRef } from 'devextreme-react/tree-view';
import { useNavigation } from '../../contexts/navigation.context';
import type { SideNavigationMenuProps } from '../../types';
import * as events from 'devextreme/events';
import { INavigation } from '../../data/navigation/navigationType';
import { createNavigationPages, navigation } from '../../app-navigation';
import minusIcon from '../../assets/images/icons/minus.svg'
import plusIcon from '../../assets/images/icons/plus.svg';
import NavigationItem from './navigation.item.component';
import DropDownButton, { DropDownButtonTypes } from 'devextreme-react/drop-down-button';

import logo from '../../assets/images/cyncly-logo.png';
import { useNavigate } from 'react-router-dom';
import authService from 'cyncly-login/authService';
import { User } from 'oidc-client-ts';
import { SideNavigationTranslates } from 'translator/components/side.navigation.translate';
import { ItemClickEvent } from 'devextreme/ui/tree_view';
import { useLogoutAction } from 'components/actions/logout.action';
import { store, useAppDispatch, useAppSelector } from 'store/store';
import { eCallEventType, eTriggerCRUDType, removeEventTrigger, removeForcedEventTrigger } from 'store/event.store';
import { Utils } from 'utils/utils';
import { addMachine } from 'store/machine.store';
import importIcon from '../../assets/images/icons/import.svg';
import exportIcon from '../../assets/images/icons/export.svg';
import userLogoutIcon from '../../assets/images/icons/user_logout.svg';
import useExportSettingsAction from 'components/actions/export.settings.action';
import useImportSettingsAction from 'components/actions/import.settings.action';
import ImportSettingsModal from 'components/import/import.settings.modal';

enum eUserMenuItemDataType {
  IMPORT,
  EXPORT,
  LOGOUT
}

interface UserMenuItem {
  userMenuItemDataType?: eUserMenuItemDataType;
  icon?: string;
  text: string;
  value?: string
}

const { Logout, Import, Export } = SideNavigationTranslates();

export default function SideNavigationMenu(props: React.PropsWithChildren<SideNavigationMenuProps>) {
  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady
  } = props;

  const [user, setUser] = useState<User | null>();
  const [dropDownMenuItems, setDropDownMenuItems] = useState<UserMenuItem[]>();
  const [navigations, setNavigations] = useState<INavigation[]>([]);
  const { navigationData: { currentPath } } = useNavigation();
  const [changePage, setChangePage] = useState<boolean>(false);
  const [openImportSettingsModal, setOpenImportSettingsModal] = useState<boolean>(false);
  const treeViewRef = useRef<TreeViewRef>(null);
  const wrapperRef = useRef<HTMLDivElement>();

  const triggerEvent = useAppSelector((state) => state.callEventDataState.triggerEvent);
  const forceReloadNavigationsTrigger = useAppSelector((state) => state.callEventDataState.forceTriggerEvent);
  const dispatch = useAppDispatch();
  const state = store.getState();
  const navigate = useNavigate();
  const logoutAction = useLogoutAction();
  const exportSettingsAction = useExportSettingsAction();
  const importSettingsAction = useImportSettingsAction();

  useEffect(() => {
    manageNavitagions();
  }, [triggerEvent]);

  useEffect(() => {
    if (changePage)
      changeToNextNavigation();
  }, [navigations]);

  useEffect(() => {
    startNavigations();
  }, [forceReloadNavigationsTrigger]);

  useEffect(() => {
    getUser();
    getDropDownMenuItems();

    const treeView = treeViewRef.current && treeViewRef.current.instance();
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      if (currentPath == '/') {
        onItemClicked("", true);
      } else {
        treeView.selectItem(currentPath);
        treeView.expandItem(currentPath);
      }
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode]);

  const getWrapperRef = useCallback((element: HTMLDivElement) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'dxclick');
    }

    wrapperRef.current = element;
    events.on(element, 'dxclick', (e: React.PointerEvent) => {
      openMenu(e);
    });
  }, [openMenu]);

  const changeToNextNavigation = () => {
    setChangePage(false);
    const nextPath = navigations[0]?.items?.[0]?.items?.[0]?.items;
    if (nextPath) {
      let getNext = nextPath.find(s => s.isSetupPage);
      if (getNext) {
        onItemClicked("", true);
        navigate(getNext.path ?? '/', { state: { pageNavigation: getNext } });
      }
    }
  }

  const startNavigations = () => {
    let navigationData = navigation();
    setNavigations(navigationData);

    for (let nav of navigationData) {
      if (nav.items)
        for (let modelNavigation of nav.items) {
          if (modelNavigation.items)
            for (let machineNavigation of modelNavigation.items) {
              dispatch(addMachine({
                id: machineNavigation.id,
                name: machineNavigation.text,
                selected: machineNavigation.itemClicked
              }));
            }
        }
    }

    if (forceReloadNavigationsTrigger === true)
      dispatch(removeForcedEventTrigger());
  }

  const addNavitagion = (navigationData: INavigation, pluginId: number) => {
    navigationData.id = Utils.generateGUID();
    let templates = state.userDataState.user.templates;
    let machineTemplate = templates.find(x => x.machineInfo.pluginId == pluginId);
    let newTreeNavigation = createNavigationPages(navigationData.machineInfo!, machineTemplate?.templates, navigationData.settings, navigationData.defaultSettings)[0];

    setNavigations(prevState => {
      let newState = prevState?.map(obj => {
        if (obj.items![0].machineInfo?.id === newTreeNavigation.machineInfo?.id) {
          obj.items![0].items!.push(newTreeNavigation);
          return {
            ...obj
          };
        }
        else
          return obj;
      });
      return newState;
    }
    );
  }

  const editNavitagion = (navigationData: INavigation) => {
    navigationData.expanded = true;

    setNavigations((prevNavigations) =>
      prevNavigations.map((obj) => {
        return {
          ...obj,
          items: obj.items
            ? obj.items.map((subItem, index) =>
              index === 0 && subItem.items
                ? {
                  ...subItem,
                  items: subItem.items.map((item) =>
                    item.id === navigationData.id ? navigationData : item
                  ),
                }
                : subItem
            )
            : obj.items,
        };
      })
    );
  }

  const removeNavitagion = (navigationData: INavigation) => {
    setNavigations((prevNavigations) =>
      prevNavigations.map((obj) => {
        return {
          ...obj,
          items: obj.items
            ? obj.items.map((subItem, index) =>
              index === 0 && subItem.items
                ? {
                  ...subItem,
                  items: subItem.items.filter(
                    (item) => item.id !== navigationData.id
                  ),
                }
                : subItem
            )
            : obj.items,
        };
      })
    );
    setChangePage(true);
  }

  const manageNavitagions = () => {
    let newNavigation = triggerEvent.find(s => s.isTriggered && s.callEventType === eCallEventType.MACHINE);
    if (newNavigation) {
      let navigationData = JSON.parse(JSON.stringify(newNavigation.data)) as INavigation;
      if (navigationData) {
        navigationData.defaultSettings!.machineSettings.name = newNavigation.text!;
        navigationData.settings!.machineSettings.name! = newNavigation.text!;
        navigationData.text = newNavigation.text!;

        switch (newNavigation.triggerCRUDType) {
          case eTriggerCRUDType.ADD:
          case eTriggerCRUDType.COPY:
            addNavitagion(navigationData, newNavigation!.data.machineInfo.pluginId);
            break;
          case eTriggerCRUDType.EDIT:
            editNavitagion(navigationData);
            break;
          case eTriggerCRUDType.DELETE:
            removeNavitagion(navigationData);
            break;
        }
      }
      dispatch(removeEventTrigger(newNavigation));
    }
  }

  const getUser = async () => {
    const getUser = await authService.getUser();
    setUser(getUser);
  }

  const getDropDownMenuItems = async () => {
    let arr: UserMenuItem[] = [{
      userMenuItemDataType: eUserMenuItemDataType.IMPORT,
      text: Import,
      icon: importIcon,

    }, {
      userMenuItemDataType: eUserMenuItemDataType.EXPORT,
      text: Export,
      icon: exportIcon
    }, {
      userMenuItemDataType: eUserMenuItemDataType.LOGOUT,
      text: Logout,
      icon: userLogoutIcon
    }]

    const authenciatedUser = await authService.getUser();
    if (authenciatedUser) {
      const isInternalUser = authenciatedUser.profile["http://promobid.promob.com/identity/security/claims/promobinternaluser"] === 'True';
      if (isInternalUser)
        arr.push(...[{
          text: 'PT (Interno)',
          value: 'PT'
        }, {
          text: 'EN (Interno)',
          value: 'EN'
        }, {
          text: 'ES (Interno)',
          value: 'ES'
        }]);
    }
    setDropDownMenuItems(arr);
  }

  const onItemClicked = (id: string, openFirstSetup?: boolean) => {
    navigations?.map((brands) => {
      brands?.items?.map((models) => {
        models.itemClicked = models.id === id;
        models?.items?.map((machines) => {
          machines.itemClicked = machines.id === id;
          machines?.items?.map((setups) => {
            if (openFirstSetup && setups.isSetupPage) {
              openFirstSetup = false;
              setups.itemClicked = true;
              navigate(setups.path ?? '/', { state: { pageNavigation: setups } });
            }
            else {
              setups.itemClicked = setups.id === id;
              setups?.items?.map((tools) => {
                tools.itemClicked = tools.id === id;
              })
            }
          })
        })
      })
    })
  }

  const renderCustomItem = (navigationItem: any) => {
    return <NavigationItem navigationItem={navigationItem} onItemClicked={onItemClicked} />
  }

  const onMenuItemClick = useCallback((e: DropDownButtonTypes.ItemClickEvent) => {

    const itemData = e.itemData as UserMenuItem;

    switch (itemData.userMenuItemDataType) {
      case eUserMenuItemDataType.EXPORT:
        exportSettings();
        break;
      case eUserMenuItemDataType.IMPORT:
        importSettings();
        break;
      case eUserMenuItemDataType.LOGOUT:
        logout();
        break;
      default: {
        localStorage.setItem('language', itemData.value!);
        window.location.reload();
      }
        break;
    }
  }, []);

  const renderMenuCustomItem = (navigationItem: UserMenuItem) => {
    return <div className='button-layout'>
      <img className={'button-icon'} src={navigationItem.icon} />
      <div className={'button-text'} >{navigationItem.text}</div>
    </div>
  }

  const exportSettings = async () => {
    exportSettingsAction();
  };

  const importSettings = async () => {
    setOpenImportSettingsModal(true);
    //importSettingsAction();
  };

  const logout = async () => {
    logoutAction();
  };

  const onImportSettingsOK = async (fileContent: any) => {
    importSettingsAction(fileContent);
  };

  const onNavigationChanged = (navigation: ItemClickEvent) => {
    let isExpanded = navigation.itemData?.expanded;
    if (navigation.itemData?.keepNodeStateWhenClick) {
      if (isExpanded === false) {
        navigation.component.instance().collapseItem(navigation.node?.key);
        selectedItemChanged(navigation);
        navigation.component.instance().expandItem(navigation.node?.key);
      }
      else {
        navigation.component.instance().expandItem(navigation.node?.key);
        selectedItemChanged(navigation);
        navigation.component.instance().collapseItem(navigation.node?.key);
      }
    }
    else {
      selectedItemChanged(navigation);
    }
  };

  return (
    <React.Fragment>

      <ImportSettingsModal show={openImportSettingsModal} onClose={() => setOpenImportSettingsModal(false)} onButtonOk={onImportSettingsOK} />

      <div className={'side-navigation-menu'} ref={getWrapperRef}>
        <div className='logo-layout'>
          <div className="dx-field-value">
            <img src={logo} className='main-logo' />
            <DropDownButton
              className='user-dropdown'
              text={user?.profile.name}
              items={dropDownMenuItems}
              itemRender={renderMenuCustomItem}
              wrapItemText={true}
              onItemClick={onMenuItemClick}
            />
          </div>
        </div>

        <div className={'tree-layout'}>
          <TreeView
            ref={treeViewRef}
            expandIcon={plusIcon}
            collapseIcon={minusIcon}
            items={navigations}
            keyExpr={'id'}
            selectionMode={'single'}
            focusStateEnabled={false}
            expandEvent={'click'}
            expandedExpr='expanded'
            onItemClick={onNavigationChanged}
            onContentReady={onMenuReady}
            width={'100%'}
            itemRender={renderCustomItem}
          />
        </div>
      </div>
    </React.Fragment>
  );
}