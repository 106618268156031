import React, { useEffect } from "react";
import Modal from "../base/modal/modal";
import useModalHook from "../../hooks/modal.hook";
import TextBox from "devextreme-react/text-box";
import { SecurityTranslates } from "../../translator/components/security.translate";
import { PropTypes } from "data/modal/modal.proprieties";

const { Password } = SecurityTranslates();

export default function Authorization({ show, onClose, onButtonOk }: PropTypes) {

    const { isOpen, toggle, open } = useModalHook();

    useEffect(() => {
        if (show)
            open();
    }, [show])

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} close={onClose} width="25%" height="12%" onButtonOk={onButtonOk}>
                <div className="dx-field">
                    <div className="dx-field-label">{Password}</div>
                    <TextBox mode="password" className="dx-field-value" name="authorizationPassword" />
                </div>
            </Modal>
        </React.Fragment>
    );
}