import './create.machine.modal.css';

import React, { useEffect, useState } from "react";
import TextBox from "devextreme-react/text-box";
import { PropTypes } from "data/modal/modal.proprieties";
import Modal from "components/base/modal/modal";
import useModalHook from "hooks/modal.hook";
import { CreateMachineTranslates } from 'translator/components/create.machine.translate';

const { Title, Name } = CreateMachineTranslates();

export default function CreateMachineModal({ show, onClose, onButtonOk }: PropTypes) {

    const { isOpen, toggle, open } = useModalHook();
    const [machineName, setMachineName] = useState<string>('');

    useEffect(() => {
        if (show)
            open();
    }, [show])

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} close={onClose} width="25%" height="5%" onButtonOk={() => onButtonOk(machineName)} modalHeader={Title} >
                <div className='modal-container'>
                    <div className='modal-title'>{Name}</div>
                    <TextBox mode="text" width={'100%'} name="machineName" value={machineName} onValueChange={setMachineName} />
                </div>
            </Modal>
        </React.Fragment>
    );
}