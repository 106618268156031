import React, { useEffect, useState } from 'react';
import useModalHook from '../../../hooks/modal.hook';
import Modal from '../../base/modal/modal';
import SelectBox from 'devextreme-react/cjs/select-box';
import { store, useAppDispatch } from '../../../store/store';
import { IPromobPlugin } from '../../../data/user/promobType';
import { PropTypes } from 'data/modal/modal.proprieties';
import { ISettings, ISettingsInput } from '../../../data/settings/settingsData';
import { editSettings } from '../../../store/settings.store';
import { PagesTranslates } from 'translator/components/pages.translate';
import { UtilsSystem } from 'utils/utils.system';
import { ManufactureUser } from 'data/user/userType';
import './page.options.component.scss';

const { GeneralOptions } = PagesTranslates();

interface SystemProps {
    id: string;
    name: string;
}

interface OptionsProps {
    availableSystems: SystemProps[];
    selectedSystemID: string;
}

export default function PageOptions({ onClose }: PropTypes) {
    const dispatch = useAppDispatch();

    const globalState = store.getState();
    const user: ManufactureUser = globalState.userDataState.user;
    const promobPlugins: IPromobPlugin[] = user.promobPlugins;
    const settings: ISettings = globalState.settingsDataState?.settings![0].settingsToUpdate![0] as ISettings;

    const [options, setOptions] = useState<OptionsProps>({
        availableSystems: [],
        selectedSystemID: ''
    });

    const { isOpen, toggle, open } = useModalHook();

    useEffect(() => {
        setOptions({
            availableSystems: UtilsSystem.getPromob5Systems(promobPlugins),
            selectedSystemID:
                settings.additionalSettings!["SystemID"] === undefined && !user.isInternalUser ? '' :
                    settings.additionalSettings!["SystemID"] === undefined && user.isInternalUser ? UtilsSystem.getStartLabsSystemID :
                        settings.additionalSettings!["SystemID"].toString()
        });
        open();
    }, []);

    const handleOptionChange = (value: string) => {
        setOptions({ availableSystems: options.availableSystems, selectedSystemID: value });
    };

    const onOptionsOk = () => {
        const selectedSystemID = options.selectedSystemID;
        const storedSettings = store.getState().settingsDataState.settings ?? [];

        storedSettings.forEach(SS => {
            const settingsInput: ISettingsInput = {
                machineInfo: SS.machineInfo,
                settingsToUpdate: [],
                settingsToDelete: SS.settingsToDelete
            }

            SS.settingsToUpdate?.forEach(SU => {
                const settingsToUpdate = JSON.parse(JSON.stringify(SU));
                settingsToUpdate.additionalSettings!["SystemID"] = selectedSystemID;

                settingsInput.settingsToUpdate?.push(settingsToUpdate);
            });

            dispatch(editSettings(settingsInput));
        })
    }

    return (
        <React.Fragment>
            <Modal modalHeader={GeneralOptions} isOpen={isOpen} toggle={toggle} close={onClose} width="25%" height="12%" onButtonOk={onOptionsOk}>
                <label className='toolbar-label'>Biblioteca</label>
                <SelectBox
                    className='dx-selectbox'
                    dataSource={options.availableSystems}
                    valueExpr="id"
                    displayExpr="name"
                    width='100%'
                    onValueChange={handleOptionChange}
                    value={options.selectedSystemID}
                />
            </Modal>
        </React.Fragment>
    );
}
