import React, { useState, useEffect, createContext, useContext } from 'react';
import { apiGetUserData } from '../api/auth';
import type { AuthContextType } from '../types';
import { ManufactureUser } from '../data/user/userType';
import { store, useAppDispatch } from '../store/store';
import { addUser } from '../store/user.store';
import { ISettings, ISettingsInput, ISettingsOutput } from '../data/settings/settingsData';
import { addSettings } from '../store/settings.store';
import authService from 'cyncly-login/authService';
import { LocalStorageHelper } from 'helpers/localStorageHelper';
import { useLogoutAction } from 'components/actions/logout.action';
import { useNavigate } from 'react-router-dom';
import { getPromobAccountData } from 'api/promob.request';
import { addLogedAccountInfo, PromobAccount } from 'store/loged.user.store';
import { Utils } from 'utils/utils';

function AuthProvider(props: React.PropsWithChildren<unknown>) {

  const dispatch = useAppDispatch();
  const [user, setUser] = useState<ManufactureUser>();
  const [userName, setUserName] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [selectUser, setSelectUser] = useState(false);
  const logoutAction = useLogoutAction();
  const navigate = useNavigate();
  const storeState = store.getState();

  useEffect(() => {
    getUserData();
  }, [storeState.logedAccountInfoState.accountInfo]);

  const hasLogedUser = () => {
    return Object.keys(storeState.logedAccountInfoState.accountInfo).length !== 0;
  }

  async function getUserData() {

    const localStorage = LocalStorageHelper.getLocalStorage();
    const authenciatedUser = await authService.getUser();

    //verifica mutiplas contas no mesmo login
    if (authenciatedUser?.profile) {
      const profiles = authenciatedUser?.profile["http://promobid.promob.com/identity/security/claims/accountid"] as any[];
      if (Array.isArray(profiles) && profiles.length > 1 && !hasLogedUser() && localStorage === undefined) {
        setLoading(false);
        setSelectUser(true);
        return;
      }
    }

    if (!selectUser) {
      setLoading(true);
      if (authenciatedUser == null && localStorage) {
        LocalStorageHelper.clearLocalStorage();
        setLoading(false);
        navigate('/login');
      }
      else {
        if (authenciatedUser?.expired) {
          logoutAction();
        }
        else {
          setUserName(authenciatedUser?.profile.name);

          if (authenciatedUser && localStorage === undefined) {
            if (!hasLogedUser()) {
              const data = await getPromobAccountData<PromobAccount>(authenciatedUser.access_token, authenciatedUser.profile["accountID"] as string);
              if (data && data.active) {
                dispatch(addLogedAccountInfo({
                  accountId: data.accountId,
                  name: data.name,
                  active: data.active
                }));
              }
            }

            const validateResult = await apiGetUserData(authenciatedUser.profile["accountID"] as string);
            if (validateResult?.data) {

              const userData = validateResult?.data as ManufactureUser;
              userData.settings = updateDefaultSettings(userData.settings);

              setuserData(userData);
            }
            else {
              LocalStorageHelper.clearLocalStorage();
            }
          } else if (localStorage !== undefined) {
            setuserData(localStorage);
          }
        }

        setLoading(false);
      }
    }
  };

  const setuserData = (userData: ManufactureUser) => {
    dispatch(addUser(userData));
    getSettings(userData.settings).forEach(S => dispatch(addSettings(S)));
    LocalStorageHelper.setLocalStorage(userData);
    setUser(userData);
  }

  function updateDefaultSettings(settings: ISettingsOutput[]): ISettingsOutput[] {
    settings.forEach(S => {
      if (S.settings.length === 0 && S.defaultSettings !== null) {
        const newSettings = JSON.parse(JSON.stringify(S.defaultSettings)) as ISettings;
        newSettings.id = Utils.generateGUID();

        S.settings.push(newSettings);
      }
    });
    return settings;
  }

  function getSettings(settings: ISettingsOutput[]): ISettingsInput[] {
    const settingsInput: ISettingsInput[] = [];

    settings.forEach(S => {
      settingsInput.push({ machineInfo: S.machineInfo, settingsToUpdate: S.settings });
    });

    return settingsInput;
  }

  return (
    <AuthContext.Provider value={{ user, loading, userName, selectUser, setSelectUser }} {...props} />
  );
}

const AuthContext = createContext<AuthContextType>({ loading: false } as AuthContextType);
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }