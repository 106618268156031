import { getMachineInfoSettings, postSaveUserSettings } from "api/manufacture";
import { useLoader } from "contexts/loader.context";
import { useNotification } from "contexts/notification.context";
import { APIResponseType } from "data/apiResponse/api.response.type";
import { ISettingsOutput } from "data/settings/settingsData";
import { LocalStorageHelper } from "helpers/localStorageHelper";
import { store, useAppDispatch } from "store/store";
import { editUser } from "store/user.store";
import { SaveTranslates } from "translator/components/save.translate";
import { useLogoutAction } from "./logout.action";

const { SaveSuccess, SaveError, SaveExpiredToken, SavingData } = SaveTranslates();

export default function useSaveAction() {

    const { setLoaderActive } = useLoader();
    const { setNotificationConfig } = useNotification();
    const dispatch = useAppDispatch();
    const logoutAction = useLogoutAction();

    const saveAction = async () => {
        setLoaderActive(true);
        setNotificationConfig({
            isVisible: true,
            message: SavingData,
            type: 'info'
        });

        const getUser = store.getState().userDataState.user;
        const storedSettings = store.getState().settingsDataState.settings;

        const result = await postSaveUserSettings(storedSettings) as APIResponseType;

        if (result === APIResponseType.OK) {
            let localUser = LocalStorageHelper.getLocalStorage();
            if (localUser) {
                const getSavedSettings = await getMachineInfoSettings(getUser.accessToken, getUser.accountID, localUser.machineInfos) as ISettingsOutput[];
                localUser.settings = getSavedSettings;
                const editedUser = {
                    ...getUser,
                    settings: getSavedSettings,
                };
                dispatch(editUser(editedUser));
                LocalStorageHelper.setLocalStorage(localUser);
            }
        }

        setLoaderActive(false);
        setNotificationConfig({
            isVisible: true,
            message: result === APIResponseType.OK ? SaveSuccess : result === APIResponseType.ERROR ? SaveError : SaveExpiredToken,
            type: result === APIResponseType.OK ? 'success' : 'error'
        });

        if (result === APIResponseType.EXPIRED_TOKEN)
            setTimeout(() => {
                logoutAction();
            }, 5000);
    };

    return saveAction;
};