import React, { ReactNode } from "react";
import crossIcon from '../../../assets/images/icons/cross.svg';
import './modal.scss'

//Importante esse ZIndex = é comum os dropdown não aparecerem por causa do index padrão
import { baseZIndex } from 'devextreme/ui/overlay';
baseZIndex(9999);

interface ModalType {
  children?: ReactNode;
  isOpen: boolean;
  toggle: () => void;
  close: () => void;
  onButtonOk: (returnValues?: any) => void;
  width?: string;
  height?: string;
  customButtonText?: string;
  customButtonEvent?: () => void;
  modalDataState?: any;
  modalHeader?: string;
  disableOkButton?: boolean;
}

export default class Modal extends React.Component<ModalType> {

  handleOnCloseModal = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    this.closeModal();
  };

  style = {
    "--width": this.props.width ?? "50%",
    "--height": this.props.height ?? "50%",
    "minWidth": this.props.customButtonText != undefined ? '450px' : '350px',
    "minHeight": this.props.customButtonText != undefined ? '360px' : '320px'
  } as React.CSSProperties;

  onOk = () => {
    this.props.onButtonOk(this.props.modalDataState);
    this.closeModal();
  }

  onCancel = () => {
    this.closeModal();
  }

  closeModal = () => {
    this.props.toggle();
    this.props.close();
  }

  render() {
    return (<>
      {
        this.props.isOpen && (
          <div className="modal-overlay" onClick={this.handleOnCloseModal}>
            <div onClick={(e) => e.stopPropagation()} className="modal-box" style={this.style}>
              <div className="modal-header-text">{this.props.modalHeader}</div>
              <div className="modal-close-button">
                <button onClick={this.closeModal}>
                  <img src={crossIcon} />
                </button>
              </div>
              {this.props.children}
              {this.props.customButtonText && (<div style={{ float: 'left', padding: '15px 0 0 0' }}>
                <button
                  className="modal-button-custom"
                  type="button"
                  onClick={this.props.customButtonEvent}>
                  <div className="modal-button-text-layout modal-button-text-ok">{this.props.customButtonText}</div>
                </button>
              </div>)}
              <div className='modal-footer'>
                <button
                  className="modal-button"
                  onClick={this.onCancel}>
                  <div className="modal-button-text-layout modal-button-text">Cancelar</div>
                </button>
                <button
                  className="modal-button-ok"
                  disabled={this.props.disableOkButton}
                  onClick={this.onOk}>
                  <div className="modal-button-text-layout modal-button-text-ok">OK</div>
                </button>
              </div>
            </div>
          </div >
        )
      }
    </>
    );
  }
}
