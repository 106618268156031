import { ConditionDTO } from "components/editor/editor.interfaces"
import { IMachineInfoModel } from "../machine/machineData"
import { IDrill, IMill, ISaw } from "../tools/toolData"
import { IWorkingArea } from "../workingArea/workingArea.data"

//----
//DISCLAIMER: We work with the exception of elements HERE (in React); which means:
//- If a property from SettingsDTO is not used anywhere, we'll ommit it;
//----

export interface ISettings {
    id: string,
    labelSettingsId?: string,
    labelPrinterSettingsId?: string,
    name?: string,
    isEnabled?: boolean,
    machineInfoId?: string,
    exporterSettings?: IExporterSettings,
    machineSettings: IMachineSettings,
    toolSettings?: IToolSettings,
    additionalSettings?: { [key: string]: object },
    slotSettings?: any,
    machiningSettings?: any
    insertionSettings?: any
}

export interface IExporterSettings {
    columnsSettings?: IExporterColumn[],
}

export interface IExporterColumn {
    id: string,
    header: string,
    name: string,
    description: string,
    format: string,
    separator: string,
    prefix: string,
    suffix: string,
    groupedColumns?: IExporterColumn[]
}

export enum eReferencePoint {
    None = "None",
    BottomLeft = "BottomLeft",
    BottomRight = "BottomRight",
    TopLeft = "TopLeft",
    TopRight = "TopRight",
    Left = "Left",
    Bottom = "Bottom",
    Right = "Right",
    Top = "Top"
}

export interface IMachineSettings {
    id: string
    name: string
    machineLength: number
    machineWidth: number
    additionalInfo?: { [key: string]: object }
    prioritizationOrder?: string[]
    operationsStepsOrder?: IOperationStep[]
    workingAreas?: IWorkingArea[]
    slotDisabledProcesses?: string[]
    insertionDisabledProcesses?: string[]
    machiningDisabledProcesses?: string[]
}

export interface IOperationStep {
    processID: string
    section: string
}

export interface IToolSettings {
    toolIDByProcessID?: { [key: string]: object }
    toolIDByConditionID?: { [key: string]: object }
    selectedToolOrProcessIDByOperationType?: { [key: string]: object }
    conditions?: ConditionDTO[]
    drills?: IDrill[]
    mills?: IMill[]
    saws?: ISaw[]
}

export interface ISettingsOutput {
    machineInfo: IMachineInfoModel,
    defaultSettings: ISettings,
    settings: ISettings[]
}

export interface ISettingsInput {
    machineInfo: IMachineInfoModel,
    settingsToUpdate?: ISettings[],
    settingsToDelete?: ISettings[]
}

export interface ISaveSettingsParamsModel {
    settingsInputs: ISettingsInput[],
    accessToken: string,
    accountID: number
}

export interface IGetParamsModel {
    accessToken: string,
    accountID: number
}

export interface IGetSettingsParamsModel {
    machineInfos: IMachineInfoModel[],
    accessToken: string,
    accountID: number
}

export interface IGetprocessesParamsModel {
    accessToken: string,
    accountID: number,
    systemID: string
}