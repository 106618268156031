import { IMachineSettings, IOperationStep } from "../../data/settings/settingsData";
import { IHandler } from "../handlerManager";

export class OperationsOrderHandler implements IHandler {
    canHandle(bind: string): boolean {
        return bind === "SettingsDTO.MachineSettings.OperationsStepsOrder";
    }

    handleTo(ogObject: object, object: object, value: any, bind: string) {
        const machineSettings = object as IMachineSettings;
        if (machineSettings !== undefined) {
            if (machineSettings!.operationsStepsOrder?.length !== 0) {
                return machineSettings!.operationsStepsOrder;
            }
        }

        return value;
    }

    handleBack(ogObject: object, object: object, value: any, bind: string) {
        const machineSettings = object as IMachineSettings;
        if (machineSettings !== undefined) {
            const operationSteps: IOperationStep[] = value.map((newValue: any) => {
                return {
                    section: newValue
                };
            }).filter((step: IOperationStep | null): step is IOperationStep => step !== null);

            machineSettings.operationsStepsOrder = operationSteps;
        }

        return value;
    }
}
