import React, { useEffect, useState } from "react";
import Authorization from "../security/authorization";
import TextBox from "devextreme-react/text-box";
import { NativeEventInfo } from "devextreme/events";
import { TextBoxInstance } from "devextreme/ui/text_box";
import PropertyCheckbox from "../properties/property.checkbox";
import PropertySelect from "../properties/property.select";

interface PropertyTextState {
    textOption: string;
}

export default function NestingToolbarInput({ field, onChange }: any) {

    const [state, setState] = useState<PropertyTextState>({
        textOption: field.value
    });

    useEffect(() => {
        setState({ textOption: field.value });
    }, [field]);

    const handleTextChange = (event: any) => {
        setState({ textOption: event.target.value });
        onChange(field.id, event.target.value);
    };

    const getFieldType = () => {
        switch (field.type) {
            case 'Bool':
                return <PropertyCheckbox field={field} onChange={onChange} />;

            case 'Enum':
                return <PropertySelect field={field} onChange={onChange} />

            case 'Text':
            default:
                return <div className="nesting-input-field">
                    <input className="nesting-default-text" hidden={field.isVisible !== undefined ? !!field.isVisible : true} name={field.id} value={state.textOption} type="text" maxLength={3} onChange={handleTextChange} />
                </div>
        }
    }

    return (
        <>
            {getFieldType()}
        </>
    )
}