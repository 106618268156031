import React, { useEffect, useState } from "react";
import { editorTypeEnum } from "../../data/editor/editorType";
import OperationsOrder from "./operationsOrder";
import PrioritizationOrder from "./prioritizationOrder";
import SelectedInsertionProcesses from "./selectedInsertionProcesses";
import SelectedSlotProcesses from "./selectedSlotProcesses";
import SelectedMachiningProcesses from "./selectedMachiningProcesses";
import SelectedSlotTools from "./selectedSlotTools";
import SelectedMachiningTools from "./selectedMachiningTools";
import SelectedSlotToolsByProcess from "./selectedSlotToolsByProcess";
import SelectedMachiningToolsByProcess from "./selectedMachiningToolsByProcess";
import SelectSolidFace from "./selectSolidFace";
import ZeroPointByFace from "./zeroPointByFace";
import SelectedOrtogonalMachiningTools from "./selectedOrtogonalMachiningTools";
import SelectedTools from "./selectedTools";
import ToolByConditionEditRules from "./toolByConditionEditRules";
import ToolByConditionRule from "./toolByConditionRule";
import LabelSettingsSelected from "./labelSettingsSelected";
import FullLabelSettingsSelected from "./fullLabelSettingsSelected";
import { PropTypes } from "data/modal/modal.proprieties";

export default function Editor({ show, data, currentSettings, formState, field, onClose, onButtonOk, editorName }: PropTypes) {

    const [editor, setEditor] = useState<editorTypeEnum>(editorTypeEnum.Undefined);

    useEffect(() => {
        let eName = editorName ?? "";
        let editorType = editorTypeEnum[eName as keyof typeof editorTypeEnum]
        setEditor(editorType);
    }, [show])

    const renderSwitch = () => {
        document.body.style.overflow = "hidden !important";
        switch (editor) {
            case editorTypeEnum.OperationsOrder:
                return <OperationsOrder show={show} data={data} currentSettings={currentSettings} formState={formState} field={field} onButtonOk={onButtonOk} onClose={onClose} />;
            case editorTypeEnum.PrioritizationOrder:
                return <PrioritizationOrder show={show} data={data} currentSettings={currentSettings} formState={formState} field={field} onButtonOk={onButtonOk} onClose={onClose} />;
            case editorTypeEnum.SelectInsertionProcesses:
                return <SelectedInsertionProcesses show={show} data={data.value} currentSettings={currentSettings} onButtonOk={onButtonOk} onClose={onClose} />;
            case editorTypeEnum.SelectSlotProcesses:
                return <SelectedSlotProcesses show={show} data={data.value} currentSettings={currentSettings} onButtonOk={onButtonOk} onClose={onClose} />;
            case editorTypeEnum.SelectMachiningProcesses:
                return <SelectedMachiningProcesses show={show} data={data.value} currentSettings={currentSettings} onButtonOk={onButtonOk} onClose={onClose} />;
            case editorTypeEnum.SelectSlotTools:
                return <SelectedSlotTools show={show} data={data.value} currentSettings={currentSettings} onButtonOk={onButtonOk} onClose={onClose} />;
            case editorTypeEnum.SelectMachiningTools:
                return <SelectedMachiningTools show={show} data={data.value} currentSettings={currentSettings} onButtonOk={onButtonOk} onClose={onClose} />;
            case editorTypeEnum.SelectOrtogonalMachiningTools:
                return <SelectedOrtogonalMachiningTools show={show} data={data.value} currentSettings={currentSettings} onButtonOk={onButtonOk} onClose={onClose} />;
            case editorTypeEnum.SelectSlotToolsByProcesses:
                return <SelectedSlotToolsByProcess show={show} data={data.value} currentSettings={currentSettings} onButtonOk={onButtonOk} onClose={onClose} />;
            case editorTypeEnum.SelectMachiningToolsByProcesses:
                return <SelectedMachiningToolsByProcess show={show} data={data.value} currentSettings={currentSettings} onButtonOk={onButtonOk} onClose={onClose} />;
            case editorTypeEnum.ToolsByConditionRules:
                return <ToolByConditionRule show={show} data={data.value} currentSettings={currentSettings} onButtonOk={onButtonOk} onClose={onClose} />;
            case editorTypeEnum.SelectSolidFaces:
                return <SelectSolidFace show={show} data={data.value} currentSettings={currentSettings} onButtonOk={onButtonOk} onClose={onClose} />;
            case editorTypeEnum.ZeroPoint:
                return <ZeroPointByFace show={show} data={data.value} currentSettings={currentSettings} onButtonOk={onButtonOk} onClose={onClose} />;
            case editorTypeEnum.ZeroPointByFace:
                return <ZeroPointByFace show={show} data={data.value} currentSettings={currentSettings} onButtonOk={onButtonOk} onClose={onClose} />;
            case editorTypeEnum.SelectTools:
                return <SelectedTools data={data} show={show} currentSettings={currentSettings} onButtonOk={onButtonOk} onClose={onClose} selectedTools={[]} />;
            case editorTypeEnum.ToolByConditionEditRules:
                return <ToolByConditionEditRules data={data} show={show} currentSettings={currentSettings} onButtonOk={onButtonOk} onClose={onClose} />;
            case editorTypeEnum.LabelSettingsSelectedEditor:
                return <LabelSettingsSelected data={data} show={show} currentSettings={currentSettings} onButtonOk={onButtonOk} onClose={onClose} />;
            case editorTypeEnum.FullLabelSettingsSelectedEditor:
                return <FullLabelSettingsSelected data={data} show={show} currentSettings={currentSettings} onButtonOk={onButtonOk} onClose={onClose} />;
        }
    }

    return (
        <React.Fragment>
            {renderSwitch()}
        </React.Fragment>
    );
}