import { useEffect, useRef, useState } from "react";
import SelectBox from "devextreme-react/select-box";
import { FieldEnum } from "./property.data";
import React from "react";
import {
    Validator,
    RequiredRule,
    ValidatorRef
} from 'devextreme-react/validator';
import { addValidation, removeValidation } from 'store/validation.store';
import { useAppDispatch } from "store/store";

interface PropertySelectState {
    selectedOption: string;
}

interface PropertySelectItems {
    display: string;
    value: string;
}

export default function PropertySelect({ field, onChange, disableForm }: any) {

    const [state, setState] = useState<PropertySelectState>({
        selectedOption: field.value
    });
    const dispatch = useAppDispatch();
    const validatorRef = useRef<ValidatorRef>(null);

    useEffect(() => {
        setState({ selectedOption: field.value });
        if (field.required)
            checkValidation();
    }, [field]);

    const handleOptionChange = (value: string) => {
        setState({ selectedOption: value });
        onChange(field.id, value);
    };

    function getItems(): PropertySelectItems[] {
        return field.constants.map((element: FieldEnum, index: number) => {
            return { display: element.localizedName, value: element.value }
        })
    }

    const checkValidation = () => {
        const validator = validatorRef.current && validatorRef.current.instance();
        if (validator) {
            const validation = validatorRef.current.instance().validate();
            if (!validation.isValid && field.isVisible)
                dispatch(addValidation({ field: field, isValid: false }))
            else
                dispatch(removeValidation(field))
        }
    }

    return (
        <React.Fragment >
            <div hidden={field.isVisible != undefined ? !field.isVisible : false} className='properties-value-layout'>
                <SelectBox disabled={disableForm} items={getItems()} displayExpr="display" valueExpr="value" onValueChange={handleOptionChange} width='100%'
                    name={field.id} value={state.selectedOption || getItems()[0].value} >
                    <Validator ref={validatorRef} >
                        {field.required && <RequiredRule type='required' message="" />}
                    </Validator>
                </SelectBox>
            </div>
        </React.Fragment>
    )
}