import { eSawOrientation } from "../data/tools/toolData";
import { Vector2 } from "../data/utils/vectorsData";
import { IConverter } from "./converterManager";

export class SawOrientationConverter implements IConverter {
    private readonly _bothOriention: Vector2 = new Vector2(1, 1);
    private readonly _horizontalOrientation: Vector2 = new Vector2(1, 0);
    private readonly _verticalOrientation: Vector2 = new Vector2(0, 1);

    canConvert(bind: string): boolean {
        if (bind === "SettingsDTO.ToolSettings.Saws.Orientation")
            return true;

        return false;
    }

    convertTo(value: any) {
        if (value === undefined || value === '') return eSawOrientation.Both;

        const orientation = JSON.parse(JSON.stringify(value)) as Vector2;

        if (Vector2.sameValues(orientation, this._bothOriention))
            return eSawOrientation.Both;

        if (Vector2.sameValues(orientation, this._horizontalOrientation))
            return eSawOrientation.Horizontal;

        if (Vector2.sameValues(orientation, this._verticalOrientation))
            return eSawOrientation.Vertical;

        return eSawOrientation.Both;
    }

    convertBack(value: any) {
        if (value === eSawOrientation.Both)
            return this._bothOriention;

        if (value === eSawOrientation.Horizontal)
            return this._horizontalOrientation;

        if (value === eSawOrientation.Vertical)
            return this._verticalOrientation;

        return this._bothOriention;
    }
}