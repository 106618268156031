import { LocalStorageHelper } from "helpers/localStorageHelper";
import { removeLogedAccountInfo } from "store/loged.user.store";
import { useAppDispatch } from "store/store";
import { removeUser } from "store/user.store";

export const useCacheAction = () => {

    const dispatch = useAppDispatch();

    const clearCacheAction = async () => {
        dispatch(removeLogedAccountInfo());
        dispatch(removeUser());
        LocalStorageHelper.clearLocalStorage();
    };

    return clearCacheAction;
};