import { INavigation } from "../data/navigation/navigationType";
import { ISettings, ISettingsInput } from "../data/settings/settingsData";
import { store } from "../store/store";

class SettingsHelper {
    getStoredSettings = (settings: ISettings, navigation: INavigation) => {
        const globalState = store.getState();
        const getUser = globalState.userDataState.user;
        const getSettings = globalState.settingsDataState.settings;

        const matchingMachineSettings = getSettings.find(S => S.machineInfo.pluginId === navigation.machineInfo?.pluginId);
        let matchingSettings = matchingMachineSettings?.settingsToUpdate?.find(S => S.id === navigation.settings?.id);

        if (matchingSettings === undefined) {
            const matchingUserMachineSettings = getUser.settings.find(S => S.machineInfo.pluginId === navigation.machineInfo?.pluginId);

            matchingSettings = matchingUserMachineSettings?.settings.find(S => S.id == navigation.settings?.id);

            if (matchingSettings === undefined)
                matchingSettings = matchingUserMachineSettings?.defaultSettings;
        }

        const settingsInput: ISettingsInput = {
            machineInfo: navigation.machineInfo!,
            settingsToUpdate: [],
            settingsToDelete: matchingMachineSettings?.settingsToDelete !== undefined ? matchingMachineSettings.settingsToDelete : []
        }

        if (matchingMachineSettings !== undefined) {
            const currentSettings = settings !== undefined ? settings : matchingSettings;

            const validSettings = matchingMachineSettings.settingsToUpdate?.filter(F => F.id !== currentSettings?.id)
            validSettings?.forEach(S => settingsInput.settingsToUpdate?.push(S));
            settingsInput.settingsToUpdate?.push(currentSettings!);

            return [settingsInput, currentSettings] as const;
        }
        else {
            settingsInput.settingsToUpdate?.push(matchingSettings!);

            return [settingsInput, matchingSettings] as const;
        }
    }
}

export default SettingsHelper;