import { INavigation } from "./data/navigation/navigationType";
import { store } from "./store/store";
import { IMachine, IMachineInfoModel } from "./data/machine/machineData";
import { IMachineTemplate } from "./data/machine/templateData";
import { machineSection } from "./pages/machine/machine";
import { setupSection } from "./pages/setup/setup";
import { nestingSection } from "./pages/nesting/nesting";
import { drillSection } from "./pages/tools/tools.drill";
import { millSection } from "./pages/tools/tools.mill";
import { sawSection } from "./pages/tools/tools.saw";
import { workingAreaSection } from "./pages/workingArea/workingArea";
import { ISettings } from "./data/settings/settingsData";
import machineIcon from './assets/images/icons/machine.svg';
import workingAreaIcon from './assets/images/icons/border all.svg';
import drillsIcon from './assets/images/icons/drills.svg';
import fileIcon from './assets/images/icons/file.svg';
import millsIcon from './assets/images/icons/mills.svg';
import settingsIcon from './assets/images/icons/settings.svg';
import toolsIcon from './assets/images/icons/tools.svg';
import hoverMachineIcon from './assets/images/icons/machine_blue.svg';
import hoverWorkingAreaIcon from './assets/images/icons/border all_blue.svg';
import hoverDrillsIcon from './assets/images/icons/drills_blue.svg';
import hoverFileIcon from './assets/images/icons/file_blue.svg';
import hoverMillsIcon from './assets/images/icons/mills_blue.svg';
import hoverSettingsIcon from './assets/images/icons/settings_blue.svg';
import hoverToolsIcon from './assets/images/icons/tools_blue.svg';
import selectedMachineIcon from './assets/images/icons/machine_purple.svg';
import selectedWorkingAreaIcon from './assets/images/icons/border all_purple.svg';
import selectedDrillsIcon from './assets/images/icons/drills_purple.svg';
import selectedFileIcon from './assets/images/icons/file_purple.svg';
import selectedMillsIcon from './assets/images/icons/mills_purple.svg';
import selectedSettingsIcon from './assets/images/icons/settings_purple.svg';
import selectedToolsIcon from './assets/images/icons/tools_purple.svg';
import { Utils } from "utils/utils";
import { NavigationTranslates } from "translator/components/navigation.translate";

const { Setups, Nesting, Tools, Drills, Mills, Saws, WorkingAreas } = NavigationTranslates();

export function navigation() {

  const state = store.getState();
  const getUser = state.userDataState.user;
  const getSettings = state.settingsDataState;

  let navigations: INavigation[] = [];
  if (getUser?.machineInfos) {
    let sortList = [...getUser?.machineInfos].sort((a, b) => a.manufacturer.localeCompare(b.manufacturer));
    let groupedMachines = groupedMachinesKeys(sortList);
    let templates = getUser.templates;
    let originalUserSettings = getUser.settings;
    let storedSettings = getSettings.settings;

    for (let key in groupedMachines) {
      let navigationKey: INavigation =
      {
        id: Utils.generateGUID(),
        text: key,
        items: [],
        expanded: true,
        isHeadLine: true
      };

      for (let machine of groupedMachines[key]) {
        let machineOriginalSettings = originalUserSettings.find(x => x.machineInfo.pluginId == machine.pluginId);
        let machineStoredSettings = storedSettings.find(x => x.machineInfo.pluginId == machine.pluginId);
        let alreadyAddedMachinesHierarchy: string[] = [];
        let machineTemplate = templates.find(x => x.machineInfo.pluginId == machine.pluginId);

        let navigationItem: INavigation =
        {
          id: Utils.generateGUID(),
          text: machine.model,
          items: [],
          expanded: true,
          machineInfo: machine
        };

        let manufacturerLogo = machine.assets.find(x => x.name == "ManufacturerLogo")?.content;
        if (manufacturerLogo !== undefined) {
          const imageUrl = `data:image/png;base64,${manufacturerLogo}`;

          navigationItem.icon = imageUrl;
          navigationItem.hoverIcon = imageUrl;
          navigationItem.selectedIcon = imageUrl;
        }

        if (machineStoredSettings?.settingsToUpdate?.length === 0) {
          navigationItem.items?.push(...createNavigationPages(machine, machineTemplate?.templates, machineOriginalSettings?.defaultSettings, machineOriginalSettings?.defaultSettings));
        }
        else {
          machineStoredSettings?.settingsToUpdate?.slice()
            .sort((a, b) => a.machineSettings.name.localeCompare(b.machineSettings.name)).forEach(s => {
              if (!alreadyAddedMachinesHierarchy.includes(s.machineSettings.id)) {
                navigationItem.items?.push(...createNavigationPages(machine, machineTemplate?.templates, s ?? machineOriginalSettings?.defaultSettings, machineOriginalSettings?.defaultSettings));

                alreadyAddedMachinesHierarchy.push(s.machineSettings.id);
              }
            });
        }

        navigationKey.items?.push(navigationItem);
      }

      if (navigationKey.items) {
        navigationKey.items = [...navigationKey.items].sort((a, b) => {
          return a.text.localeCompare(b.text, undefined, { numeric: true, sensitivity: "base" });
        });
      }

      navigations.push(navigationKey);
    }
  }
  return navigations;
}

const groupedMachinesKeys = (list: IMachineInfoModel[]) => {
  let result = list.reduce((group: { [key: string]: IMachineInfoModel[] }, item) => {
    if (!group[item.manufacturer]) {
      group[item.manufacturer] = [];
    }
    group[item.manufacturer].push(item);
    return group;
  }, {});

  return result;
}

export const createNavigationPages = (machineInfo: IMachineInfoModel, templates?: IMachineTemplate[], settings?: ISettings, defaultSettings?: ISettings): INavigation[] => {
  const getSettings = settings ?? defaultSettings;

  let machinePgs: INavigation = {
    id: Utils.generateGUID(),
    icon: machineIcon,
    hoverIcon: hoverMachineIcon,
    selectedIcon: selectedMachineIcon,
    itemClicked: false,
    text: `${getSettings?.machineSettings?.name}`,
    path: `${getMachineSettingsRoute(machineInfo, getSettings)}`,
    items: [],
    sectionTemplate: templates?.find(x => x.pageSection == machineSection),
    machineInfo: machineInfo,
    settings: getSettings,
    expanded: true,
    defaultSettings: defaultSettings,
    keepNodeStateWhenClick: true,
    isTempNavigation: false
  };

  if (machineInfo.hasWorkingAreas)
    machinePgs.items?.push(defaultWorkingAreaPages(machineInfo, templates, settings));

  if (machineInfo.isNesting)
    machinePgs.items?.push(defaultNestingPages(machineInfo, templates, settings));

  machinePgs.items?.push(defaultSetupPages(machineInfo, templates, settings));

  if (machineInfo.hasDrills || machineInfo.hasSaws || machineInfo.hasMills || machineInfo.hasConicalMills)
    machinePgs.items?.push(defaultToolsPages(machineInfo, templates, settings));

  machinePgs.items = machinePgs.items?.sort((a, b) => a.text.localeCompare(b.text));
  let navigationPgs: INavigation[] = [{ ...machinePgs }];

  return navigationPgs.sort((a, b) => a.text.localeCompare(b.text));
}

const defaultSetupPages = (machineInfo: IMachineInfoModel, templates?: IMachineTemplate[], settings?: ISettings, defaultSettings?: ISettings): INavigation => {
  const getSettings = settings ?? defaultSettings;
  return {
    id: Utils.generateGUID(),
    text: Setups,
    icon: settingsIcon,
    hoverIcon: hoverSettingsIcon,
    selectedIcon: selectedSettingsIcon,
    itemClicked: false,
    path: `${getMachineSettingsRoute(machineInfo, getSettings)}/setups`,
    sectionTemplate: templates?.find(x => x.pageSection == setupSection),
    machineInfo: machineInfo,
    settings: getSettings,
    defaultSettings: defaultSettings,
    isSetupPage: true
  }
}

const defaultNestingPages = (machineInfo: IMachineInfoModel, templates?: IMachineTemplate[], settings?: ISettings, defaultSettings?: ISettings): INavigation => {
  const getSettings = settings ?? defaultSettings;
  return {
    id: Utils.generateGUID(),
    text: Nesting,
    icon: fileIcon,
    hoverIcon: hoverFileIcon,
    selectedIcon: selectedFileIcon,
    itemClicked: false,
    path: `${getMachineSettingsRoute(machineInfo, getSettings)}/nesting`,
    sectionTemplate: templates?.find(x => x.pageSection == nestingSection),
    machineInfo: machineInfo,
    settings: getSettings,
    defaultSettings: defaultSettings
  }
}

const defaultToolsPages = (machineInfo: IMachineInfoModel, templates?: IMachineTemplate[], settings?: ISettings, defaultSettings?: ISettings): INavigation => {

  let machineRoute = getMachineSettingsRoute(machineInfo, settings);
  const getSettings = settings ?? defaultSettings;

  let defaultTools: INavigation = {
    id: Utils.generateGUID(),
    text: Tools,
    icon: toolsIcon,
    hoverIcon: hoverToolsIcon,
    selectedIcon: selectedToolsIcon,
    itemClicked: false,
    items: []
  };

  if (machineInfo.hasDrills)
    defaultTools.items?.push({
      id: Utils.generateGUID(),
      text: Drills,
      path: `${machineRoute}/tools/drills`,
      icon: drillsIcon,
      hoverIcon: hoverDrillsIcon,
      selectedIcon: selectedDrillsIcon,
      itemClicked: false,
      sectionTemplate: templates?.find(x => x.pageSection == drillSection),
      machineInfo: machineInfo,
      settings: getSettings,
      defaultSettings: defaultSettings
    });

  if (machineInfo.hasMills || machineInfo.hasConicalMills)
    defaultTools.items?.push({
      id: Utils.generateGUID(),
      text: Mills,
      icon: millsIcon,
      hoverIcon: hoverMillsIcon,
      selectedIcon: selectedMillsIcon,
      itemClicked: false,
      path: `${machineRoute}/tools/mills`,
      sectionTemplate: templates?.find(x => x.pageSection == millSection),
      machineInfo: machineInfo,
      settings: getSettings,
      defaultSettings: defaultSettings
    });

  if (machineInfo.hasSaws)
    defaultTools.items?.push({
      id: Utils.generateGUID(),
      text: Saws,
      path: `${machineRoute}/tools/saws`,
      icon: settingsIcon,
      hoverIcon: hoverSettingsIcon,
      selectedIcon: selectedSettingsIcon,
      itemClicked: false,
      sectionTemplate: templates?.find(x => x.pageSection == sawSection),
      machineInfo: machineInfo,
      settings: getSettings,
      defaultSettings: defaultSettings
    });

  defaultTools.items?.sort((a, b) => a.text.localeCompare(b.text));

  return defaultTools;
}

const defaultWorkingAreaPages = (machineInfo: IMachineInfoModel, templates?: IMachineTemplate[], settings?: ISettings, defaultSettings?: ISettings): INavigation => {
  const getSettings = settings ?? defaultSettings;
  return {
    id: Utils.generateGUID(),
    text: WorkingAreas,
    icon: workingAreaIcon,
    hoverIcon: hoverWorkingAreaIcon,
    selectedIcon: selectedWorkingAreaIcon,
    itemClicked: false,
    path: `${getMachineSettingsRoute(machineInfo, getSettings)}/working_area`,
    sectionTemplate: templates?.find(x => x.pageSection == workingAreaSection),
    machineInfo: machineInfo,
    settings: getSettings,
    defaultSettings: defaultSettings
  }
}

function getMachineSettingsRoute(machineInfo: IMachineInfoModel, settings: ISettings | undefined): string | undefined {
  return `${getMachineRoute(machineInfo)}/${settings?.machineSettings?.name?.toLowerCase().replace(/ /g, "_")}`;
}

function getMachineRoute(machineInfo: IMachineInfoModel): string | undefined {
  return `/${machineInfo.manufacturer.toLowerCase().replace(/ /g, "_")}/${machineInfo.model.toLowerCase().replace(/ /g, "_")}`;
}