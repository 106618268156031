import React, { useEffect, useState } from "react";
import Modal from "../base/modal/modal";
import useModalHook from "../../hooks/modal.hook";
import { PropTypes } from 'data/modal/modal.proprieties';
import DataGrid, { Column, ColumnFixing } from 'devextreme-react/data-grid';
import Button from "devextreme-react/button";
import editIcon from '../../assets/images/icons/edit.svg';
import Editor from "./editor";
import { eToolByConditionRuleOpenEditorType, eToolsSelect } from "./editor.enums";
import { operatorCondition, fieldConditions, IDataByConditionRules, IDataByProcess, logicalConditions, IConditionRules } from "./editor.interfaces";
import { ITool } from "../../data/tools/toolData";
import { EditorsTranslates } from "../../translator/components/editors.translate";
import { Utils } from "../../utils/utils";

const { ToolByConditionRuleTitle, EditConditions, Process, Tool } = EditorsTranslates();

export default function ToolByConditionRule({ show, data, currentSettings, onClose, onButtonOk }: PropTypes) {

    const [dataSource, setDataSource] = useState<IConditionRules>(data);
    const [unestedDataSource, setUnestedDataSource] = useState<IConditionRules[]>(data);
    const [selectedRule, setSelectedRule] = useState<string>();
    const { isOpen, toggle, open } = useModalHook();
    const [isToolsEditorOpen, setToolsEditorOpen] = useState(false);
    const [isRulesEditorOpen, setRulesEditorOpen] = useState(false);

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        if (show) {
            populateDataSource();
            open();
        }

        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [data, show])

    const isGuidMap = (obj: any): obj is { [key: string]: object } => {
        if (typeof obj !== 'object' || obj === null) {
            return false;
        }
        return Object.keys(obj).every(key =>
            typeof key === 'string' && typeof obj[key] === 'string'
        );
    }

    const populateDataSource = () => {
        let loadByID = isGuidMap(data);
        if (Object.keys(unestedDataSource).length === 0) {
            const defaultRule = createDataByConditionRule(
                Utils.generateGUID(),
                '',
                fieldConditions[0].Value,
                operatorCondition[0].Value,
                "0"
            )
            setDataSource(defaultRule);
            setUnestedDataSource([{ ...defaultRule }]);
        }
        else if (loadByID) {
            let toolSettings = currentSettings?.toolSettings;
            if (toolSettings) {
                let arr: IConditionRules[] = [];
                for (const recordKey in data as Record<string, string>) {
                    const recordValue = (data as Record<string, string>)[recordKey];
                    let getCondition = findConditionById(toolSettings.conditions || [], recordKey);

                    if (getCondition) {
                        const getTool = toolSettings.mills?.find(t => t.id === recordValue);
                        const newRule = createDataByConditionRule(
                            getCondition.id || Utils.generateGUID(),
                            getCondition.logical || '',
                            getCondition.field.replace(/{|}/g, '') || fieldConditions[0].Name,
                            getCondition.operator || operatorCondition[0].Name,
                            String(getCondition.value) || "0",
                            getCondition.innerCondition,
                            getTool || undefined
                        );
                        arr.push(newRule);
                    }
                }
                setDataSource(arr[0]);
                setUnestedDataSource(arr);
            }
        }
    }

    const findConditionById = (conditions: IConditionRules[], recordKey: string): IConditionRules | undefined => {
        for (const condition of conditions) {
            if (condition.id === recordKey) {
                return condition;
            }
            const innerCondition = condition.innerCondition;
            if (innerCondition) {
                const foundCondition = findConditionById([innerCondition], recordKey);
                if (foundCondition) {
                    return foundCondition;
                }
            }
        }
        return undefined;
    };

    const createDataByConditionRule =
        (id: string, logical: string, name: string, operator: string, value: string, innerCondition?: IConditionRules, tool?: ITool) => {
            const defaultRule: IConditionRules = {
                id: id,
                logical: logical,
                field: name,
                operator: operator,
                value: value || '0',
                innerCondition: innerCondition,
                tool: tool
            };
            return defaultRule;
        }

    const openEditor = (toolByConditionRuleOpenEditor: eToolByConditionRuleOpenEditorType, ruleID?: string) => {
        switch (toolByConditionRuleOpenEditor) {
            case eToolByConditionRuleOpenEditorType.TOOLS_EDITOR:
                setSelectedRule(ruleID);
                return setToolsEditorOpen(true);
            case eToolByConditionRuleOpenEditorType.RULES_EDITOR:
                return setRulesEditorOpen(true);
        }
    }


    const confirmEditor = (data?: any) => {
        if (data) {
            let nestedRule = data as IConditionRules;
            let nestedTool = data[0] as ITool;
            let isRules = nestedRule?.id !== undefined;
            let isTool = nestedTool?.id !== undefined;
            let arr: any[] = [];
            let current: IConditionRules | undefined;

            if (isRules) {
                setDataSource(nestedRule);
                current = nestedRule;
            } else if (isTool) {
                current = dataSource;
                while (current) {
                    if (current.id === selectedRule) {
                        current.tool = nestedTool;
                    }
                    arr.push(current);
                    current = current.innerCondition;
                }

                setUnestedDataSource(arr);
                closeEditor();
                return;
            }

            while (current) {
                arr.push(current);
                current = current.innerCondition;
            }
            setUnestedDataSource(arr);
        }
        closeEditor();
    };

    const getSelectToolOptions = (row: any) => {
        return <Button width={40} height={40} className="button-alignment-right" icon={editIcon} onClick={() => openEditor(eToolByConditionRuleOpenEditorType.TOOLS_EDITOR, row.data?.id)} />
    }

    const openEditRules = () => {
        openEditor(eToolByConditionRuleOpenEditorType.RULES_EDITOR);
    }

    const closeEditor = () => {
        if (isToolsEditorOpen === true)
            setToolsEditorOpen(false);
        else if (isRulesEditorOpen === true)
            setRulesEditorOpen(false);
    }

    const getRowValueDisplay = (row: IConditionRules) => {
        if (row) {
            const logical = logicalConditions.find(condition => condition.Value === row.logical)?.Name ?? '';
            const field = fieldConditions.find(condition => condition.Value === row.field)?.Name ?? '';
            const operator = operatorCondition.find(condition => condition.Value === row.operator)?.Name ?? '';
            return `${logical} ${field} ${operator} ${row.value}`;
        }
        else return '';
    }

    const saveData = () => onButtonOk(dataSource);

    const invalidTools = () => Array.isArray(unestedDataSource) && unestedDataSource?.some(s => s.tool === undefined) || false;

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} close={onClose} width="45%" height="55%" onButtonOk={saveData}
                modalHeader={ToolByConditionRuleTitle}
                customButtonText={EditConditions} customButtonEvent={openEditRules} disableOkButton={invalidTools()}>

                <DataGrid
                    dataSource={unestedDataSource}
                    keyExpr="id"
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    showBorders={true}
                    height={windowSize.width > 1600 ? 400 : windowSize.width > 1180 ? 300 : 200}
                >
                    <ColumnFixing enabled={true} />
                    <Column
                        caption={Process}
                        alignment="center"
                        calculateDisplayValue={getRowValueDisplay}
                    />
                    <Column
                        caption={Tool}
                        alignment="center"
                        dataField="tool.name"
                    />
                    <Column
                        caption=""
                        width={60}
                        alignment="center"
                        cellRender={getSelectToolOptions}
                    />

                    <Editor
                        show={isToolsEditorOpen || isRulesEditorOpen}
                        currentSettings={currentSettings}
                        data={isToolsEditorOpen ? [(eToolsSelect.MILL as eToolsSelect)] : Array.isArray(unestedDataSource) ? unestedDataSource : ''}
                        onClose={closeEditor}
                        onButtonOk={confirmEditor}
                        editorName={isToolsEditorOpen ? "SelectTools" : "ToolByConditionEditRules"} />
                </DataGrid>
            </Modal>
        </React.Fragment>
    );
}