import './property.css'

import React, { useEffect, useRef, useState } from 'react';
import { Category, Field } from './property.data';
import PropertyFieldset from './property.fieldset';
import FieldUtil from './util/field.util';
import { AppService } from '../../api/app.service';
import LoadingOverlay from '../overlay/load/loading.overlay';
import { ISettings, ISettingsInput } from '../../data/settings/settingsData';
import BindManager from '../../helpers/bindHelper';
import { useLocation } from 'react-router-dom';
import { INavigation } from '../../data/navigation/navigationType';
import { store, useAppDispatch } from '../../store/store';
import { editSettings } from '../../store/settings.store';
import SettingsHelper from '../../helpers/settingsHelper';
import { editorTypeEnum } from '../../data/editor/editorType';
import { useSearch } from 'contexts/search.context';

const PropertyForm: React.FC<FormProps> = ({ changesPropagationLevel, categories, fields, onFieldChanged, onUpdateSettings, onBindAssign, onBindUpdate }) => {

    const appService = new AppService();
    const bindManager = new BindManager();
    const settingsHelper = new SettingsHelper();
    const filteredFields = fields.filter(f => f.id !== undefined);
    const dispatch = useAppDispatch();
    const { state } = useLocation();
    const [formState, setFormState] = useState<Field[]>();
    const settings = useRef<ISettings>();
    const [isLoading, setFormLoading] = useState(true);
    const [startedVisilityFields, setStartedVisilityFields] = useState(false);
    const { searchTerm } = useSearch();
    const [isFiltering, setIsFiltering] = useState<Boolean>(false);
    const [searchFields, setSearchFields] = useState<Field[]>([]);

    useEffect(() => {
        setIsFiltering(searchTerm !== "");
        if (searchTerm && formState) {
            let filter = [];
            for (let f of formState) {
                if (f.localizedName.toLocaleLowerCase().startsWith(searchTerm.toLocaleLowerCase()) ||
                    f.localizedName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                    filter.push(f);
                }
            }
            setSearchFields(filter);
        }
    }, [searchTerm]);

    useEffect(() => {
        if (!startedVisilityFields && Array.isArray(formState) && formState.length > 0)
            setStartedVisilityFields(true);
    }, [formState]);

    useEffect(() => {

        assignUserSettings();
        let updatedFields: Field[] = [];

        if (onBindAssign) {
            updatedFields = onBindAssign(settings.current);
            if (isLoading && updatedFields.length > 0 && !startedVisilityFields) {
                updatedFields = updatedFields.filter(f => f.visibilityCondition !== undefined || f.visibilityCondition !== '').map(s => {
                    return { ...s, isVisible: false };
                });
                setFormState(updatedFields);
            }
        }
        else {
            updatedFields = bindManager.assignBinds(filteredFields, settings.current!);
            setFormState(updatedFields);
            updateStateValues(updatedFields);
        }

        if (formState && startedVisilityFields) {
            setFormState(updatedFields);
            updateStateValues(updatedFields);
        }

    }, [fields])

    const disableForm = () => {
        let currentSetup = store.getState().selectedElementDataState.selectedElement;
        if (currentSetup?.selectedSetup?.selected) {
            return !(currentSetup?.selectedSetup?.settings?.isEnabled ?? true);
        }
        return false;
    }

    const getVisibilityByConditionResult = async (newFormState?: Field[]) => {
        let formFields: any[] = [];
        newFormState?.forEach(field => {
            if (field.visibilityCondition) {
                formFields.push({
                    field: field.id,
                    params: FieldUtil.getformFieldsBasedOnVisibilityCondition(newFormState, field.visibilityCondition),
                    formatString: field.visibilityCondition
                });
            } else {
                setFormState(prevState => {
                    let newState = prevState?.filter(f => f.id !== undefined).map(obj => {
                        if (obj.id === field.id) {
                            return {
                                ...obj,
                                isVisible: true
                            };
                        }
                        return obj;
                    });
                    return newState;
                });
            }
        });
        return formFields?.length > 0 ? await appService.getConditionResult(formFields) : [];
    }

    const getInteractionResult = async (newFormState?: Field[], fieldID?: string) => {
        let InteractionData: any[] = [];
        newFormState?.forEach(field => {
            if (field.interaction) {
                if (field.editorType == editorTypeEnum.Undefined) {
                    let fields = FieldUtil.getformFieldsBasedOnInterectionCondition(newFormState, field.id);
                    if (fields.some(f => f.item1 === fieldID)) {
                        InteractionData.push({
                            field: field.id,
                            params: fields,
                            formatString: field.format,
                            Constants: field.constants,
                            Interactions: field.interaction,
                            EditorType: field.editorType
                        });
                    }
                }
            }
        });
        return InteractionData.length > 0 ? await appService.getInteractionResult(InteractionData) : [];
    };

    const updateStateValues = async (fieldsState?: Field[], fieldName?: string, fieldValue?: any) => {
        if (onFieldChanged)
            onFieldChanged(fieldName, fieldValue);

        getVisibilityByConditionResult(fieldsState).then((tupleResults: any[]) => {
            Object.values(tupleResults).forEach(result => {
                const fieldResult = result.item1;
                const valueResult = result.item2;
                setFormState(prevState => {
                    let newState = prevState?.filter(f => f.id !== undefined).map(obj => {
                        if (obj.id === fieldResult || obj.id === fieldName) {
                            return {
                                ...obj,
                                isVisible: obj.id === fieldResult ? valueResult : obj.isVisible,
                                value: obj.id === fieldName ? fieldValue : obj.value,
                            };
                        }
                        return {
                            ...obj,
                            isVisible: obj.isVisible !== undefined ? !!obj.isVisible : true
                        }
                    });
                    return newState;
                });
            })
            setFormLoading(false);
        });
    }

    const handleValueChanged = async (fieldName: string, fieldValue: any) => {
        const sett = JSON.parse(JSON.stringify(settings.current!)) as ISettings;
        let updatedObject: any;

        let newStateTemp = formState?.map(obj => {
            if (obj.id === fieldName) {
                if (changesPropagationLevel != ChangesPropagationLevel.None) {
                    updateBinds(fieldValue, obj, sett);
                    propagateBindChanges(fieldValue, obj, sett);
                } else {
                    updateBinds(fieldValue, obj, sett);
                }

                updatedObject = { ...obj, value: fieldValue };
                return { ...obj, value: fieldValue };
            }
            return obj;
        });

        setFormState(newStateTemp);
        updateStateValues(newStateTemp, fieldName, fieldValue);

        await processInteractions(newStateTemp, sett, updatedObject.id);
        assignUserSettings();
    };

    const processInteractions = async (newStateTemp: Field[] | undefined, sett: ISettings, fieldID: string) => {
        const interactionObjs = newStateTemp?.filter(obj => obj.interaction);
        if (interactionObjs && interactionObjs.length > 0) {
            const tupleResults = await getInteractionResult(newStateTemp, fieldID);

            Object.values(tupleResults).forEach(result => {
                const valueToSet = result.item1;
                const fieldToUpdate = result.item2;
                const fieldObject = formState?.find(obj => obj.id === fieldToUpdate);

                if (fieldObject) {
                    const targetType = typeof fieldObject.value;
                    const convertedValue = convertValueToType(valueToSet, targetType);

                    fieldObject.value = convertedValue;

                    if (fieldObject.constants?.some(constant => constant.value === valueToSet))
                        updateStateValues(newStateTemp, valueToSet, fieldObject.value);
                    else
                        updateStateValues(newStateTemp, result.item2, fieldObject.value);

                    setFormState(newStateTemp);
                }
            });
        }
    };

    function convertValueToType(value: any, targetType: string): any {
        if (targetType === 'boolean') {
            return value === 'True' || value === 'true' || value === 1 || value === true;
        } else if (targetType === 'number') {
            return isNaN(Number(value)) ? 0 : Number(value);
        } else if (targetType === 'string') {
            return String(value);
        } else {
            return value;
        }
    }

    function propagateBindChanges(fieldValue: any, obj: Field, sett: ISettings) {
        const storedSettings = store.getState().settingsDataState.settings ?? [];

        storedSettings.forEach(SS => {
            const settingsInput: ISettingsInput = {
                machineInfo: SS.machineInfo,
                settingsToUpdate: [],
                settingsToDelete: SS.settingsToDelete
            }

            if (SS.machineInfo.id === sett.machineInfoId) {
                switch (changesPropagationLevel) {
                    case ChangesPropagationLevel.MachineModel:
                        SS.settingsToUpdate?.forEach(SU => {
                            const settings = JSON.parse(JSON.stringify(SU));
                            updateBinds(fieldValue, obj, settings)

                            settingsInput.settingsToUpdate?.push(settings);
                        })
                        break;
                    case ChangesPropagationLevel.MachineSettings:
                        SS.settingsToUpdate?.forEach(SU => {
                            if (SU.machineSettings.id === sett.machineSettings.id) {
                                const settings = JSON.parse(JSON.stringify(SU));
                                updateBinds(fieldValue, obj, settings)

                                settingsInput.settingsToUpdate?.push(settings);
                            }
                            else {
                                settingsInput.settingsToUpdate?.push(SU);
                            }
                        })
                        break;
                }
            }
            else {
                settingsInput.settingsToUpdate = SS.settingsToUpdate;
            }

            dispatch(editSettings(settingsInput));
        });
    }

    function updateBinds(fieldValue: any, obj: Field, sett: ISettings) {
        if (onBindUpdate)
            onBindUpdate(fieldValue, obj, sett);
        else
            bindManager.setValue(fieldValue, obj, sett);
    }

    function assignUserSettings() {
        if (onUpdateSettings)
            settings.current = onUpdateSettings();

        const storedSettings = settingsHelper.getStoredSettings(settings.current!, state.pageNavigation as INavigation);

        settings.current = storedSettings[1]!;
        dispatch(editSettings(storedSettings[0]!));
    }

    return (
        <React.Fragment>
            <LoadingOverlay isLoading={isLoading} />
            {!isLoading && (<form>
                {
                    categories.filter(c => { return filteredFields.some(s => s.categoryID === c.id) }).map((category, index) => (
                        <PropertyFieldset key={index} fieldsetKey={index} category={category} disableForm={disableForm()}
                            formState={isFiltering ? searchFields : formState} currentSettings={settings.current} handleChange={handleValueChanged} />
                    ))
                }
            </form>)}
        </React.Fragment>
    );
};

interface FormProps {
    changesPropagationLevel: ChangesPropagationLevel,
    categories: Category[];
    fields: Field[];
    name?: string,
    onFieldChanged?: (fieldName?: string, fieldValue?: any) => void
    onBindUpdate?: (fieldValue?: any, obj?: Field, settings?: ISettings) => void
    onUpdateSettings?: () => ISettings
    onBindAssign?: (settings?: ISettings) => Field[]
}

export enum ChangesPropagationLevel {
    None,
    MachineModel,
    MachineSettings
}

export default PropertyForm;