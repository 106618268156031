export interface ITool {
    id?: string
    name?: string,
    selected?: boolean
};

export interface IDrill extends ITool {
};

export interface IMill extends ITool {
};

export interface ISaw extends ITool {
};

export enum eSawOrientation {
    Both = "Both",
    Horizontal = "Horizontal",
    Vertical = "Vertical"
}