import './tools.scss';

import React, { useState, useEffect, useRef } from 'react';
import PropertyForm, { ChangesPropagationLevel } from '../../components/properties/property.form';
import { Category, Field } from '../../components/properties/property.data';
import { IDrill } from '../../data/tools/toolData';
import { useLocation } from 'react-router-dom';
import { INavigation } from '../../data/navigation/navigationType';
import PageHeaderComponent from '../../components/pages/header/page.header.component';
import { ISettings, ISettingsInput } from '../../data/settings/settingsData';
import BindManager from '../../helpers/bindHelper';
import { store, useAppDispatch } from '../../store/store';
import { editSettings } from '../../store/settings.store';
import { eHeaderButtonVisibility } from '../../components/header/header-button/header.button.visibility.types';
import TabPanelComponent from '../../components/tab-panel/tab.panel.component';
import { useTranslator } from 'contexts/translator.context';
import { DrillsTranslates } from 'translator/pages/drills.translate';
import { Utils } from 'utils/utils';

const { Title } = DrillsTranslates();

export const drillSection = "Drill";

const headerButtonsVisibility: eHeaderButtonVisibility[] = [eHeaderButtonVisibility.ADD, eHeaderButtonVisibility.COPY, eHeaderButtonVisibility.DELETE]

const Drill: React.FC = () => {
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const [fields, setFields] = useState<Field[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [drills, setDrills] = useState<IDrill[]>([]);
  const [allowBindAssign, setAllowBindAssign] = useState(true);
  const [copyIndex, setCopyIndex] = useState<number>(1);
  const currentSettings = useRef<ISettings>();
  const currentTool = useRef<IDrill>();
  const translatorHelper = useTranslator();
  const bindManager = new BindManager();
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);

  useEffect(() => {
    currentSettings.current = undefined;
    currentTool.current = undefined;
    setAllowBindAssign(true);
    setFields([]);
    setCategories([]);
    setDrills([]);

    const navigation = state.pageNavigation as INavigation;

    if (navigation != null && navigation.sectionTemplate?.pageSection == drillSection) {
      const formFields = JSON.parse(JSON.stringify(navigation.sectionTemplate));
      const translationPromise = translatorHelper.getTranslatedFieldsAndCategories(formFields.properties, formFields.categories);

      setFields(translationPromise.translationFields);
      setCategories(translationPromise.translationCategories);
    }
  }, [state.pageNavigation]);

  const getSelectedDrill = () => {
    return drills?.find(f => f.selected == true);
  }

  const onAddNewDrill = async () => {
    const uuid = Utils.generateGUID();
    let newTool: IDrill = {
      id: uuid,
      name: uuid,
      selected: false
    };

    const settings = JSON.parse(JSON.stringify(currentSettings.current!));
    settings?.toolSettings.drills?.push(newTool);
    currentSettings.current! = settings;

    propagateChanges(currentSettings.current!)

    setDrills(previousState => [...previousState, newTool]);
    drillSelected(newTool.id!);
  }

  const onDeleteDrill = async () => {
    const selectedTool = getSelectedDrill();
    let indexOf = drills.findIndex(x => x == selectedTool);
    if (selectedTool != undefined) {
      let newtools = drills.filter(a => a.id !== selectedTool?.id);

      const settings = JSON.parse(JSON.stringify(currentSettings.current!));
      settings.toolSettings.drills?.splice(0, settings.toolSettings.drills.length);
      settings.toolSettings.drills?.push(...newtools);
      currentSettings.current! = settings;

      propagateChanges(currentSettings.current!)

      setDrills(newtools);

      if (indexOf > 0)
        drillSelected(newtools[indexOf - 1].id!);
      else
        drillSelected(newtools[0].id!);
    }
  }

  const onDuplicateDrill = async () => {
    let selectedTool = getSelectedDrill();
    if (selectedTool != undefined) {
      const uuid = Utils.generateGUID();
      let duplicateTool = JSON.parse(JSON.stringify(selectedTool));
      duplicateTool.name = `${duplicateTool.name}(${copyIndex})`;
      duplicateTool.id = uuid;
      duplicateTool.selected = false;

      const settings = JSON.parse(JSON.stringify(currentSettings.current!));
      settings.toolSettings.drills?.push(duplicateTool);
      currentSettings.current! = settings;

      setCopyIndex(copyIndex + 1);
      propagateChanges(currentSettings.current!)
      setDrills(previousState => [...previousState, duplicateTool]);
    }
  }

  const onDrillClicked = (toolID: string) => {
    if (drills.find(f => f.id === toolID)?.selected === false)
      drillSelected(toolID, true);
  }

  const drillSelected = (toolID: string, onTabChange?: boolean) => {
    let getTool = drills.find(f => f.id === toolID);

    if (getTool)
      currentTool.current = getTool;

    let updatedFields: Field[] = [];
    for (var field of fields) {
      if (onTabChange)
        field.isVisible = false;

      updatedFields.push({
        ...field,
      })
    }

    bindManager.assignBinds(updatedFields, getTool);

    setDrills(prevState => {
      let newState = prevState?.map(obj => {
        if (obj.id === getTool?.id) {
          return {
            ...obj,
            selected: obj.selected === undefined ? true : !obj.selected
          };
        }
        return {
          ...obj,
          selected: false
        };
      });
      return newState;
    });

    setFields(updatedFields)
  }

  const onUpdateSettings = () => {
    return currentSettings.current!;
  }

  const onBindAssign = (settings?: ISettings) => {
    if (allowBindAssign) {
      currentSettings.current = settings;

      setDrills(settings?.toolSettings?.drills!);

      setAllowBindAssign(false);
    }

    if (getSelectedDrill() === undefined) {
      drillSelected(drills![0]?.id!);
    }

    setPageLoaded(Array.isArray(drills) && drills.length > 0);
    return fields;
  }

  const onBindUpdate = (fieldValue?: any, obj?: Field, settings?: ISettings) => {
    if (!obj!.bind || fieldValue === undefined) return;

    currentSettings.current = settings;

    let selectedDrill = getSelectedDrill();

    bindManager.setValue(fieldValue, obj!, selectedDrill);

    let availableDrills = settings?.toolSettings?.drills?.filter(D => D.id !== selectedDrill?.id)
    availableDrills?.push(selectedDrill!);
    availableDrills = availableDrills?.map(saw => ({ ...saw, selected: saw.selected ? saw.selected : false }));

    settings?.toolSettings?.drills?.splice(0, settings?.toolSettings?.drills.length);
    settings?.toolSettings?.drills?.push(...availableDrills!);
    setDrills(availableDrills!);
  }

  function propagateChanges(sett: ISettings) {
    const storedSettings = store.getState().settingsDataState.settings ?? [];

    storedSettings.forEach(SS => {
      const settingsInput: ISettingsInput = {
        machineInfo: SS.machineInfo,
        settingsToUpdate: [],
        settingsToDelete: SS.settingsToDelete
      }

      if (SS.machineInfo.id === sett.machineInfoId) {
        SS.settingsToUpdate?.forEach(SU => {
          if (SU.machineSettings.id === sett.machineSettings.id) {
            const settings: ISettings = {
              ...SU,
              toolSettings: {
                ...SU.toolSettings,
                drills: [...sett.toolSettings?.drills!]
              }
            };
            settingsInput.settingsToUpdate?.push(settings);
          }
          else {
            settingsInput.settingsToUpdate?.push(SU);
          }
        });
      }

      dispatch(editSettings(settingsInput));
    })
  }

  return (
    <React.Fragment>
      <PageHeaderComponent pageData={drills} buttonHandlers={[
        [eHeaderButtonVisibility.ADD, onAddNewDrill],
        [eHeaderButtonVisibility.DELETE, onDeleteDrill],
        [eHeaderButtonVisibility.COPY, onDuplicateDrill]]}
        headerButtonsVisibility={headerButtonsVisibility} title={Title} />
      <div className='page-body-layout'>
        <TabPanelComponent currentData={currentTool.current} pageLoaded={pageLoaded} data={drills} onTabChanged={(id: string) => onDrillClicked(id)} />
        <PropertyForm changesPropagationLevel={ChangesPropagationLevel.MachineSettings} categories={categories} fields={fields} onUpdateSettings={onUpdateSettings} onBindAssign={onBindAssign} onBindUpdate={onBindUpdate} />
      </div>
    </React.Fragment>
  );

}

export default Drill;