import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.cyncly.css';
import './themes/generated/theme.additional.cyncly.css';
import 'font-awesome/css/font-awesome.min.css';
import './dx-styles.css';

import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { NavigationProvider } from './contexts/navigation.context';
import { AuthProvider, useAuth } from './contexts/auth.context';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { licenseKey } from 'devextreme-license';
import config from 'devextreme/core/config';
import ProgressBarComponent from 'components/progress-bar/progress.bar.component';
import Login from 'pages/login/login';
import Logout from 'pages/logout/logout';
import { TranslatorProvider } from './contexts/translator.context';
import SearchProvider from 'contexts/search.context';
import LoaderProvider from 'contexts/loader.context';
import NotificationProvider from 'contexts/notification.context';
import SelectUserLogin from 'pages/login/select.user.login';

config({
  licenseKey
});

function App() {

  const { user, loading, userName, selectUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = () => {
      navigate('/');
    };

    window.addEventListener('load', handleBeforeUnload);

    return () => {
      window.removeEventListener('load', handleBeforeUnload);
    };

  }, []);

  if (selectUser) {
    return <SelectUserLogin />
  }

  if (loading) {
    return <ProgressBarComponent profileName={userName} />
  }
  
  if (user) {
    return <Content />;
  }

  const url = new URL(window.location.href);
  if (url.pathname === "/logout") {
    return <Logout />;
  }

  return <Login />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Fragment>
      <Provider store={store}>
        <Router>
          <AuthProvider>
            <LoaderProvider>
              <NotificationProvider>
                <TranslatorProvider>
                  <SearchProvider>
                    <NavigationProvider>
                      <div className={`app app-background ${screenSizeClass}`}>
                        <App />
                      </div>
                    </NavigationProvider>
                  </SearchProvider>
                </TranslatorProvider>
              </NotificationProvider>
            </LoaderProvider>
          </AuthProvider>
        </Router>
      </Provider>
    </Fragment>
  );
}