import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISettingsInput } from "../data/settings/settingsData";

interface SettingsDataState {
    settings: ISettingsInput[]
}

const initialState: SettingsDataState = {
    settings: []
}

export const SettingsDataSlice = createSlice({
    name: 'settingsData',
    initialState,
    reducers: {
        addSettings: (state, action: PayloadAction<ISettingsInput>) => {
            var data = state.settings.find(s => s.machineInfo.pluginId === action.payload.machineInfo.pluginId)
            if (!data)
                state.settings.push(JSON.parse(JSON.stringify(action.payload)));
        },
        editSettings: (state, action: PayloadAction<ISettingsInput>) => {
            var index = state.settings.findIndex(s => s.machineInfo.pluginId === action.payload.machineInfo.pluginId)
            if (index !== -1)
                state.settings[index] = action.payload
            else
                state.settings.push(JSON.parse(JSON.stringify(action.payload)))

        },
        replaceNewSettings: (state, action: PayloadAction<ISettingsInput[]>) => {
            const index = state.settings.findIndex(s => s.machineInfo.pluginId === action.payload[0].machineInfo.pluginId);
            if (index !== -1) {
                state.settings[index] = {
                    ...state.settings[index],
                    ...action.payload[0]
                };
            } else {
                state.settings.push({
                    ...action.payload[0],
                });
            }
        },
        removeSettings: (state, action: PayloadAction<ISettingsInput>) => {
            state.settings = state.settings.filter(({ }) => action.payload.machineInfo.pluginId)
        },
        clearSettings: (state) => {
            state.settings = [];
        }
    }
})

export default SettingsDataSlice.reducer;
export const { addSettings, editSettings, removeSettings, clearSettings, replaceNewSettings } = SettingsDataSlice.actions;