import TranslatorHelper from '../../helpers/translatorHelper';
const translatorHelper = new TranslatorHelper();
export const EditorsTranslates = () => {
    return {
        FullLabelTitle: translatorHelper.getTranslatedValue('Editor.FullLabel.Title'),
        LabelSettingsTitle: translatorHelper.getTranslatedValue('Editor.Label.Title'),
        OperationsOrderTitle: translatorHelper.getTranslatedValue('Editor.OperationsOrder.Title'),
        PriorizationOrderTitle: translatorHelper.getTranslatedValue('Editor.PriorizationOrder.Title'),
        InsertionProcessesTitle: translatorHelper.getTranslatedValue('Editor.InsertionProcesses.Title'),
        MachiningProcessesTitle: translatorHelper.getTranslatedValue('Editor.MachiningProcesses.Title'),
        MachiningToolsTitle: translatorHelper.getTranslatedValue('Editor.MachiningTools.Title'),
        MachiningToolsByProcessTitle: translatorHelper.getTranslatedValue('Editor.MachiningToolsByProcess.Title'),
        OrtogonalMachiningToolsTitle: translatorHelper.getTranslatedValue('Editor.OrtogonalMachiningTools.Title'),
        SlotProcessesTitle: translatorHelper.getTranslatedValue('Editor.SlotProcesses.Title'),
        SlotToolsTitle: translatorHelper.getTranslatedValue('Editor.SlotTools.Title'),
        SlotToolsByProcessTitle: translatorHelper.getTranslatedValue('Editor.SlotToolsByProcess.Title'),
        ToolsTitle: translatorHelper.getTranslatedValue('Editor.Tools.Title'),
        ToolByConditionRuleTitle: translatorHelper.getTranslatedValue('Editor.ToolByConditionRule.Title'),
        ZeroPointByFaceTitle: translatorHelper.getTranslatedValue('Editor.ZeroPointByFace.Title'),

        Add: translatorHelper.getTranslatedValue('Add'),
        Field: translatorHelper.getTranslatedValue('Field'),
        Condition: translatorHelper.getTranslatedValue('Condition'),
        Value: translatorHelper.getTranslatedValue('Value'),
        AndOr: translatorHelper.getTranslatedValue('AndOr'),
        EditConditions: translatorHelper.getTranslatedValue('EditConditions'),
        SelectedFaces: translatorHelper.getTranslatedValue('SelectedFaces'),
        SelectDeselectAll: translatorHelper.getTranslatedValue('SelectDeselectAll'),
        None: translatorHelper.getTranslatedValue('None'),
        All: translatorHelper.getTranslatedValue('All'),
        Saws: translatorHelper.getTranslatedValue('Saws'),
        Tool: translatorHelper.getTranslatedValue('Tool'),
        Mills: translatorHelper.getTranslatedValue('Mills'),
        Process: translatorHelper.getTranslatedValue('Process'),
        Operation: translatorHelper.getTranslatedValue('Operation'),
        AndConditionRule: translatorHelper.getTranslatedValue('Editor.AndConditionRule'),
        OrConditionRule: translatorHelper.getTranslatedValue('Editor.OrConditionRule'),
        FieldLength: translatorHelper.getTranslatedValue('Length'),
        FieldWidth: translatorHelper.getTranslatedValue('Width'),
        FieldDepth: translatorHelper.getTranslatedValue('Depth'),
        FieldArea: translatorHelper.getTranslatedValue('Area'),
        ConditionalBiggerThan: translatorHelper.getTranslatedValue('BiggerThan'),
        ConditionalLowerThan: translatorHelper.getTranslatedValue('LowerThan'),
        ConditionalLowerThanOrEqual: translatorHelper.getTranslatedValue('LowerThanOrEqual'),
        ConditionalBiggerThanOrEqual: translatorHelper.getTranslatedValue('BiggerThanOrEqual'),
        ProcessDisabled: translatorHelper.getTranslatedValue('Disabled'),
        ProcessEnabled: translatorHelper.getTranslatedValue('Enabled'),
        ProcessEnabledWithSlotToolByProcess: translatorHelper.getTranslatedValue('EnabledWithSlotToolByProcess'),
        ProcessEnabledWithMachiningToolByProcess: translatorHelper.getTranslatedValue('EnabledWithMachiningToolByProcess'),
        ProcessEnabledWithSlotTool: translatorHelper.getTranslatedValue('EnabledWithSlotTool'),
        ProcessEnabledWithMachiningTool: translatorHelper.getTranslatedValue('EnabledWithMachiningTool'),
    };
};
