import './import.settings.modal.css';

import React, { useEffect, useRef, useState } from "react";
import { PropTypes } from "data/modal/modal.proprieties";
import Modal from "components/base/modal/modal";
import useModalHook from "hooks/modal.hook";
import { ImportTranslates } from "translator/components/import.translate";
import { Button } from 'devextreme-react';

const { ImportModalTitle, ImportModalFile } = ImportTranslates();

export default function ImportSettingsModal({ show, onClose, onButtonOk }: PropTypes) {

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const { isOpen, toggle, open } = useModalHook();
    const [fileContent, setFileContent] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    useEffect(() => {
        if (show)
            open();
    }, [show]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            setSelectedFile(file);

            const reader = new FileReader();
            reader.onload = (e) => {
                if (typeof e.target?.result === 'string') {
                    setFileContent(e.target.result);
                } else {
                    const arrayBuffer = e.target?.result as ArrayBuffer;
                    const text = new TextDecoder().decode(arrayBuffer);
                    setFileContent(text);
                }
            };
            reader.readAsText(file);
        }
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} close={onClose} width="25%" height="5%" onButtonOk={() => onButtonOk(fileContent)} modalHeader={ImportModalTitle}>
                <div className='modal-container'>
                    <input
                        type="file"
                        accept=".json"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        className='file-input'
                    />
                    <Button onClick={handleButtonClick} type='normal'>
                        {ImportModalFile}
                    </Button>
                    <input type='text' value={selectedFile?.name || ''} disabled={true} className='value-style' />
                </div>
            </Modal>
        </React.Fragment>
    );
}