import React, { useEffect, useState } from "react";
import Modal from "../base/modal/modal";
import useModalHook from "../../hooks/modal.hook";
import { PropTypes } from "data/modal/modal.proprieties";
import { store } from "../../store/store";
import { TreeList, Selection, Column, TreeListTypes } from 'devextreme-react/tree-list';
import { IMill, ITool } from "../../data/tools/toolData";
import { ITreeListTools } from "./editor.interfaces";
import { Utils } from "../../utils/utils";
import { EditorsTranslates } from "../../translator/components/editors.translate";
import './editor.css';

const { MachiningToolsTitle, Mills } = EditorsTranslates();

export default function SelectedMachiningTools({ show, data, currentSettings, onClose, onButtonOk }: PropTypes) {

    const [mills, setMills] = useState<IMill[]>([]);
    const [treeListData, setTreeListData] = useState<ITreeListTools[]>([]);
    const [selectedData, setSelectedData] = useState<ITool[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const { isOpen, toggle, open } = useModalHook();

    const state = store.getState();

    useEffect(() => {
        if (show) {
            if (currentSettings) {
                let toolSettings = currentSettings.toolSettings;
                if (toolSettings) {
                    setMills(toolSettings.mills || []);
                }
            }
            open();
        }
    }, [show]);

    useEffect(() => {
        if (mills.length > 0) {
            populateToolTreeList();
        }
    }, [mills]);

    useEffect(() => {
        if (treeListData.length > 0) {
            const selectedTools = getSelectedTools(currentSettings, mills);
            setSelectedData(selectedTools);
        }
    }, [treeListData]);

    useEffect(() => {
        updateSelectedKeys();
    }, [selectedData]);

    function populateToolTreeList() {
        let toolsArray: ITreeListTools[] = [];

        let millHeadTool: ITreeListTools = {
            ID: Utils.generateGUID(),
            headID: '0',
            toolName: Mills,
            isHead: true
        };

        toolsArray.push(millHeadTool);

        for (let m of mills) {
            let newTool: ITreeListTools = {
                ID: Utils.generateGUID(),
                headID: millHeadTool.ID,
                toolID: m.id,
                toolName: m.name ?? ""
            };
            toolsArray.push(newTool);
        }

        setTreeListData(toolsArray);
    }

    function getSelectedTools(currentSettings: any, mills: IMill[]): ITool[] {
        const selectedToolOrProcessIDByOperationType = currentSettings.toolSettings.selectedToolOrProcessIDByOperationType;

        if (!selectedToolOrProcessIDByOperationType.hasOwnProperty("ParametricMachiningDTO")
            || Object.keys(selectedToolOrProcessIDByOperationType["ParametricMachiningDTO"]).length === 0) {
            return [];
        }

        const selectedToolIDs = Object.values(selectedToolOrProcessIDByOperationType["ParametricMachiningDTO"]);

        return mills.filter(mill => selectedToolIDs.includes(mill.id));
    }

    const onSelectionChanged = (e: TreeListTypes.SelectionChangedEvent) => {
        let childrenToolsID: any[] = [];
        if (e.selectedRowsData.some(s => s.isHead))
            childrenToolsID = treeListData.filter(f => f.headID === e.currentSelectedRowKeys[0]).map((tool) => tool.toolID);

        let toolsID = e.selectedRowsData.map((tool) => tool.toolID);
        let dataSelectedMills = mills.filter(m => toolsID.includes(m.id) || childrenToolsID.includes(m.id));
        setSelectedData(dataSelectedMills);
        setSelectedKeys(e.selectedRowKeys);
    };

    const updateSelectedKeys = () => {
        let dataSelected = selectedData.map(m => m.id);
        if (treeListData) {
            setSelectedKeys(treeListData.filter(m => dataSelected.includes(m.toolID)).map(m => m.ID));
        }
    };

    const saveData = () => onButtonOk(selectedData);

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} close={onClose} width="45%" height="55%" onButtonOk={saveData} modalHeader={MachiningToolsTitle}>

                <TreeList
                    id="tool-grid"
                    dataSource={treeListData}
                    showRowLines={true}
                    showBorders={true}
                    columnAutoWidth={true}
                    showColumnHeaders={false}
                    keyExpr="ID"
                    parentIdExpr="headID"
                    selectedRowKeys={selectedKeys}
                    onSelectionChanged={onSelectionChanged}>
                    <Selection recursive={true} mode="multiple" />
                    <Column dataField="toolName" />
                </TreeList>
            </Modal>
        </React.Fragment>
    );
}
