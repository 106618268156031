import React from 'react';
import { Button } from 'devextreme-react/button';

type ButtonGroupProps = {
  onClickMoveUp: () => void;
  onClickMoveDown: () => void;
  buttonMoveUpState: () => boolean;
  buttonMoveDownState: () => boolean;
};

const ButtonEditorRow: React.FC<ButtonGroupProps> = ({
  onClickMoveUp,
  onClickMoveDown,
  buttonMoveUpState,
  buttonMoveDownState
}) => {
  return (
    <div className='button-editor-react' >
      <div >
        <Button icon="arrowup" onClick={onClickMoveUp} disabled={buttonMoveUpState()} />
      </div>

      <div>
        <Button icon="arrowdown" onClick={onClickMoveDown} disabled={buttonMoveDownState()} />
      </div>
    </div>
  );
};

export default ButtonEditorRow;