import { IProcessOutput } from "../../data/machine/templateData"
import { ITool } from "../../data/tools/toolData"
import { enablingProcessOptionsType } from "./editor.enums"
import { EditorsTranslates } from "../../translator/components/editors.translate"

export interface ITreeListTools {
    ID: string,
    headID: string,
    toolID?: string,
    toolName: string,
    isHead?: boolean
}

export interface ITreeListLabelSettings {
    id: string,
    name: string
}

export interface IDataByProcess {
    processOutput: IProcessOutput,
    tools?: ITool[]
}

export interface IDataByConditionRules {
    conditionRule: IConditionRules,
    tool?: ITool
}

interface ConditionRuleInterface {
    ID: number;
    Value: string,
    Name: string;
}

interface AndOrConditionRule extends ConditionRuleInterface { }
interface FieldConditionRule extends ConditionRuleInterface { }
interface ConditionalConditionRule extends ConditionRuleInterface { }

const { AndConditionRule, OrConditionRule, FieldLength, FieldWidth, FieldDepth, FieldArea, ConditionalBiggerThan: OperatorBiggerThan,
    ConditionalLowerThan: OperatorLowerThan, ConditionalLowerThanOrEqual: OperatorLowerThanOrEqual, ConditionalBiggerThanOrEqual: OperatorBiggerThanOrEqual, ProcessDisabled, ProcessEnabled,
    ProcessEnabledWithSlotToolByProcess, ProcessEnabledWithMachiningToolByProcess, ProcessEnabledWithSlotTool, ProcessEnabledWithMachiningTool
} = EditorsTranslates();

export const logicalConditions: AndOrConditionRule[] = [{
    ID: 1,
    Value: "&&",
    Name: AndConditionRule
}, {
    ID: 2,
    Value: "||",
    Name: OrConditionRule
}]

export const fieldConditions: FieldConditionRule[] = [{
    ID: 3,
    Value: "Depth",
    Name: FieldDepth,
}, {
    ID: 1,
    Value: "Length",
    Name: FieldLength,
}, {
    ID: 2,
    Value: "Width",
    Name: FieldWidth,
}, {
    ID: 4,
    Value: "Length * Width",
    Name: FieldArea
}]

export const operatorCondition: ConditionalConditionRule[] = [{
    ID: 2,
    Value: "<",
    Name: OperatorLowerThan,
}, {
    ID: 1,
    Value: ">",
    Name: OperatorBiggerThan,
}, {
    ID: 3,
    Value: "<=",
    Name: OperatorLowerThanOrEqual,
}, {
    ID: 4,
    Value: ">=",
    Name: OperatorBiggerThanOrEqual
}]

export interface ConditionDTO {
    id: string;
    logical?: string;
    field: string;
    operator: string;
    value: string;
    innerCondition?: ConditionDTO;
}

export interface IConditionRules extends ConditionDTO {
    tool?: ITool
}

export interface IDataBySelectedProcess {
    processOutput: IProcessOutput,
    enablingProcessOptionsType: enablingProcessOptionsType
}

interface IProcessOptionsInterface {
    ID: string;
    Name: string;
    Value: enablingProcessOptionsType
}

export const enablingProcessOptions: IProcessOptionsInterface[] = [
    {
        ID: '1',
        Name: ProcessDisabled,
        Value: enablingProcessOptionsType.Disabled
    }, {
        ID: '2',
        Name: ProcessEnabled,
        Value: enablingProcessOptionsType.Enabled
    }, {
        ID: '3',
        Name: ProcessEnabledWithSlotToolByProcess,
        Value: enablingProcessOptionsType.EnabledWithSlotToolByProcess
    }, {
        ID: '4',
        Name: ProcessEnabledWithMachiningToolByProcess,
        Value: enablingProcessOptionsType.EnabledWithMachiningToolByProcess
    }, {
        ID: '5',
        Name: ProcessEnabledWithSlotTool,
        Value: enablingProcessOptionsType.EnabledWithSlotTool
    }, {
        ID: '6',
        Name: ProcessEnabledWithMachiningTool,
        Value: enablingProcessOptionsType.EnabledWithMachiningTool
    }
]

export const enablingMachiningProcessOptions: IProcessOptionsInterface[] = [
    {
        ID: '1',
        Name: ProcessDisabled,
        Value: enablingProcessOptionsType.Disabled
    }, {
        ID: '4',
        Name: ProcessEnabledWithMachiningToolByProcess,
        Value: enablingProcessOptionsType.EnabledWithMachiningToolByProcess
    }, {
        ID: '6',
        Name: ProcessEnabledWithMachiningTool,
        Value: enablingProcessOptionsType.EnabledWithMachiningTool
    }
]

export const enablingSlotProcessOptions: IProcessOptionsInterface[] = [
    {
        ID: '1',
        Name: ProcessDisabled,
        Value: enablingProcessOptionsType.Disabled
    }, {
        ID: '3',
        Name: ProcessEnabledWithSlotToolByProcess,
        Value: enablingProcessOptionsType.EnabledWithSlotToolByProcess
    }, {
        ID: '5',
        Name: ProcessEnabledWithSlotTool,
        Value: enablingProcessOptionsType.EnabledWithSlotTool
    }
]