import React, { useEffect, useState } from "react";
import Modal from "../base/modal/modal";
import useModalHook from "../../hooks/modal.hook";
import TextBox from 'devextreme-react/text-box';
import Box, { Item } from 'devextreme-react/box';
import { Field } from '../properties/property.data';
import ButtonEditorRow from "../editor/buttonEditorRow";
import ColumnEditorRow from "./nesting.columnEditorRow";

export interface PropTypes {
  show: boolean;
  onPropertyChange: (property: Field) => void;
  onPropertyNotChange: () => void;
  editorName?: string;
  property: Field;
}

export default function ColumnEditor({ show, property, onPropertyChange, onPropertyNotChange }: PropTypes) {
  const { isOpen, toggle, open } = useModalHook();
  const [selectedItem, setSelectedItem] = useState<Field | null>(null);
  const [separatorValue, setSeparatorValue] = useState("");
  const [atualizeLines, setAtualizeLines] = useState(false);
  const [cloneProperty, setCloneProperty] = useState<Field>(property);
  const [groupedProperties, setGroupedProperties] = useState<Field[]>([]);

  function setClone(property: Field) {
    let propertyClone = { ...property }

    setCloneProperty(propertyClone);
  }

  function setProperties(property: Field) {
    let propertyClone = { ...property }

    if (propertyClone.groupedProperties === undefined || propertyClone.groupedProperties.length === 0) {
      setGroupedProperties([propertyClone]);
    } else {
      setGroupedProperties([...propertyClone.groupedProperties]);
    }
  }

  function setSeparator(property: Field) {
    if (property) {
      if (property.separator === undefined || property.separator.length === 0) {
        setSeparatorValue("-");
      } else {
        setSeparatorValue(property.separator);
      }
    }
  }

  const handleItemClick = (item: Field) => {
    setSelectedItem(item);
  };

  const onClickMoveUp = () => {
    if (!buttonMoveUpState()) {

      let index = groupedProperties.findIndex(item => item === selectedItem);

      let item = groupedProperties[index];
      groupedProperties.splice(index, 1);
      groupedProperties.splice(index - 1, 0, item);

      const cloneGroupedProperties = [...groupedProperties];

      cloneProperty.groupedProperties = cloneGroupedProperties;
      const { format, value, visibleValue } = ReorderProperties(cloneProperty);

      cloneProperty.format = format;
      cloneProperty.value = value;
      cloneProperty.visibleValue = visibleValue;

      setClone(cloneProperty);

      setGroupedProperties(cloneGroupedProperties)
    }
  }

  const onClickMoveDown = () => {
    if (!buttonMoveDownState()) {

      let index = groupedProperties.findIndex(item => item === selectedItem);

      let item = groupedProperties[index];
      groupedProperties.splice(index, 1);
      groupedProperties.splice(index + 1, 0, item);

      const cloneGroupedProperties = [...groupedProperties];

      cloneProperty.groupedProperties = cloneGroupedProperties;
      const { format, value, visibleValue } = ReorderProperties(cloneProperty);

      cloneProperty.format = format;
      cloneProperty.value = value;
      cloneProperty.visibleValue = visibleValue;

      setClone(cloneProperty);

      setGroupedProperties(cloneGroupedProperties)
    }
  }

  const ReorderProperties = (Property: Field): { format: string, value: string, visibleValue: string } => {
    let format = '';
    let value = '';
    let visibleValue = '';

    Property.groupedProperties.forEach((selectedItem, index) => {
      format += selectedItem.format;
      value += selectedItem.value;
      visibleValue += selectedItem.visibleValue;

      if (index !== (Property.groupedProperties.length - 1)) {
        format += Property.separator;
        value += Property.separator;
        visibleValue += Property.separator;;
      }
    });

    return { format, value, visibleValue };
  };

  const buttonMoveUpState = () => {
    let index = groupedProperties.findIndex(item => item === selectedItem);

    if (index > 0) {
      return false;
    }

    return true;
  }

  const buttonMoveDownState = () => {
    let index = groupedProperties?.findIndex(item => item === selectedItem);

    if (index < groupedProperties.length - 1 && index > -1) {
      return false;
    }

    return true;
  }

  useEffect(() => {
    setClone(property);
    setSeparator(property);
    setProperties(property);
    setAtualizeLines(true);
  }, [property]);

  useEffect(() => {
    if (atualizeLines) {
      setSelectedItem(groupedProperties[0]);
      setAtualizeLines(false);
    }
  }, [groupedProperties, atualizeLines]);

  useEffect(() => {
    if (show) {
      open();
    }
  }, [show, open]);


  function onSeparatorChange(separator: string): void {
    if (!(cloneProperty.groupedProperties === undefined || cloneProperty.groupedProperties.length === 0)) {
      cloneProperty.separator = separator;
      const { format, value, visibleValue } = ReorderProperties(cloneProperty);
      cloneProperty.format = format;
      cloneProperty.value = value;
      cloneProperty.visibleValue = visibleValue;
      setClone(cloneProperty);
    }

    setSeparatorValue(separator);
  }

  const handlePropertyChange = (updatedProperty: Field) => {
    let index = groupedProperties.findIndex(item => item.id === updatedProperty.id);
    groupedProperties[index] = updatedProperty;
    const GroupedPropertiesClone = [...groupedProperties];

    replaceAttributes(updatedProperty);

    if (cloneProperty.groupedProperties === undefined || cloneProperty.groupedProperties.length === 0) {
      setClone(updatedProperty)
    } else {
      cloneProperty.groupedProperties = GroupedPropertiesClone;
      const { format, value, visibleValue } = ReorderProperties(cloneProperty);
      cloneProperty.format = format;
      cloneProperty.value = value;
      cloneProperty.visibleValue = visibleValue;
      setClone(cloneProperty);
    }

    setSelectedItem({ ...updatedProperty });
    setGroupedProperties(GroupedPropertiesClone);
  };

  const handlePropertyCloneChange = (updatedProperty: Field) => {
    setClone(updatedProperty);
  };

  const replaceAttributes = (updatedProperty: Field) => {
    const regex = /\[([^[\]]+)\]/;
    const modifiedString = updatedProperty.format.replace(regex, `[${updatedProperty.suffix}]`);
    updatedProperty.format = modifiedString;
  };

  const onClickOk = () => {
    let newProperty = { ...cloneProperty };

    if (!(cloneProperty.groupedProperties === undefined || cloneProperty.groupedProperties.length === 0)) {
      newProperty.groupedProperties = groupedProperties;
    }

    newProperty.separator = separatorValue;
    onPropertyChange(newProperty);
  }

  const onCancel = () => {
    setClone(property);
    setSeparator(property);
    setProperties(property);
    setSelectedItem(property);
    onPropertyNotChange();
  }

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={toggle} close={onCancel} width="800px" height="500px" onButtonOk={onClickOk}>
        <Box direction="row" width="100%" height="70%">
          <Item ratio={0} baseSize={280}>
            <div className="div-columnEditor-column">
              <ul>
                {groupedProperties.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => handleItemClick(item)}
                    className={selectedItem === item ? "selected" : ""}
                  >
                    {item.value}
                  </div>
                ))}
              </ul>
            </div>
            <div className="div-columnEditor-separator">
              Separador
              <TextBox value={separatorValue} className="separatorTextBox" maxLength={1} onValueChange={onSeparatorChange} />
            </div>
          </Item>
          <Item>
            <div className="rect">
              <ButtonEditorRow
                onClickMoveUp={onClickMoveUp}
                onClickMoveDown={onClickMoveDown}
                buttonMoveUpState={buttonMoveUpState}
                buttonMoveDownState={buttonMoveDownState}
              />
            </div>
          </Item>
          <Item ratio={3}>
            <div className="div-name-column">
              Coluna Selecionada
            </div>
            <div className="div-columnEditor-selectedColumn">
              <ColumnEditorRow property={selectedItem} type="selectedColumn" onPropertyChange={handlePropertyChange} />
            </div>
            <div className="div-name-column">
              Resultado Final
            </div >
            <div className="div-columnEditor-finalColumn">
              <ColumnEditorRow property={cloneProperty} type="finalColumn" onPropertyChange={handlePropertyCloneChange} />
            </div>
          </Item>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
